import React, { useState, useRef, useEffect } from 'react'
import { DatePicker } from '@mui/lab'
import { Close } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import {
  ClearButton,
  CustomPipeline,
  RangePickerWrapper,
  StyledDatePicker,
} from './StyledComponents'
import CalendarIcon from '../icons/calendarIcon'

function CustomDateRangePicker({
  // eslint-disable-next-line no-unused-vars
  setFromDate: setFromDateProp,
  // eslint-disable-next-line no-unused-vars
  setToDate: setToDateProp,
}) {
  const fromDateRef = useRef()
  const toDateRef = useRef()
  const [displayClearButton, setDisplayClearButton] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [fromOpen, setFromOpen] = useState(false)
  const [toOpen, setToOpen] = useState(false)

  const handleClear = () => {
    setFromDate(null)
    setToDate(null)
    setFromOpen(false)
    setToOpen(false)
    setDisplayClearButton(false)
    setFromDateProp(null)
    setToDateProp(null)
  }

  useEffect(() => {
    if (fromDate || toDate) {
      setDisplayClearButton(true)
    }
    if (fromDate !== null) {
      setFromDateProp(fromDate)
    }
    if (toDate !== null) {
      setToDateProp(toDate)
    }
  }, [fromDate, toDate])

  return (
    <RangePickerWrapper style={{ margin: 'auto', width: '100%' }}>
      <DatePicker
        ref={fromDateRef}
        open={fromOpen}
        onClose={() => setFromOpen(false)}
        views={['day']}
        label="From date"
        value={fromDate}
        onChange={(newValue) => setFromDate(newValue)}
        PopperProps={{
          anchorEl: fromDateRef?.current ?? null,
        }}
        renderInput={({
          ref,
          inputProps,
          disabled,
          onChange,
          value,
          ...other
        }) => (
          <StyledDatePicker ref={ref} {...other} style={{ width: '50%' }}>
            <input
              value={value}
              onChange={onChange}
              disabled={disabled}
              {...inputProps}
            />
            <button
              type="button"
              width="100%"
              onClick={() => setFromOpen(true)}
            >
              <span>{fromDate ? fromDate.format('L') : 'From'}</span>
              <CalendarIcon style={{ width: '15px' }} />
            </button>
          </StyledDatePicker>
        )}
      />
      <CustomPipeline />
      <DatePicker
        ref={toDateRef}
        open={toOpen}
        onClose={() => setToOpen(false)}
        views={['day']}
        label="To date"
        value={toDate}
        onChange={(newValue) => setToDate(newValue)}
        PopperProps={{
          anchorEl: toDateRef?.current ?? null,
        }}
        renderInput={({
          ref,
          inputProps,
          disabled,
          onChange,
          value,
          ...other
        }) => (
          <StyledDatePicker ref={ref} {...other} style={{ width: '50%' }}>
            <input
              value={value}
              onChange={onChange}
              disabled={disabled}
              {...inputProps}
            />
            <button
              type="button"
              className="reverse"
              width="100%"
              onClick={() => setToOpen(true)}
            >
              <span>{toDate ? toDate.format('L') : 'To'}</span>
              <CalendarIcon style={{ width: '15px' }} />
            </button>
          </StyledDatePicker>
        )}
      />
      {displayClearButton && (
        <Tooltip
          title="Clear"
          placement="right"
          enterDelay={300}
          leaveDelay={300}
          arrow
        >
          <ClearButton type="button" onClick={handleClear}>
            <Close />
          </ClearButton>
        </Tooltip>
      )}
    </RangePickerWrapper>
  )
}

export default CustomDateRangePicker
