import { faTruckFast } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@material-ui/core'
import { Box } from '@mui/material'
import moment from 'moment'
import React from 'react'

function InstructionsBloc({ data }) {
  return (
    <>
      <h5>Instructions</h5>
      <div className="card">
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <p>Enlèvement demandé pour le :</p>
            <input
              className="input-info"
              type="text"
              value={moment(data?.date).format('L')}
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="input-info-grey">
              <p>Annotations :</p>
              <p>{data?.commentaireDate || 'Aucune annotation'}</p>
            </Box>
          </Grid>
        </Grid>
        <Box className="tableRecap">
          <p>
            <FontAwesomeIcon
              icon={faTruckFast}
              style={{ marginRight: '1rem' }}
            />
            Expédition
          </p>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={`/icons/funnel/cotation/${data?.expeditionContrainteAcces.icon}`}
              alt="svg"
            />
            <p style={{ marginLeft: '1rem' }}>
              {data?.expeditionContrainteAcces.nom}
            </p>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={`/icons/funnel/cotation/${data?.expeditionContrainteChargement.icon}`}
              alt="svg"
            />
            <p style={{ marginLeft: '1rem' }}>
              {data?.expeditionContrainteChargement.nom}
            </p>
          </Box>
        </Box>
        <Box className="tableRecap">
          <p>
            <FontAwesomeIcon
              icon={faTruckFast}
              style={{ marginRight: '1rem' }}
            />
            Destination
          </p>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={`/icons/funnel/cotation/${data?.destinationContrainteAcces.icon}`}
              alt="svg"
            />
            <p style={{ marginLeft: '1rem' }}>
              {data?.destinationContrainteAcces.nom}
            </p>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={`/icons/funnel/cotation/${data?.destinationContrainteChargement.icon}`}
              alt="svg"
            />
            <p style={{ marginLeft: '1rem' }}>
              {data?.destinationContrainteChargement.nom}
            </p>
          </Box>
        </Box>
      </div>
    </>
  )
}

export default InstructionsBloc
