/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { apiUrl } from '../../services/Api/request'
import AdminSelectors from '../../components/customElements/AdminSelectors'
import { formatMarchandiseCell } from '../../components/funnels/steps/04-marchandises/utils'

export const columnsHistoriqueAdmin = [
  {
    Header: 'N°',
    accessor: 'id',
    height: '2.6rem',
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <span>{value?.charAt(0).toUpperCase()}</span>,
  },
  {
    Header: 'Mode',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { typeFret },
      } = row
      return (
        <img
          src={`/icons/funnel/cotation/${typeFret?.icon || 'fret-truck.svg'}`}
          alt="svg"
          style={{ width: '26px' }}
        />
      )
    },
  },
  {
    Header: 'Reception',
    accessor: 'createdAt',
    Cell: ({ value }) => <p>{moment(value).format('LL')}</p>,
  },
  {
    Header: 'De',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          expediteur: { nom, prenom, adresse, ville, codePostal },
        },
      } = row
      return (
        <div className="flex-fullinfosExp">
          <h5>{`${nom} ${prenom}`}</h5>
          <p>
            {`${adresse},`} <br /> {`${codePostal} ${ville}`}
          </p>
        </div>
      )
    },
  },
  {
    Header: 'À',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          destinataire: { nom, prenom, adresse, ville, codePostal },
        },
      } = row
      return (
        <div className="flex-fullinfosDest">
          <h5>{`${nom} ${prenom}`}</h5>
          <p>
            {`${adresse},`} <br /> {`${codePostal} ${ville}`}
          </p>
        </div>
      )
    },
  },
  {
    Header: 'Contenu',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { marchandises },
      } = row
      return (
        <div className="flex-fullinfos">
          {marchandises.map((item, index) => (
            <div key={index} className="flex-fullMarchandises">
              <p>{formatMarchandiseCell(item)}</p>
            </div>
          ))}
        </div>
      )
    },
  },
  {
    Header: 'Date',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { date },
      } = row
      return <p>{moment(date).format('LL')}</p>
    },
  },
  {
    Header: 'Marge',
    accessor: 'marge',
    Cell: ({ row }) => {
      const {
        original: { marge, type },
      } = row
      if (type === 'messageries') {
        return <h5>NC</h5>
      }
      return <h5>{`+ ${parseFloat(marge).toFixed(2)} €`}</h5>
    },
  } || null,
  {
    Header: 'Gérer par',
    accessor: 'gestionnaire',
    clickable: false,
    Cell: ({ row: { original } }) => (
      <AdminSelectors disabled data={[]} current={original} />
    ),
  },
  {
    Header: 'Tracking',
    accessor: 'preuve',
    clickable: false,
  },
  {
    Header: '',
    accessor: 'statut.nom',
  },
  {
    Header: '',
    accessor: 'auteur.prenom',
  },
  {
    Header: '',
    accessor: 'auteur.nom',
  },
  {
    Header: '',
    accessor: 'expediteur.prenom',
  },
  {
    Header: '',
    accessor: 'expediteur.nom',
  },
  {
    Header: '',
    accessor: 'expediteur.societe',
  },
  {
    Header: '',
    accessor: 'destinataire.prenom',
  },
  {
    Header: '',
    accessor: 'destinataire.nom',
  },
  {
    Header: '',
    accessor: 'destinataire.societe',
  },
]

// Collaborateurs
export const columnsHistoriqueCollab = [
  {
    Header: 'N°',
    accessor: 'id',
    height: '2.6rem',
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <span>{value?.charAt(0).toUpperCase()}</span>,
  },
  {
    Header: 'Mode',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { typeFret },
      } = row
      return (
        <img
          src={`/icons/funnel/cotation/${typeFret?.icon || 'fret-truck.svg'}`}
          alt="svg"
          style={{ width: '26px' }}
        />
      )
    },
  } || null,
  {
    Header: 'De',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          expediteur: { nom, prenom, adresse, ville, codePostal },
        },
      } = row
      return (
        <div className="flex-fullinfosExp">
          <h5>{`${nom} ${prenom}`}</h5>
          <p>
            {`${adresse},`} <br /> {`${codePostal} ${ville}`}
          </p>
        </div>
      )
    },
  },
  {
    Header: 'À',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          destinataire: { nom, prenom, adresse, ville, codePostal },
        },
      } = row
      return (
        <div className="flex-fullinfosDest">
          <h5>{`${nom} ${prenom}`}</h5>
          <p>
            {`${adresse},`} <br /> {`${codePostal} ${ville}`}
          </p>
        </div>
      )
    },
  },
  {
    Header: 'Contenu',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { marchandises },
      } = row
      return (
        <div className="flex-fullinfos">
          {marchandises.map((item, index) => (
            <div key={index} className="flex-fullMarchandises">
              <p>{formatMarchandiseCell(item)}</p>
            </div>
          ))}
        </div>
      )
    },
  },
  {
    Header: 'Date',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { date },
      } = row
      return <p>{moment(date).format('LL')}</p>
    },
  },
  {
    Header: 'Prix',
    accessor: 'prixVente',
    Cell: ({ row }) => {
      const {
        original: { prixVente, type },
      } = row
      return type === 'cotations' ? (
        <p>{`${parseFloat(prixVente || '0.00').toFixed(2)} €`}</p>
      ) : (
        <p>NC</p>
      )
    },
  },
  {
    Header: 'État',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          statut: { nom, couleur, type },
        },
      } = row
      return (
        <div className={`btn-statuts box-${type}`}>
          <p
            style={{
              color: couleur,
            }}
          >
            {`${nom}`}
          </p>
        </div>
      )
    },
  },
  {
    Header: 'Tracking',
    accessor: '',
    clickable: false,
    Cell: ({ row }) => {
      const {
        original: {
          bordereau,
          preuveLivraison,
          statut: { type: statutType },
          type,
        },
      } = row

      return (
        <ul>
          {bordereau !== null ? (
            type !== 'cotations' ? (
              <li>
                <a
                  className="flex-btn-bordereau"
                  href={`${apiUrl}/uploads/${bordereau}`}
                  download="bordereau"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                  Bon de prise en charge
                </a>
              </li>
            ) : (
              statutType !== 'pending' &&
              statutType !== 'waiting' && (
                <li>
                  <a
                    className="flex-btn-bordereau"
                    href={`${apiUrl}/uploads/${bordereau}`}
                    download="bordereau"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFileArrowDown} />
                    Bon de prise en charge
                  </a>
                </li>
              )
            )
          ) : null}
          {preuveLivraison !== null && preuveLivraison !== '' && (
            <li style={{ marginTop: '.75rem' }}>
              <a
                className="flex-btn-bordereau"
                href={`${apiUrl}/uploads/${preuveLivraison}`}
                download="preuveLivraison"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFileArrowDown} />
                Preuve de livraison
              </a>
            </li>
          )}
        </ul>
      )
    },
  },
  {
    Header: '',
    accessor: 'statut.nom',
  },
  {
    Header: '',
    accessor: 'expediteur.prenom',
  },
  {
    Header: '',
    accessor: 'expediteur.nom',
  },
  {
    Header: '',
    accessor: 'destinataire.prenom',
  },
  {
    Header: '',
    accessor: 'destinataire.nom',
  },
]

export const hiddenColumnsHistorique = [
  'prenom',
  'nom',
  'adresse',
  'codePostal',
  'ville',
  'contenu',
  'preuve',
  'statut',
  'quantite',
  'longueur',
  'largeur',
  'hauteur',
  'typeFret',
  'statut.nom',
  'auteur.prenom',
  'auteur.nom',
  'expediteur.prenom',
  'expediteur.nom',
  'expediteur.societe',
  'destinataire.prenom',
  'destinataire.nom',
  'destinataire.societe',
]
