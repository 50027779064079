import { Avatar, Button as MuiButton, makeStyles } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit'
import { Stack } from '@mui/material'
import React, { createRef, useState } from 'react'
import styled from 'styled-components'
import { apiUrl } from '../../services/Api/request'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: '100px',
    height: '100px',
  },
}))

const ButtonInfo = styled(MuiButton)(() => ({
  color: '#0b0421',
  width: 'fit-content',
  borderRadius: '46px',
  fontFamily: 'Gordita Regular, sans-serif',
  fontSize: '0.8rem',
  padding: '0.4rem 1rem',
  border: '2px solid #0b0421',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  textTransform: 'initial',
  letterSpacing: '0',

  '&:hover': {
    backgroundColor: '#0b0421',
    color: '#ffffff',
    borderRadius: '46px',
  },

  '& -contained': {
    color: '#0b0421',
    width: 'fit-content',
    borderRadius: '46px',
    fontFamily: 'Gordita Regular, sans-serif',
    fontSize: '0.8rem',
    padding: '0.4rem 1rem',
    border: '2px solid #0b0421',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    textTransform: 'initial',
    letterSpacing: '0',

    '&:hover': {
      backgroundColor: '#0b0421',
      color: '#ffffff',
      borderRadius: '46px',
    },
  },
}))

function AvatarUpload({ register, defaultValue }) {
  const classes = useStyles()

  const [image, _setImage] = useState(
    defaultValue ? `${apiUrl}/uploads/${defaultValue}` : null
  )
  const inputFileRef = createRef(null)

  const cleanup = () => {
    URL.revokeObjectURL(image)
    if (inputFileRef.current && inputFileRef.current.value) {
      inputFileRef.current.value = null
    }
  }

  const setImage = (newImage) => {
    if (image) {
      cleanup()
    }
    _setImage(newImage)
  }

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0]
    if (newImage) {
      setImage(URL.createObjectURL(newImage))
    }
  }

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'center', md: 'flex-start' }}
      direction={{ xs: 'column', md: 'row' }}
    >
      <Avatar
        src={image}
        className={classes.large}
        // $withBorder
      />
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onInput={(e) => {
          handleOnChange(e)
        }}
        name="avatarFile"
        {...register('avatarFile')}
      />
      <label htmlFor="avatar-image-upload">
        <p className="small" style={{ marginBottom: '.8rem' }}>
          Veuillez choisir un fichier .jpeg, .jpg ou .png. <br />
          La taille de fichier maximale autorisée est de 200 Ko.
        </p>

        <ButtonInfo variant="contained" component="span" className="btn-icon">
          <EditIcon mr=".6rem" />
          Modifier
        </ButtonInfo>
      </label>
    </Stack>
  )
}

export default AvatarUpload
