import { Grid } from '@material-ui/core'
import { Box } from '@mui/material'
import React from 'react'
import { formatMarchandise } from '../funnels/steps/04-marchandises/utils'

function MarchandisesBloc({ data }) {
  console.log(data)
  return (
    <>
      <h5>Marchandises</h5>
      <div className="card">
        <Box className="table">
          <table>
            <thead>
              <tr>
                <th>
                  <h5>Quantité</h5>
                </th>
                <th>
                  <h5>Format</h5>
                </th>
                <th>
                  <h5>Hauteur</h5>
                </th>
                <th>
                  <h5>Poids (kg)</h5>
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                borderBottom: '1px solid #f6f6f8 !important',
              }}
            >
              {data?.marchandises.map((item) => (
                <tr key={item.id}>
                  <td>{item.quantite}</td>
                  <td>{formatMarchandise(item)}</td>
                  <td>{item.hauteur}</td>
                  <td>{item.poids}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <Grid container style={{ marginBottom: '1.5rem', width: '100%' }}>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              {data?.assurance ? (
                <p>
                  Les marchandises sont assurées pour une valeur de{' '}
                  {data?.assuranceValue} €
                </p>
              ) : (
                <p>Les marchandises ne sont pas assurées.</p>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <p>Palettes gerbables :</p>
              <p style={{ marginLeft: '1rem' }}>
                {data?.gerbable ? 'oui' : 'non'}
              </p>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default MarchandisesBloc
