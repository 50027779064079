/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { Modal, Box } from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'
import { getMany } from '../../services/Api/request'
import BadgeAvatars from '../customElements/BadgeAvatar'

export default function ModalChoiceCollab({
  open,
  setOpen,
  setCollaborateur,
  setOpenCreateContact,
}) {
  const [filteredCollabs, setFilteredCollabs] = React.useState([])
  let filterTimeout

  const { data: collabs } = useQuery('collaborateurs', async () => {
    const data = await getMany('collaborateurs')
    data.forEach((collab) => {
      const { prenom, nom } = collab.client
      collab.fullname = `${nom} ${prenom}`
    })
    return data
  })

  const searchCollaborateur = (e) => {
    clearTimeout(filterTimeout)
    const { value } = e.target
    if (value.length > 2) {
      filterTimeout = setTimeout(() => {
        setFilteredCollabs(
          collabs.filter((collab) =>
            collab.fullname.toLowerCase().includes(value.toLowerCase())
          )
        )
      }, 300)
    } else {
      filterTimeout = setTimeout(() => {
        setFilteredCollabs([])
      }, 300)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setCollaborateur(null)
  }

  const handleChoice = (collab) => {
    setCollaborateur(collab.id)
    setOpen(false)
  }

  const handleOpenCreateContact = () => {
    handleClose()
    setOpenCreateContact(true)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="card-modal">
        <div
          className="card"
          style={{ textAlign: 'center', width: '29rem', padding: '2rem' }}
        >
          <h5 style={{ textAlign: 'center' }}>
            Choisir un client de votre carnet de clientèle
          </h5>
          <Box sx={{ py: 1 }}>
            <label htmlFor="inputEmail">
              Veuillez choisir le client pour lequel vous souhaitez effectuer
              cette demande.
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                marginTop: '1rem',
              }}
            >
              <div
                className="input"
                style={{ marginTop: 'auto', width: '100%' }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Rechercher"
                  autoFocus
                  onChange={searchCollaborateur}
                />
              </div>
              <div
                style={{
                  position: 'relative',
                  width: '75%',
                  height: '2px',
                  background: '#ededed',
                  margin: '1rem auto',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: 'calc(50% + 2px)',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#ededed',
                    background: '#fff',
                    padding: '.5rem',
                  }}
                >
                  OU
                </span>
              </div>
              <button
                type="button"
                className="btn"
                style={{ margin: 'auto' }}
                onClick={handleOpenCreateContact}
              >
                <em>Créer un client</em>
              </button>
            </div>
            {filteredCollabs.map((collab) => (
              <button
                type="button"
                key={collab.id}
                style={{
                  padding: '0',
                }}
                className="list-choice-collab"
                onClick={() => handleChoice(collab)}
              >
                <BadgeAvatars avatarFile={collab.avatarFile} />
                {collab.fullname}
              </button>
            ))}
          </Box>
        </div>
      </div>
    </Modal>
  )
}
