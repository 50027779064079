/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Box, Grid, Button, CardContent } from '@material-ui/core'
import { useQuery } from 'react-query'
import { TextField, Tooltip, FormControlLabel } from '@mui/material'
import { Add } from '@mui/icons-material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toast } from 'react-toastify'
import { getMany } from '../../../../services/Api/request'
import CustomizedProgress from '../../../customElements/CircularProgress'
import { HuubSwitch } from '../../../customElements/Switch'
import MarchandiseCotationItem from './MarchandiseCotationItem'
import ToogleMarchandiseTypes from '../../../toggle/ToogleMarchandiseTypes'
import { verifyMarchandiseContent } from './utils'

export default function MarchandisesCotationStep({
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
  step,
}) {
  const [marchandisesItems, setMarchandisesItems] = useState(
    funnelState.marchandisesItems || []
  )
  const [marchandisesType, setMarchandisesType] = useState(null)
  const [euSwitchQte, setEuSwitchQte] = useState(
    funnelState.euSwitchQte || null
  )
  const [euSwitch, setEuSwitch] = useState(funnelState.euSwitch || false)

  const { data: types, isLoading } = useQuery('marchandise_types', async () => {
    const data = await getMany('marchandise_types', {
      filters: '?type=cotation',
    })
    setMarchandisesType(data[0])
    return data
  })

  const handleChangeType = (_event, value) => {
    setMarchandisesType(value)
  }

  const handleChange = (payload) => {
    setMarchandisesItems(
      marchandisesItems.map((marchandise) => {
        if (marchandise.id === payload.id) {
          return { ...marchandise, ...payload }
        }
        return marchandise
      })
    )
  }

  const handleSwitchEu = () => {
    setEuSwitch(!euSwitch)
  }

  const nextStep = () => {
    if (marchandisesItems.length > 0) {
      if (verifyMarchandiseContent(marchandisesItems)) {
        setFunnelState({
          ...funnelState,
          euSwitch,
          euSwitchQte,
          marchandisesItems: marchandisesItems.map((marchandisesItem) => ({
            ...marchandisesItem,
            type: marchandisesType.id,
          })),
        })
        setFunnelSteps(step, item)
        setActiveStep(item.nextStep)
      } else {
        console.log('error', marchandisesItems)
        toast.error(
          'Veuillez remplir correctement les informations des marchandises'
        )
      }
    } else {
      toast.error('Veuillez ajouter au moins une marchandise')
    }
  }
  const previousStep = () => {
    setFunnelState({
      ...funnelState,
      euSwitch,
      euSwitchQte,
      marchandisesItems: marchandisesItems.map((marchandisesItem) => ({
        ...marchandisesItem,
        type: marchandisesType.id,
      })),
    })
    setFunnelSteps(step, item)
    setActiveStep(item.previousStep)
  }

  const deleteMarchandise = (id) => {
    let baseId = 0
    const newMarchandisesItems = marchandisesItems.filter((marchandise) => {
      if (marchandise.id !== id) {
        baseId += 1
        return { ...marchandise, id: baseId }
      }
      return null
    })

    setMarchandisesItems(newMarchandisesItems)
    setFunnelState((prevState) => ({
      ...prevState,
      marchandisesItems: newMarchandisesItems,
    }))
  }
  const addNewMarchandise = () => {
    let newId = 0
    if (marchandisesItems.length === 0) {
      newId = 1
    } else {
      newId = marchandisesItems.length + 1
    }

    const newMarchandise = {
      id: newId,
    }
    setMarchandisesItems([...marchandisesItems, newMarchandise])
  }

  useEffect(() => {
    if (marchandisesItems.length === 0) {
      addNewMarchandise()
    }
  }, [])

  return (
    <div className="card card-funnel">
      <CardContent>
        <Grid container justifyContent="center">
          <Grid item>
            {isLoading ? (
              <CustomizedProgress />
            ) : (
              <ToogleMarchandiseTypes
                types={types}
                marchandisesType={marchandisesType}
                handleChangeType={handleChangeType}
              />
            )}
          </Grid>
          <Grid item align="center" xs={12} sm={12} md={12}>
            <div className="info">
              <p style={{ textAlign: 'center' }}>
                Indiquez le nombre de palettes par format. <br />
                Dans le cas de palettes gerbées, ne comptez que le nombre de
                palettes au sol.
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-arround',
                borderRadius: '50px',
                boxShadow: '0px 3px 6px #00000029',
                backgroundColor: '#fffffff',
                padding: '0.75rem 1.25rem',
                width: '100%',
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                style={{ sm: { marginBottom: '1.5rem' } }}
              >
                <FormControlLabel
                  control={
                    <HuubSwitch
                      defaultChecked={euSwitch}
                      onChange={(event) => {
                        handleSwitchEu(event.target.value)
                      }}
                      name="type"
                    />
                  }
                  label="Palettes Europe à échanger"
                  labelPlacement="end"
                />
              </Grid>
              {euSwitch && (
                <Grid item xs={12} sm={12} md={2} align="center">
                  <TextField
                    id="inputQuantities"
                    label="Nbr"
                    type="number"
                    defaultValue={euSwitchQte || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(event) => {
                      setEuSwitchQte(event.target.value)
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {marchandisesItems.map(
          (marchandise) =>
            marchandisesItems.length > 0 && (
              <MarchandiseCotationItem
                key={marchandise.id}
                marchandise={marchandise}
                marchandisesType={marchandisesType}
                marchandisesItems={marchandisesItems}
                handleChange={handleChange}
                deleteMarchandise={deleteMarchandise}
              />
            )
        )}
        <Box
          height="max-content"
          width="100%"
          style={{
            marginTop: '1.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                height: '30px',
                width: '30px',
                minWidth: '30px',
                backgroundColor: '#0b0421',
                borderRadius: '100%',
                color: 'white',
              }}
              onClick={() => addNewMarchandise()}
            >
              <Add />
            </Button>
          </Box>
        </Box>
      </CardContent>
      <Tooltip title="Précédent" placement="right">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            left: '2rem',
          }}
          onClick={previousStep}
        >
          <NavigateBeforeIcon />
        </button>
      </Tooltip>
      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
