/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { initialStateMessagerie } from '../stores/init/initialStateMessagerie'
import { authSelector } from '../stores/selectors/authSelectors'
import MessagerieFunnel from '../components/funnels/MessagerieFunnel'
import MessagerieSidebar from '../components/funnels/MessagerieSidebar'

const TitleStepFunnel = ({ state: { step, children } }) => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    const titleStep = initialStateMessagerie[step].title
    const titleChildren = initialStateMessagerie[step].childrens[children].title
    setTitle(titleStep + titleChildren)
  }, [step, children])

  return title
}

function Messagerie(props) {
  const location = useLocation()
  const collaborateurId = location?.state?.collaborateur || null
  const [activeStep, setActiveStep] = useState({ step: 0, children: 0 })
  const [funnelState, setFunnelState] = useState({
    ...(collaborateurId ? { collaborateur: collaborateurId } : {}),
  })
  const [funnelSteps, setFunnelSteps] = useState(initialStateMessagerie)
  const auth = useSelector(authSelector)
  const navigate = useNavigate()

  const updateFunnelSteps = (step, item) => {
    const stepCompleted = !step.childrens[item.id + 1]
    const steps = funnelSteps.map((funnelStep) => {
      if (funnelStep.id === step.id) {
        return {
          ...funnelStep,
          completed: stepCompleted,
          childrens: funnelStep.childrens.map((children) => {
            if (children.id === item.id) {
              return { ...children, completed: true }
            }
            return children
          }),
        }
      }

      return funnelStep
    })

    setFunnelSteps(steps)
  }

  const goDashboard = () => {
    if (auth.isAdmin) {
      navigate('/')
    } else {
      navigate('/demandes')
    }
  }

  return (
    funnelSteps?.length > 0 && (
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          style={{ marginBottom: '.5rem' }}
        >
          <Grid item xs={0} sm={0} md={3}>
            <button type="button" className="breadcrumps" onClick={goDashboard}>
              <ArrowBackIcon />
              <h5>Messagerie</h5>
            </button>
          </Grid>
          <Grid item xs={0} sm={0} md={9}>
            <p className="breadcrumps-step">
              <TitleStepFunnel state={activeStep} />
            </p>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs={0} sm={0} md={3}>
            <MessagerieSidebar
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              funnelSteps={funnelSteps}
              {...props}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MessagerieFunnel
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              funnelState={funnelState}
              setFunnelState={setFunnelState}
              funnelSteps={funnelSteps}
              setFunnelSteps={updateFunnelSteps}
              {...props}
            />
          </Grid>
        </Grid>
      </>
    )
  )
}

export default Messagerie
