import { Box, Button, Grid, InputAdornment } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import { FormControlLabel, TextField } from '@mui/material'
import React from 'react'
import { HuubSwitch } from '../../../customElements/Switch'

function MarchandiseCotationItem({
  marchandise,
  marchandisesType,
  marchandisesItems,
  handleChange,
  deleteMarchandise,
}) {
  return (
    <>
      <Grid
        container
        key={marchandise.id}
        justifyContent={
          marchandisesType?.details?.includes('métrage')
            ? 'space-around'
            : 'space-between'
        }
        display="flex"
        spacing={2}
      >
        <Box
          style={{
            width: '100%',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
            borderBottom: '1px solid #00000029',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h6>marchandise {marchandise.id}</h6>
          <Box>
            <FormControlLabel
              id={marchandise.id}
              control={
                <HuubSwitch
                  defaultChecked={marchandise.gerbable || false}
                  onChange={(event) => {
                    handleChange({
                      id: marchandise.id,
                      gerbable: event.target.value,
                    })
                  }}
                  name="type"
                  value={marchandise.gerbable ? 'Palettes gerbables' : false}
                />
              }
              label="Palettes gerbables"
              labelPlacement="end"
            />
            {marchandisesItems.length > 1 && (
              <Button
                style={{
                  padding: 0,
                  minWidth: 0,
                }}
                onClick={() => deleteMarchandise(marchandise.id)}
              >
                <Close />
              </Button>
            )}
          </Box>
        </Box>

        {marchandisesType?.details?.includes('métrage') ? (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                sx={{ width: '100%' }}
                id="inputLength"
                label="Longueur (mL)"
                type="number"
                defaultValue={marchandise.longueur || null}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mL</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={(event) =>
                  handleChange({
                    id: marchandise.id,
                    longueur: event.target.value,
                  })
                }
                style={{
                  marginRight: 'auto',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                sx={{ width: '100%' }}
                id="inputWeight"
                label="Poids (kg)"
                type="number"
                defaultValue={marchandise.poids || null}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                }}
                onChange={(event) =>
                  handleChange({
                    id: marchandise.id,
                    poids: event.target.value,
                  })
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                sx={{ width: '100%' }}
                id="InputQuantity"
                label="Quantité"
                defaultValue={marchandise.quantite || null}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={(event) =>
                  handleChange({
                    id: marchandise.id,
                    quantite: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                sx={{ width: '100%' }}
                id="inputLength"
                label="Longueur (cm)"
                type="number"
                defaultValue={marchandise.longueur || null}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={(event) =>
                  handleChange({
                    id: marchandise.id,
                    longueur: event.target.value,
                  })
                }
                style={{
                  marginRight: 'auto',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                sx={{ width: '100%' }}
                id="inputWidth"
                label="Largeur (cm)"
                type="number"
                defaultValue={marchandise.largeur || null}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={(event) =>
                  handleChange({
                    id: marchandise.id,
                    largeur: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                sx={{ width: '100%' }}
                id="inputHeight"
                label="Hauteur (cm)"
                type="number"
                defaultValue={marchandise.hauteur || null}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={(event) =>
                  handleChange({
                    id: marchandise.id,
                    hauteur: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                sx={{ width: '100%' }}
                id="inputWeight"
                label="Poids (kg)"
                type="number"
                defaultValue={marchandise.poids || null}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                }}
                onChange={(event) =>
                  handleChange({
                    id: marchandise.id,
                    poids: event.target.value,
                  })
                }
              />
            </Grid>
          </>
        )}
      </Grid>
      {!marchandisesType?.details && (
        <Grid container style={{ marginTop: '1.5rem' }}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              sx={{ width: '100%' }}
              id="inputDetails"
              label="Details"
              type="text"
              defaultValue={marchandise.details || null}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              onChange={(event) =>
                handleChange({
                  id: marchandise.id,
                  details: event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MarchandiseCotationItem
