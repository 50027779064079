import { Avatar, Grid, Modal, CardContent } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { deleteItem, getOne, updateItem } from '../../services/Api/request'
import CustomizedProgress from '../customElements/CircularProgress'

export default function ModalViewCollab({ open, onClose, view: clientId }) {
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()

  const { data: collab, isLoading } = useQuery(
    ['collaborateurs', clientId],

    async () => {
      const data = await getOne(`collaborateurs/${clientId}`)
      return data
    },
    {
      enabled: true,
    }
  )

  const { mutate: updateClient, isLoading: isUpdating } = useMutation(
    async (item) => {
      const response = await updateItem('clients', {
        id: collab.client.id,
        body: JSON.stringify(item),
      })
      return response
    },
    {
      onSuccess: () => {
        toast.success('Collaborateur mis à jour')
        queryClient.refetchQueries(['collaborateurs'])
        onClose()
      },
    }
  )
  const { mutate: deleteClient, isLoading: isDeleting } = useMutation(
    async () => {
      const response = await deleteItem('collaborateurs', {
        id: clientId,
      })
      return response
    },
    {
      onSuccess: () => {
        toast.success('Collaborateur supprimé')
        queryClient.refetchQueries(['collaborateurs'])
        onClose()
      },
    }
  )

  return (
    <Modal open={open} onClose={onClose}>
      <div className="card-modal">
        <div className="card">
          {isLoading ? (
            <CustomizedProgress />
          ) : (
            <form onSubmit={handleSubmit(updateClient)}>
              <CardContent>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <Avatar
                      avatarFile={collab?.client?.avatarFile}
                      sx={{ width: '100px', height: '100px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <label htmlFor="inputCivility">Civilité</label>
                    <div className="input">
                      <select
                        id="inputCivility"
                        name="civilite"
                        type="civility"
                        defaultValue={collab?.client?.civilite}
                        {...register('civilite')}
                      >
                        <option value="M">M.</option>
                        <option value="Mme">Mme</option>
                      </select>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <label htmlFor="inputLastName">Nom</label>
                    <div className="input">
                      <input
                        id="inputLastname"
                        className="form-control"
                        name="nom"
                        type="text"
                        placeholder="Nom"
                        defaultValue={collab?.client?.nom}
                        {...register('nom')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label htmlFor="inputFirstName">Prénom</label>
                    <div className="input">
                      <input
                        id="inputFirstname"
                        className="form-control"
                        name="prenom"
                        type="text"
                        placeholder="Prénom"
                        defaultValue={collab?.client?.prenom}
                        {...register('prenom')}
                      />
                    </div>
                  </Grid>

                  {collab?.client?.type.includes('pro') && (
                    <>
                      <Grid item xs={12} sm={12} md={6}>
                        <label htmlFor="inputSociety">Société</label>
                        <div className="input">
                          <input
                            id="inputSociety"
                            className="form-control"
                            name="societe"
                            type="text"
                            placeholder="Société"
                            defaultValue={collab?.client?.societe}
                            {...register('societe')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <label htmlFor="inputJob">Poste</label>
                        <div className="input">
                          <input
                            id="inputJob"
                            className="form-control"
                            name="poste"
                            type="text"
                            placeholder="Poste"
                            defaultValue={collab?.client?.poste}
                            {...register('poste')}
                          />
                        </div>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={6}>
                    <label htmlFor="inputAddress">Adresse de livraison</label>
                    <div className="input">
                      <input
                        id="inputAddress"
                        className="form-control"
                        name="adresse"
                        type="text"
                        placeholder="Adresse postale"
                        defaultValue={collab?.client?.adresse}
                        {...register('adresse')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <label htmlFor="inputCP">Code postal</label>
                    <div className="input">
                      <input
                        id="inputCP"
                        className="form-control"
                        name="codePostal"
                        type="number"
                        placeholder="33000"
                        defaultValue={collab?.client?.codePostal}
                        {...register('codePostal')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <label htmlFor="inputCity">Ville</label>
                    <div className="input">
                      <input
                        id="inputCity"
                        className="form-control"
                        name="ville"
                        type="text"
                        placeholder="Ville"
                        defaultValue={collab?.client?.ville}
                        {...register('ville')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label htmlFor="inputEmail">Adresse e-mail</label>
                    <div className="input">
                      <input
                        id="Email"
                        className="form-control"
                        name="email"
                        type="email"
                        placeholder="Adresse e-mail"
                        defaultValue={collab?.client?.email}
                        {...register('email')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label htmlFor="inputPhone">Téléphone</label>
                    <div className="input">
                      <input
                        id="Phone"
                        className="form-control"
                        name="telephone"
                        type="phone"
                        placeholder="Numéro de téléphone"
                        defaultValue={collab?.client?.telephone}
                        {...register('telephone')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} display="flex">
                    <button
                      type="button"
                      className="btn-delete"
                      style={{ marginLeft: 'auto' }}
                      onClick={deleteClient}
                    >
                      <em>
                        {isDeleting ? (
                          <CustomizedProgress />
                        ) : (
                          'Supprimer le contact'
                        )}
                      </em>
                    </button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <button type="submit" className="btn">
                      <em>
                        {isUpdating ? (
                          <CustomizedProgress />
                        ) : (
                          'Enregister les modifications'
                        )}
                      </em>
                    </button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          )}
        </div>
      </div>
    </Modal>
  )
}
