/* eslint-disable default-param-last */

import { initialStateAuth } from '../init/initialStateAuth'

export const UPDATE_USER = 'UPDATE_USER'
export const CONNECT_USER = 'CONNECT_USER'
export const CLEAN_AUTH = 'CLEAN_AUTH'

export function authReducer(state = initialStateAuth, action) {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload.user } }
    case CONNECT_USER:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        isAdmin: action.payload.isAdmin,
        isLogged: true,
      }
    case CLEAN_AUTH:
      return {
        ...initialStateAuth,
      }
    default:
      return state
  }
}
