/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { set, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { apiUrl, getOne, updateItem } from '../services/Api/request'
import CustomizedProgress from '../components/customElements/CircularProgress'
import { authSelector } from '../stores/selectors/authSelectors'
import BadgeAvatars from '../components/customElements/BadgeAvatar'
import FretBloc from '../components/HistoricOrders/FretBloc'
import ExpediteurBloc from '../components/HistoricOrders/ExpediteurBloc'
import DestinataireBloc from '../components/HistoricOrders/DestinataireBloc'
import InstructionsBloc from '../components/HistoricOrders/InstructionsBloc'
import MarchandisesBloc from '../components/HistoricOrders/MarchandisesBloc'
import TarificationBloc from '../components/HistoricOrders/TarificationBloc'
import TrackingBloc from '../components/HistoricOrders/TrackingBloc'

export default function HistoriqueOrders() {
  const queryClient = useQueryClient()
  const auth = useSelector(authSelector)
  const { register, handleSubmit } = useForm()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const { type: typeData, id } = useParams()
  const { data, isLoading } = useQuery([typeData, id], async () => {
    const item = await getOne(typeData, { id })
    return item
  })
  const [prixAchat, setPrixAchat] = useState(data?.prixAchat || 0)
  const [prixVente, setPrixVente] = useState(data?.prixVente || 0)

  const { mutate: updateOrder, isLoading: isUpdating } = useMutation(
    async (body) => {
      await updateItem(typeData, {
        id,
        body: JSON.stringify({
          ...body,
          prixAchat: prixAchat || data?.prixAchat,
          prixVente: prixVente || data?.prixVente,
        }),
      })
    },
    {
      onSuccess: () => {
        toast.success('Commande mise à jour')
        queryClient.refetchQueries([typeData, id])
      },
    }
  )

  function textBackBtn() {
    if (from) {
      if (from.includes('historique')) {
        return 'Historique des commandes'
      }
      if (from.includes('commandes')) {
        return 'Nouvelles commandes'
      }
      return 'Dashboard'
    }
    return 'Dashboard'
  }

  return isLoading ? (
    <CustomizedProgress />
  ) : (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        style={{ marginBottom: '.5rem' }}
      >
        <Grid item xs={0} sm={0} md={3}>
          <button
            type="button"
            className="breadcrumps"
            onClick={() => navigate(from, { replace: true })}
          >
            <ArrowBackIcon />
            <h5>{textBackBtn()}</h5>
          </button>
        </Grid>
        <Grid item xs={0} sm={0} md={9}>
          <p className="breadcrumps-step">{`${
            (typeData === 'messageries' && 'Messagerie') ||
            (typeData === 'cotations' && 'Cotation')
          } | Récapitulatif de la demande N°${data?.id}`}</p>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={12} md={3}>
          <div className="sidebar-orders">
            <div className="orders">
              <div className="orders-info">
                <h5>N°{data?.id}</h5>
                <BadgeAvatars avatarFile={data?.avatarFile} />
                <p
                  htmlFor={`${data?.statut.nom} status-checkbox`}
                  className={`btn-statuts box-${data?.statut.type}`}
                >
                  {data?.statut.nom}
                </p>
              </div>
              <div className="orders-details">
                <div className="circle">
                  <h5>A</h5>
                </div>
                <div className="info-transport">
                  <p className="fullname">
                    {`${data?.expediteur.civilite} ${data?.expediteur.prenom} ${data?.expediteur.nom},`}
                  </p>
                  <h5>{`${data?.expediteur.societe}`}</h5>
                </div>
              </div>
              <div className="orders-details">
                <div className="circle">
                  <h5>B</h5>
                </div>
                <div className="info-transport">
                  <p className="fullname">
                    {`${data?.destinataire.civilite} ${data?.destinataire.prenom} ${data?.destinataire.nom},`}
                  </p>
                  <h5>{`${data?.destinataire.societe}`}</h5>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <div className="recap-step">
            <form onSubmit={handleSubmit(updateOrder)}>
              <Grid
                container
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
                style={{ rowGap: '1.5rem', paddingBottom: '1.5rem' }}
              >
                <FretBloc data={data} typeData={typeData} />
                <Grid item xs={12} sm={12} md={6}>
                  <ExpediteurBloc data={data} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DestinataireBloc data={data} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InstructionsBloc data={data} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MarchandisesBloc data={data} />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TarificationBloc
                    auth={auth}
                    data={data}
                    prixAchat={prixAchat}
                    prixVente={prixVente}
                    typeData={typeData}
                    setPrixAchat={setPrixAchat}
                    setPrixVente={setPrixVente}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TrackingBloc
                    auth={auth}
                    data={data}
                    typeData={typeData}
                    register={register}
                  />
                </Grid>
                {auth.isAdmin ? (
                  <div>
                    {isUpdating ? (
                      <CustomizedProgress />
                    ) : (
                      <button type="submit" className="btn">
                        <em>Enregister les modifications</em>
                      </button>
                    )}
                  </div>
                ) : null}
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
