import { Grid } from '@material-ui/core'
import React from 'react'

function FretBloc({ typeData, data }) {
  return typeData === 'cotations' ? (
    <>
      <Grid item xs={12} sm={12} md={6}>
        <h5>Type de fret</h5>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <div
          className="card"
          style={{
            padding: '1rem 2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={`/icons/funnel/cotation/${data?.typeFret.icon}`}
            alt="svg"
            style={{ width: '42px', margin: 'auto' }}
          />
        </div>
      </Grid>
    </>
  ) : null
}

export default FretBloc
