/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-constructed-context-values */
import jwtDecode from 'jwt-decode'
import React, { createContext, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cleanAuth, connectUserAction } from '../stores/actions/authActions'
import { authSelector } from '../stores/selectors/authSelectors'
import { getMeWithToken } from './Api/request'

export const adminRole = 'ROLE_ADMIN'
export const collaborateurRole = 'ROLE_COLLABORATEUR'

export const AuthContext = createContext(null)

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [role, setRole] = useState(null)
  const dispatch = useDispatch()

  const handleConnect = async (token, refreshToken) => {
    const me = await getMeWithToken(token)
    const decodedToken = jwtDecode(JSON.stringify(token))
    decodedToken.roles.splice(decodedToken.roles.indexOf('ROLE_USER'), 1)
    const currentRole = decodedToken.roles[0]

    dispatch(
      connectUserAction({
        token,
        refreshToken,
        isLogged: true,
        user: me,
        isAdmin: currentRole === adminRole,
        isCollab: currentRole === collaborateurRole,
      })
    )
  }

  const signin = async (data, callback) => {
    const response = await toast.promise(
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/login_check`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      }),
      {
        pending: 'vérification de connexion',
      }
    )
    const responseData = await response.json()
    if (response.ok) {
      const { token, refresh_token } = responseData
      /**
       * DEPRECATED
       */
      localStorage.setItem('token', token)
      localStorage.setItem('refresh_token', refresh_token)
      /** */
      const decodedToken = jwtDecode(
        JSON.stringify(localStorage.getItem('token'))
      )
      decodedToken.roles.splice(decodedToken.roles.indexOf('ROLE_USER'), 1)
      setRole(decodedToken.roles[0])
      setUser(responseData.token)
      await handleConnect(token, refresh_token)
      callback()
    } else if (responseData?.message?.includes('credential')) {
      toast.error('E-mail ou mot de passe invalide')
    }
  }

  const signout = (callback) => {
    setUser(null)
    setRole(null)
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    dispatch(cleanAuth())
    callback()
  }

  const registerUser = (token, callback) => {
    const decodedToken = jwtDecode(
      JSON.stringify(localStorage.getItem('token'))
    )
    decodedToken.roles.splice(decodedToken.roles.indexOf('ROLE_USER'), 1)
    setRole(decodedToken.roles[0])
    setUser(token)
    callback()
  }

  const value = { user, role, signin, signout, registerUser }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function RequireAuth({ children, name, title, container }) {
  const location = useLocation()
  const auth = useSelector(authSelector)
  const dispatch = useDispatch()

  const connectWithTokens = async (token, refreshToken) => {
    const me = await getMeWithToken(token)
    const decodedToken = jwtDecode(JSON.stringify(token))
    decodedToken.roles.splice(decodedToken.roles.indexOf('ROLE_USER'), 1)
    const currentRole = decodedToken.roles[0]

    dispatch(
      connectUserAction({
        token,
        refreshToken,
        isLogged: true,
        user: me,
        isAdmin: currentRole === adminRole,
        isCollab: currentRole === collaborateurRole,
      })
    )
    return true
  }

  if (!auth.user) {
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refresh_token')

    if (token && refreshToken) {
      connectWithTokens(token, refreshToken)
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />
    }
  }

  return (
    <div className={container ? 'container' : ''}>
      <div className={`section-${name}`}>
        {title && <h4>{title}</h4>}
        {children}
      </div>
    </div>
  )
}
