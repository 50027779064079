import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'

function ToogleMarchandiseTypes({ types, marchandisesType, handleChangeType }) {
  return (
    <ToggleButtonGroup
      className="toggle-button-group"
      exclusive
      onChange={handleChangeType}
      sx={{
        alignItems: 'center',
        justifycontent: 'space-between',
        gap: 3,
      }}
    >
      {types.map((type) => (
        <ToggleButton
          className={`toggle-btn-2 ${
            marchandisesType?.id === type.id ? ' select ' : ''
          }`}
          key={type.id}
          active={type}
          value={type}
        >
          {type.nom}
          <br />
          <span>{type.details || <br />}</span>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default ToogleMarchandiseTypes
