import * as React from 'react'
// import TextField from '@mui/material/TextField'
import DateRangePicker from '@mui/lab/DateRangePicker'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import MuiDateRangePickerDay from '@mui/lab/DateRangePickerDay'

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting }) => ({
    ...(isHighlighting && {
      borderRadius: 0,
      fontSize: theme.typography,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  })
)

export default function DateRangePickerFilter() {
  const [value, setValue] = React.useState([null, null])

  const renderWeekPickerDay = (date, dateRangePickerDayProps) => (
    <DateRangePickerDay {...dateRangePickerDayProps} />
  )

  return (
    <DateRangePicker
      label="Choississez vos dates"
      value={value}
      renderDay={renderWeekPickerDay}
      onChange={(newValue) => setValue(newValue)}
      renderInput={(startProps, endProps) => (
        <>
          <div className="input">
            <input ref={startProps.inputRef} {...startProps.inputProps} />
          </div>
          <Box sx={{ mx: 1 }}> </Box>
          <div className="input">
            <input ref={endProps.inputRef} {...endProps.inputProps} />
          </div>
        </>
      )}
    />
  )
}
