import { Grid, Box, Modal } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { apiUrl } from '../../services/Api/request'

export default function ModalResetPassword({ open, onClose }) {
  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => {
    fetch(`${apiUrl}/reset_password_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          toast.success(
            'Un e-mail vous a été envoyé pour réinitialiser votre mot de passe.'
          )
          onClose()
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        toast.error(
          'Un problème est survenu, êtes-vous sur que votre adresse e-mail est valide ? '
        )
      })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="card-modal">
        <div className="card">
          <h5>Mot de passe oublié</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ py: 1 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <label htmlFor="inputEmail">
                    Veuillez inscrire votre e-mail pour recevoir un nouveau mot
                    de passe.
                  </label>
                  <div className="input">
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      className="form-control"
                      {...register('email')}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <button type="submit" className="btn">
              <em>Envoyer</em>
            </button>
          </form>
        </div>
      </div>
    </Modal>
  )
}
