/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { Box, CardContent, Grid, Modal, Checkbox } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { createItem } from '../../services/Api/request'

export default function ModalCreateContactCollab({
  open,
  handleClose,
  clientId,
  collaborateurId,
}) {
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()

  const [checkedPro, setCheckedPro] = useState(true)
  const [checkedIndividual, setCheckedIndividual] = useState(false)
  // const { id: collaborateurId } = useParams()

  const { mutate } = useMutation(
    async (data) => {
      await createItem('clients', {
        id: clientId,
        body: JSON.stringify({
          collaborateur: collaborateurId,
          ...data,
          type: checkedPro ? 'professionnel' : 'particulier',
        }),
      })
    },
    {
      onSuccess: () => {
        toast.success('Client créé')
        if (collaborateurId) {
          queryClient.refetchQueries(['collaborateurs', collaborateurId])
        } else {
          queryClient.refetchQueries(['clients'])
        }
        handleClose()
      },
    }
  )
  const checkHandler = () => {
    setCheckedPro(!checkedPro)
    setCheckedIndividual(!checkedIndividual)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="card-modal">
        <div className="card">
          <form onSubmit={handleSubmit(mutate)}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: '1.5rem',
                }}
              >
                <label htmlFor="isPro" style={{ fontSize: '1em' }}>
                  <p style={{ marginTop: '4.5px' }}>Professionnel</p>
                </label>
                <Checkbox
                  id="isPro"
                  checked={checkedPro}
                  onChange={() => {
                    checkHandler()
                  }}
                />

                <Checkbox
                  id="isIndividual"
                  checked={checkedIndividual}
                  onChange={() => {
                    checkHandler()
                  }}
                />

                <label htmlFor="isIndividual" style={{ fontSize: '1em' }}>
                  <p style={{ marginTop: '4.5px' }}>Particulier</p>
                </label>
              </Box>
              <Grid container justifyContent="center" spacing={2}>
                <input
                  type="hidden"
                  name="type"
                  value={checkedPro ? 'professionnel' : 'particulier'}
                  {...register('type')}
                />
                <Grid item xs={12} sm={12} md={2}>
                  <label htmlFor="inputCivility">Civilité</label>
                  <div className="input">
                    <select
                      type="civility"
                      name="civilite"
                      id="inputCivility"
                      {...register('civilite')}
                    >
                      <option value="M">M.</option>
                      <option value="Mme">Mme</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <label htmlFor="inputLastName">Nom</label>
                  <div className="input">
                    <input
                      type="text"
                      name="nom"
                      {...register('nom')}
                      id="inputLastname"
                      placeholder="Nom"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputFirstName">Prénom</label>
                  <div className="input">
                    <input
                      type="text"
                      name="prenom"
                      {...register('prenom')}
                      id="inputFirstname"
                      placeholder="Prénom"
                      className="form-control"
                    />
                  </div>
                </Grid>
                {checkedPro && (
                  <>
                    <Grid item xs={12} sm={12} md={6}>
                      <label htmlFor="inputSociety">Société</label>
                      <div className="input">
                        <input
                          type="text"
                          name="societe"
                          {...register('societe')}
                          id="inputSociety"
                          placeholder="Société"
                          className="form-control"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <label htmlFor="inputJob">Poste</label>
                      <div className="input">
                        <input
                          type="text"
                          name="poste"
                          {...register('poste')}
                          id="inputJob"
                          placeholder="Poste"
                          className="form-control"
                        />
                      </div>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputAddress">Adresse de livraison</label>
                  <div className="input">
                    <input
                      type="text"
                      name="adresse"
                      {...register('adresse')}
                      id="inputAddress"
                      placeholder="Adresse postale"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <label htmlFor="inputCP">Code postal</label>
                  <div className="input">
                    <input
                      type="number"
                      name="codePostal"
                      {...register('codePostal')}
                      id="inputCP"
                      placeholder="33000"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <label htmlFor="inputCity">Ville</label>
                  <div className="input">
                    <input
                      type="text"
                      name="ville"
                      {...register('ville')}
                      id="inputCity"
                      placeholder="Ville"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputEmail">Adresse e-mail</label>
                  <div className="input">
                    <input
                      type="email"
                      name="email"
                      {...register('email')}
                      id="Email"
                      placeholder="Adresse e-mail"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputPhone">Téléphone</label>
                  <div className="input">
                    <input
                      type="phone"
                      name="telephone"
                      {...register('telephone')}
                      id="Phone"
                      placeholder="Numéro de téléphone"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <button
                    type="submit"
                    className="btn"
                    style={{ marginRight: 'auto' }}
                  >
                    <em>Enregister le contact</em>
                  </button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </div>
      </div>
    </Modal>
  )
}
