import React from 'react'
import { Grid, IconButton, Toolbar, Tooltip } from '@mui/material'
import { Archive } from '@mui/icons-material'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import TypeFilter from '../filters/TypeFilter'
import CustomDateRangePicker from '../datePicker/CustomDateRangePicker'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '48px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: '#FF00B4',
          backgroundColor: lighten('#FF00B4', 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    fontSize: '0.8rem',
    flex: '1 1 100%',
  },
}))

export default function HistoricSearchToolbar({
  globalFilter,
  setFilter,
  setGlobalFilter,
  numSelected,
  updateStatus,
  totalMarge,
  setFromDate = () => {},
  setToDate = () => {},
}) {
  const classes = useToolbarStyles()
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="search-bar"
        marginBottom={3}
      >
        <Grid item xs={12} sm={12} md={3} mb={{ xs: 2, sm: 2, md: 0 }}>
          <div className="input">
            <input
              type="search"
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Recherche"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} mb={{ xs: 2, sm: 2, md: 0 }}>
          <CustomDateRangePicker
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} mb={{ xs: 2, sm: 2, md: 0 }}>
          <div className="input">
            <input
              type="search"
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder={`Total marge : ${totalMarge.toFixed(2)} €`}
            />
          </div>
        </Grid>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            margin: '1rem 0',
          }}
        >
          <TypeFilter setFilter={setFilter} />
        </Box>
      </Grid>
      {numSelected > 0 ? (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <p className={classes.title}>
            Vous avez selectionné {numSelected} commande(s).
          </p>
          <Tooltip title="Archiver">
            <IconButton aria-label="archiver" onClick={updateStatus}>
              <Archive />
            </IconButton>
          </Tooltip>
        </Toolbar>
      ) : null}
    </>
  )
}
