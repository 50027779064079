import { Box, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'

export default function TypeFilter({ setFilter }) {
  const [cotationFilter, setCotationFilter] = useState(false)
  const [messagerieFilter, setMessagerieFilter] = useState(false)

  useEffect(() => {
    let value = ''
    if (cotationFilter) {
      value = 'cotation'
    }
    if (messagerieFilter) {
      value = 'messagerie'
    }
    setFilter('type', value)
  }, [cotationFilter, messagerieFilter])
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Checkbox
          id="messagerie"
          checked={messagerieFilter}
          onChange={(event) => {
            setMessagerieFilter(event.target.checked)
            if (event.target.checked) {
              setCotationFilter(false)
            }
          }}
        />
        <label htmlFor="messagerie" style={{ fontSize: '1em' }}>
          <p style={{ marginTop: '4.5px' }}>Messagerie</p>
        </label>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Checkbox
          id="cotation"
          checked={cotationFilter}
          onChange={(event) => {
            setCotationFilter(event.target.checked)
            if (event.target.checked) {
              setMessagerieFilter(false)
            }
          }}
        />
        <label htmlFor="cotation" style={{ fontSize: '1em' }}>
          <p style={{ marginTop: '4.5px' }}>Cotation</p>
        </label>
      </Box>
    </Box>
  )
}
