import React, { useState } from 'react'
import { Box, CardContent, Grid } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toast } from 'react-toastify'

export default function TaxeStep({
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
  step,
}) {
  const [incotermSelect, setIncotermSelect] = useState(
    funnelState.incotermSelect || null
  )

  const handleChangeIncotermType = (event, value) => {
    setIncotermSelect(value)
  }
  const nextStep = () => {
    if (incotermSelect) {
      setFunnelState({
        ...funnelState,
        incotermSelect,
      })
      setFunnelSteps(step, item)
      setActiveStep(item.nextStep)
    } else {
      toast.error(
        'Si vous ne souhaitez pas choisir un incoterm veuillez cliquer sur "Ignorer cette étape"'
      )
    }
  }
  const previousStep = () => {
    setFunnelState({
      ...funnelState,
      incotermSelect,
    })
    setFunnelSteps(step, item)
    setActiveStep(item.previousStep)
  }
  const handleIgnore = () => {
    setIncotermSelect(null)
    setFunnelState({
      ...funnelState,
      incotermSelect,
    })
    setFunnelSteps(step, item)
    setActiveStep(item.nextStep)
  }

  return (
    <div className="card card-funnel">
      <CardContent>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item align="center" xs={12} sm={12} md={12}>
            <div className="info">
              <p>
                Dans le cadre d’un fret air/terre/merre/fer en Europe ou à
                l’international, merci d’indiquer les incoterms choisis. Dans le
                cas contraire vous pouvez passer à l'étape suivante.
              </p>
            </div>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            <Box sx={{ py: 2 }}>
              <p>Indiquez les incoterms :</p>
            </Box>

            <ToggleButtonGroup
              value={incotermSelect}
              exclusive
              onChange={handleChangeIncotermType}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'EXW' ? 'active' : ''
                }`}
                value="EXW"
              >
                EXW
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'FCA' ? 'active' : ''
                }`}
                value="FCA"
              >
                FCA
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'CPT' ? 'active' : ''
                }`}
                value="CPT"
              >
                CPT
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'CIP' ? 'active' : ''
                }`}
                value="CIP"
              >
                CIP
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'DPU' ? 'active' : ''
                }`}
                value="DPU"
              >
                DPU
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'DAP' ? 'active' : ''
                }`}
                value="DAP"
              >
                DAP
              </ToggleButton>

              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'DDP' ? 'active' : ''
                }`}
                value="DDP"
              >
                DDP
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'FAS' ? 'active' : ''
                }`}
                value="FAS"
              >
                FAS
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'FOB' ? 'active' : ''
                }`}
                value="FOB"
              >
                FOB
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'CFR' ? 'active' : ''
                }`}
                value="CFR"
              >
                CFR
              </ToggleButton>
              <ToggleButton
                className={`toggle-btn-2 icoterms-btn ${
                  funnelState.incotermSelect === 'CIF' ? 'active' : ''
                }`}
                value="CIF"
              >
                CIF
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item align="center" xs={12} sm={12} md={12}>
            <Box sx={{ py: 2 }}>
              <p>Les taxes sont à la charge de :</p>
            </Box>
          </Grid>
          <Grid item align="center" xs={12} sm={12} md={4}>
            <button
              type="button"
              className="btn"
              style={{ width: '100%' }}
              // onClick={() => setMarchandisesAssurance(true)}
            >
              <em>Expéditeur</em>
            </button>
          </Grid>
          <Grid item align="center" xs={12} sm={12} md={4}>
            <button
              type="button"
              className="btn"
              style={{ width: '100%' }}
              // onClick={() => setMarchandisesAssurance(false)}
            >
              <em>Destinataire</em>
            </button>
          </Grid>
        </Grid> */}
        </Grid>
      </CardContent>
      <Tooltip title="Précédent" placement="right">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            left: '2rem',
          }}
          onClick={previousStep}
        >
          <NavigateBeforeIcon />
        </button>
      </Tooltip>
      <button
        type="button"
        className="btn-inco ignore-text"
        onClick={() => handleIgnore()}
      >
        <em>Ignorer cette étape</em>
      </button>
      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
