// higher order component to add same functionality to each page
import React from 'react'
import { motion } from 'framer-motion'

const MotionHoc = (Component) =>
  function HOC(props) {
    return (
      <motion.div
        initial={{
          width: '100%',
          height: '100%',
          y: 500,
        }}
        animate={{
          width: '100%',
          height: '100%',
          y: 0,
          transition: { duration: 1, type: 'spring' },
        }}
        exit={{
          width: '100%',
          height: '100%',
          y: -500,
          transition: { duration: 1, type: 'spring', ease: 'easeInOut' },
        }}
      >
        <Component {...props} />
      </motion.div>
    )
  }

export default MotionHoc
