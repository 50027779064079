import React from 'react'
import BadgeAvatars from '../../components/customElements/BadgeAvatar'

export const columnsCarnetAdmin = [
  {
    Header: 'Nom, Prénom',
    accessor: 'id',
    Cell: ({ row }) => {
      const {
        original: {
          client: { avatarFile, prenom, nom },
        },
      } = row
      return (
        <div className="flex-img-fullname">
          <BadgeAvatars avatarFile={avatarFile} />
          <p>{`${nom} ${prenom}`}</p>
        </div>
      )
    },
  },
  {
    Header: '',
    accessor: 'view',
  },
  // {
  //   Header: 'Classe',
  //   accessor: '',
  //   Cell: ({ row }) => {
  //     const {
  //       original: {
  //         categorie: { nom, couleur },
  //       },
  //     } = row
  //     return (
  //       <div
  //         className="btn-statuts"
  //         // style={{
  //         //   backgroundColor:
  //         //     (nom === 'VIP2' && 'rgba(255, 145, 0, 0.2)') ||
  //         //     (nom === 'Autre1' && 'rgba(0, 189, 255, 0.2)') ||
  //         //     (nom === 'Autre2' && 'rgba(255, 0, 0, 0.2'),
  //         // }}
  //       >
  //         <p
  //           style={{
  //             color: couleur,
  //           }}
  //         >
  //           {`${nom}`}
  //         </p>
  //       </div>
  //     )
  //   },
  // },
  {
    Header: '',
    accessor: 'client.categorie',
  },
  {
    Header: '',
    accessor: 'client.nom',
  },
  {
    Header: '',
    accessor: 'client.prenom',
  },
  {
    Header: '',
    accessor: 'client.poste',
  },
  {
    Header: '',
    accessor: 'client.ville',
  },
  {
    Header: '',
    accessor: 'client.adresse',
  },
  {
    Header: '',
    accessor: 'client.codePostal',
  },
  {
    Header: 'Entreprise',
    accessor: 'client.societe',
  },
  {
    Header: 'E-mail',
    accessor: 'client.email',
  },
  {
    Header: 'Téléphone',
    accessor: 'client.telephone',
  },
  {
    Header: 'Adresse',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          client: { adresse, codePostal, ville },
        },
      } = row
      return (
        <div className="flex-fulladresse">
          <p>{`${adresse}, ${codePostal} ${ville}`}</p>
        </div>
      )
    },
  },
]

export const columnsCarnetAdminClient = [
  {
    Header: 'Nom, Prénom',
    accessor: 'id',
    Cell: ({ row }) => {
      const {
        original: { avatarFile, prenom, nom },
      } = row
      return (
        <div className="flex-img-fullname">
          <BadgeAvatars avatarFile={avatarFile} />
          <p>{`${nom} ${prenom}`}</p>
        </div>
      )
    },
  },
  {
    Header: '',
    accessor: 'view',
  },
  {
    Header: 'Entreprise',
    accessor: 'societe',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
  },
  {
    Header: 'Téléphone',
    accessor: 'telephone',
  },
  {
    Header: 'Adresse',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { adresse, codePostal, ville },
      } = row
      return (
        <div className="flex-fulladresse">
          <p>{`${adresse}, ${codePostal} ${ville}`}</p>
        </div>
      )
    },
  },
  {
    Header: '',
    accessor: 'nom',
  },
  {
    Header: '',
    accessor: 'prenom',
  },
  {
    Header: '',
    accessor: 'ville',
  },
  {
    Header: '',
    accessor: 'adresse',
  },
  {
    Header: '',
    accessor: 'codePostal',
  },
]

// Collaborateur
export const columnsCarnetCollab = [
  {
    Header: 'Nom, Prénom',
    accessor: 'id',
    Cell: ({ row }) => {
      const {
        original: { avatarFile, prenom, nom },
      } = row
      return (
        <div className="flex-img-fullname">
          <BadgeAvatars avatarFile={avatarFile} />
          <p>{`${nom} ${prenom}`}</p>
        </div>
      )
    },
  },
  {
    Header: '',
    accessor: 'view',
  },
  {
    Header: 'Entreprise',
    accessor: 'societe',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
  },
  {
    Header: 'Téléphone',
    accessor: 'telephone',
  },
  {
    Header: 'Adresse',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { adresse, codePostal, ville },
      } = row
      return (
        <div className="flex-fulladresse">
          <p>{`${adresse}, ${codePostal} ${ville}`}</p>
        </div>
      )
    },
  },
]

export const hiddenColumnsCarnet = [
  'nom',
  'prenom',
  'ville',
  'adresse',
  'codePostal',
  'client.nom',
  'client.prenom',
  'client.poste',
  'client.ville',
  'client.adresse',
  'client.codePostal',
  'client.categorie',
]
