/* eslint-disable no-nested-ternary */
import React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQueries } from 'react-query'
import Dashboard from './Dashboard'
import { authSelector } from '../stores/selectors/authSelectors'
import { getMany } from '../services/Api/request'

export default function Home() {
  const auth = useSelector(authSelector)
  const navigate = useNavigate()
  const [
    { data: cotations, isLoading: isLoadingCotations },
    { data: messageries, isLoading: isLoadingMessageries },
  ] = useQueries([
    {
      queryKey: 'cotations',
      queryFn: async () => {
        const data = await getMany('cotations', {
          filters: '?statuses=pending,waiting',
        })
        return data
      },
    },
    {
      queryKey: 'messageries',
      queryFn: async () => {
        const data = await getMany('messageries', {
          filters: '?statuses=pending,waiting',
        })
        return data
      },
    },
  ])

  if (auth.isAdmin) {
    return (
      <>
        <h4>
          Vous avez{' '}
          <span>
            {!isLoadingCotations &&
              !isLoadingMessageries &&
              cotations.length + messageries.length}
          </span>{' '}
          demandes de prise en charge en attente
        </h4>
        <div className="info-dashboard">
          <button
            type="button"
            className="btn"
            onClick={() => navigate('/commandes')}
            style={{ marginBottom: '1rem' }}
          >
            <Link to="/commandes">
              <em>Voir les demandes à traiter</em>
            </Link>
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => navigate('/demandes')}
            style={{ marginBottom: '1rem' }}
          >
            <Link to="/demandes">
              <em>Faire une nouvelle demande</em>
            </Link>
          </button>
        </div>
        <div>
          <Dashboard />
        </div>
      </>
    )
  }
  return <Navigate to="/demandes" replace />
}
