import { Box, Button, Divider, MenuItem, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../services/security'
import {
  authSelector,
  userSelector,
} from '../../stores/selectors/authSelectors'
import BadgeAvatars from '../customElements/BadgeAvatar'
import { CustomLink } from '../customElements/CustomLink'
import MenuPopover from './MenuPopover'

export default function AccountPopover() {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const auth = useSelector(authSelector)
  const user = useSelector(userSelector)
  const { signout } = useAuth()
  const navigate = useNavigate()

  const logout = () => {
    signout(() => {
      navigate('/login')
    })
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button ref={anchorRef} onClick={handleOpen}>
        <BadgeAvatars avatarFile={user?.avatarFile} />
      </Button>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef?.current}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {auth.isAdmin ? user?.prenom : user?.client?.prenom}{' '}
            {auth.isAdmin ? user?.nom : user?.client?.nom}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {auth.isAdmin ? user?.email : user?.client?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <MenuItem
            className="profil"
            to="/compte"
            component={CustomLink}
            onClick={handleClose}
            sx={{ typography: 'body2', my: 1, px: 2.5 }}
          >
            <span>Mon Compte</span>
          </MenuItem>
        </Box>

        <Box
          sx={{
            p: 0.5,
            pt: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <button type="button" onClick={logout} className="btn">
            <em>Déconnexion</em>
          </button>
        </Box>
      </MenuPopover>
    </>
  )
}
