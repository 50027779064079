/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import { ContactsTable, ClassicSearchToolbar } from '../components/tables'
import CustomizedProgress from '../components/customElements/CircularProgress'
import { getMany } from '../services/Api/request'
import {
  columnsCarnetAdmin,
  columnsCarnetCollab,
  hiddenColumnsCarnet,
} from '../utils/columns/columnsCarnet'
import MotionHoc from '../components/MotionHoc'
import ModalCreateContact from '../components/modals/ModalCreateContact'
import { authSelector } from '../stores/selectors/authSelectors'

function ContactsComponent() {
  const [open, setOpen] = useState(false)
  const auth = useSelector(authSelector)
  const handleOpen = () => setOpen(true)
  const queryKey = auth.isAdmin ? 'collaborateurs' : 'clients'
  const columns = auth.isAdmin ? columnsCarnetAdmin : columnsCarnetCollab

  const handleClose = () => {
    setOpen(false)
  }
  const { data: users, isLoading } = useQuery(queryKey, async () => {
    const data = await getMany(queryKey)
    return data
  })

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          boxShadow: 'inherit !important',
          overflow: 'hidden',
          paddingTop: '2rem',
        }}
      >
        {isLoading ? (
          <CustomizedProgress />
        ) : (
          <ContactsTable
            columns={columns}
            data={users}
            hiddenColumns={hiddenColumnsCarnet}
            toolbar={ClassicSearchToolbar}
            toolbarProps={{ callback: handleOpen }}
            clickable
          />
        )}
      </Paper>

      <ModalCreateContact open={open} handleClose={handleClose} />
    </>
  )
}

const Contacts = MotionHoc(ContactsComponent)

export default Contacts
