import React from 'react'
import { Popover } from '@mui/material'

export default function MenuPopover({ children, sx, ...other }) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ ...sx }}
      {...other}
    >
      {children}
    </Popover>
  )
}
