/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import { AnimatePresence } from 'framer-motion'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/lab'
import AdapterMoment from '@mui/lab/AdapterMoment'
import moment from 'moment'
import { AuthProvider, RequireAuth } from './services/security'
import NotFound from './pages/NotFound'
import { routes } from './routes'
import Navigation from './components/Navigation'
import 'react-toastify/dist/ReactToastify.css'
import customTheme from './customTheme'
import 'moment/locale/fr'

moment.locale('fr')
moment().format('L')
// moment('DD-MM-YYYY', 'fr', true)

const queryClient = new QueryClient()

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <ThemeProvider theme={customTheme}>
          <AuthProvider>
            <Router>
              <AnimatePresence exitBeforeEnter>
                <Routes>
                  {/* <Navigation /> */}
                  {routes.map((route, index) => {
                    const {
                      component: Component,
                      name,
                      path,
                      title,
                      container,
                      requireAuth,
                      roles,
                    } = route
                    return requireAuth ? (
                      <Route
                        key={index.toString() + name}
                        path={path}
                        exact
                        element={
                          <>
                            <Navigation />
                            <RequireAuth
                              name={name}
                              title={title ?? null}
                              container={container ?? null}
                              roles={roles}
                            >
                              <Component />
                            </RequireAuth>
                          </>
                        }
                      />
                    ) : (
                      <Route
                        key={index.toString() + name}
                        path={path}
                        exact
                        element={<Component />}
                      />
                    )
                  })}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AnimatePresence>
            </Router>
          </AuthProvider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </LocalizationProvider>
  )
}

export default App
