/* eslint-disable no-unsafe-negation */
/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode'

const refreshToken = async (currentRefreshToken) => {
  const req = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/token_refresh`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: currentRefreshToken,
      }),
    }
  )
  const res = await req.json()
  localStorage.setItem('token', res.token)
  localStorage.setItem('refresh_token', res.refresh_token)

  return res
}

export const httpClient = async (url, params = {}) => {
  if (!params.headers) {
    params.headers = {
      Accept: 'application/json',
    }

    if (!(params.body instanceof FormData)) {
      params.headers['Content-Type'] = 'application/json'
    }
  }

  const currentToken = localStorage.getItem('token')
  const { iat, exp } = jwtDecode(JSON.stringify(currentToken))
  const timeDifference = exp - iat
  const currentTime = Math.trunc(Date.now() / 1000)

  if (currentTime - iat >= timeDifference - 10) {
    if (localStorage.getItem('refresh_token')) {
      const { token: newToken } = await refreshToken(
        localStorage.getItem('refresh_token')
      )
      params.headers.Authorization = `Bearer ${newToken}`
      return fetch(url, params)
    }
    localStorage.removeItem('token')
    return new Response(
      JSON.stringify({
        message: 'Impossible de rafraichir votre accés, reconnectez-vous',
      }),
      { status: 401 }
    )
  }
  params.headers.Authorization = `Bearer ${currentToken}`
  return fetch(url, params)
}
