/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Button,
  InputAdornment,
  CardContent,
} from '@material-ui/core'
import { useQuery } from 'react-query'
import {
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Tooltip,
  FormControlLabel,
} from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toast } from 'react-toastify'
import { Add, Close } from '@mui/icons-material'
import { getMany } from '../../../../services/Api/request'
import CustomizedProgress from '../../../customElements/CircularProgress'
import { HuubSwitch } from '../../../customElements/Switch'

export default function MarchandisesMessagerieStep({
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
  step,
}) {
  const [marchandisesItems, setMarchandisesItems] = useState(
    funnelState.marchandisesItems || []
  )
  const [marchandisesType, setMarchandisesType] = useState(null)
  const [euSwitchQte, setEuSwitchQte] = useState(
    funnelState.euSwitchQte || null
  )
  const [euSwitch, setEuSwitch] = useState(funnelState.euSwitch || false)

  const { data: types, isLoading } = useQuery('marchandise_types', async () => {
    const data = await getMany('marchandise_types', {
      filters: '?type=messagerie',
    })
    setMarchandisesType(data[0])
    return data
  })

  const handleChangeType = (_event, value) => {
    setMarchandisesType(value)
  }

  const handleChange = (payload) => {
    setMarchandisesItems(
      marchandisesItems.map((marchandise) => {
        if (marchandise.id === payload.id) {
          return { ...marchandise, ...payload }
        }
        return marchandise
      })
    )
  }

  const handleSwitchEu = () => {
    setEuSwitch(!euSwitch)
  }

  function verifyMarchandiseContent() {
    for (let i = 0; i < marchandisesItems.length; i++) {
      if (!(marchandisesItems[i].quantite > 0)) {
        return false
      }
      if (
        marchandisesItems[i].longueur &&
        !(marchandisesItems[i].longueur !== '')
      ) {
        return false
      }
      if (
        marchandisesItems[i].largeur &&
        !(marchandisesItems[i].largeur !== '')
      ) {
        return false
      }
      if (
        marchandisesItems[i].hauteur &&
        !(marchandisesItems[i].hauteur !== '')
      ) {
        return false
      }
      if (!(marchandisesItems[i].poids > 0)) {
        return false
      }
    }
    return true
  }

  const nextStep = () => {
    if (marchandisesItems.length > 0) {
      if (verifyMarchandiseContent()) {
        setFunnelState({
          ...funnelState,
          euSwitch,
          euSwitchQte,
          marchandisesItems: marchandisesItems.map((marchandisesItem) => ({
            ...marchandisesItem,
            type: marchandisesType.id,
          })),
        })
        setFunnelSteps(step, item)
        setActiveStep(item.nextStep)
      } else {
        toast.error(
          'Veuillez remplir correctement les informations des marchandises'
        )
      }
    } else if (marchandisesItems.length === 0) {
      toast.error('Veuillez ajouter au moins une marchandise')
    } else if (!formIsOk) {
      toast.error('Veuillez remplir tous les champs')
    }
  }
  const previousStep = () => {
    setFunnelState({
      ...funnelState,
      euSwitch,
      euSwitchQte,
      marchandisesItems: marchandisesItems.map((marchandisesItem) => ({
        ...marchandisesItem,
        type: marchandisesType.id,
      })),
    })
    setFunnelSteps(step, item)
    setActiveStep(item.previousStep)
  }

  const deleteMarchandise = (id) => {
    let baseId = 0
    const newMarchandisesItems = marchandisesItems.filter((marchandise) => {
      if (marchandise.id !== id) {
        baseId += 1
        return { ...marchandise, id: baseId }
      }
      return null
    })

    setMarchandisesItems(newMarchandisesItems)
    setFunnelState((prevState) => ({
      ...prevState,
      marchandisesItems: newMarchandisesItems,
    }))
  }
  const addNewMarchandise = () => {
    let newId = 0
    if (marchandisesItems.length === 0) {
      newId = 1
    } else {
      newId = marchandisesItems.length + 1
    }

    const newMarchandise = {
      id: newId,
    }
    setMarchandisesItems([...marchandisesItems, newMarchandise])
  }

  useEffect(() => {
    if (marchandisesItems.length === 0) {
      addNewMarchandise()
    }
  }, [])

  return (
    <div className="card card-funnel">
      <CardContent>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} align="center">
            {isLoading ? (
              <CustomizedProgress />
            ) : (
              <ToggleButtonGroup
                className="toggle-button-group"
                exclusive
                onChange={handleChangeType}
                sx={{
                  alignItems: 'center',
                  justifycontent: 'space-between',
                  gap: 3,
                }}
              >
                {types.map((type) => (
                  <ToggleButton
                    className={`toggle-btn-2 ${
                      marchandisesType?.id === type.id ? ' select ' : ''
                    }`}
                    key={type.id}
                    active={type}
                    value={type}
                  >
                    {type.nom}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-arround',
                borderRadius: '50px',
                boxShadow: '0px 3px 6px #00000029',
                backgroundColor: '#fffffff',
                padding: '0.75rem 1.25rem',
                width: '100%',
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                style={{ sm: { marginBottom: '1.5rem' } }}
              >
                <FormControlLabel
                  control={
                    <HuubSwitch
                      defaultChecked={euSwitch}
                      onChange={(event) => {
                        handleSwitchEu(event.target.value)
                      }}
                      name="type"
                    />
                  }
                  label="Palettes Europe à échanger"
                  labelPlacement="end"
                />
              </Grid>
              {euSwitch && (
                <Grid item xs={12} sm={12} md={2} align="center">
                  <TextField
                    id="inputQuantities"
                    label="Nbr"
                    type="number"
                    defaultValue={euSwitchQte || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(event) => {
                      setEuSwitchQte(event.target.value)
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {marchandisesItems.map(
          (marchandise) =>
            marchandisesItems.length > 0 && (
              <Grid
                container
                key={marchandise.id}
                justifyContent="space-between"
                display="flex"
                spacing={2}
              >
                <Box
                  style={{
                    width: '100%',
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    borderBottom: '1px solid #00000029',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h6>marchandise {marchandise.id}</h6>
                  <Box>
                    <FormControlLabel
                      id={marchandise.id}
                      control={
                        <HuubSwitch
                          checked={!!marchandise.gerbable}
                          onChange={(event) => {
                            handleChange({
                              id: marchandise.id,
                              gerbable: event.target.checked,
                            })
                          }}
                          name="type"
                        />
                      }
                      label="Palettes gerbables"
                      labelPlacement="end"
                    />
                    {marchandisesItems.length > 1 && (
                      <Button
                        style={{
                          padding: 0,
                          minWidth: 0,
                        }}
                        onClick={() => deleteMarchandise(marchandise.id)}
                      >
                        <Close />
                      </Button>
                    )}
                  </Box>
                </Box>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    sx={{ width: '100%' }}
                    id="InputQuantity"
                    label="Quantité"
                    type="number"
                    defaultValue={marchandise.quantite || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(event) =>
                      handleChange({
                        id: marchandise.id,
                        quantite: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    sx={{ width: '100%' }}
                    id="inputLength"
                    label="Longueur (cm)"
                    type="number"
                    defaultValue={marchandise.longueur || null}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(event) =>
                      handleChange({
                        id: marchandise.id,
                        longueur: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    sx={{ width: '100%' }}
                    id="inputWidth"
                    label="Largeur (cm)"
                    type="number"
                    defaultValue={marchandise.largeur || null}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(event) =>
                      handleChange({
                        id: marchandise.id,
                        largeur: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    sx={{ width: '100%' }}
                    id="inputHeight"
                    label="Hauteur (cm)"
                    type="number"
                    defaultValue={marchandise.hauteur || null}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(event) =>
                      handleChange({
                        id: marchandise.id,
                        hauteur: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    sx={{ width: '100%' }}
                    id="inputWeight"
                    label="Poids (kg)"
                    type="number"
                    defaultValue={marchandise.poids || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">kg</InputAdornment>
                      ),
                    }}
                    onChange={(event) =>
                      handleChange({
                        id: marchandise.id,
                        poids: event.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            )
        )}
        <Box
          height="max-content"
          width="100%"
          style={{
            marginTop: '1.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                height: '30px',
                width: '30px',
                minWidth: '30px',
                backgroundColor: '#0b0421',
                borderRadius: '100%',
                color: 'white',
                marginRight: '1rem',
              }}
              onClick={() => addNewMarchandise()}
            >
              <Add />
            </Button>
          </Box>
        </Box>
      </CardContent>
      <Tooltip title="Précédent" placement="right">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            left: '2rem',
          }}
          onClick={previousStep}
        >
          <NavigateBeforeIcon />
        </button>
      </Tooltip>
      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
