/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { Grid, CardContent, CardActions, InputLabel } from '@material-ui/core'

import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import CustomizedProgress from '../customElements/CircularProgress'
import { createItem } from '../../services/Api/request'
import MotionHoc from '../MotionHoc'

function NotificationFormComponent({ user }) {
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const [emailPreference, setEmailPreference] = useState(
    user?.emailNotification && user?.user?.email !== user?.emailNotification
      ? 'other'
      : user?.user?.email
  )

  const { isLoading: isUpdating, mutate } = useMutation(
    async (data) => {
      if (data.emailNotification && emailPreference === user?.email) {
        data.emailNotification = ''
      }
      const formData = new FormData()
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key].toString())
      })
      await createItem('me', { body: formData })
    },
    {
      onSuccess: () => queryClient.refetchQueries(['me']),
    }
  )

  function changePreference(value) {
    setEmailPreference(value)
  }

  return isUpdating ? (
    <CustomizedProgress />
  ) : (
    <div className="card">
      <form onSubmit={handleSubmit(mutate)}>
        <CardContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                htmlFor="inputFacture"
                style={{
                  fontFamily: 'inherit',
                  fontSize: '0.8rem',
                  paddingBottom: '1rem',
                }}
              >
                Je souhaite recevoir mes factures.
              </InputLabel>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="notificationIsDelivery"
                    checked={emailPreference === user?.user?.email}
                    onChange={() => changePreference(user?.user?.email)}
                  />
                  sur l'adresse e-mail du compte
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="notificationInDelivery"
                    checked={emailPreference === 'other'}
                    onChange={() => changePreference('other')}
                  />
                  sur une autre adresse e-mail
                </label>
              </div>
              {emailPreference === 'other' && (
                <div className="input">
                  <input
                    className="form-control"
                    type="email"
                    name="emailNotification"
                    defaultValue={user?.emailNotification}
                    {...register('emailNotification')}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                htmlFor="inputNotification"
                style={{
                  fontFamily: 'inherit',
                  fontSize: '0.8rem',
                  paddingBottom: '1rem',
                }}
              >
                Je souhaite recevoir des e-mails de notifications.
              </InputLabel>

              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="notificationDeliveryRequest"
                    defaultChecked={user?.notificationDeliveryRequest}
                    {...register('notificationDeliveryRequest')}
                  />
                  lorsque mon colis est en traitement
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="notificationInDelivery"
                    defaultChecked={user?.notificationInDelivery}
                    {...register('notificationInDelivery')}
                  />
                  lorsque mon colis est en transit
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="notificationIsDelivery"
                    defaultChecked={user?.notificationIsDelivery}
                    {...register('notificationIsDelivery')}
                  />
                  lorsque mon colis est livré
                </label>
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <button type="submit" className="btn">
            <em>Enregistrer les modifications</em>
          </button>
        </CardActions>
      </form>
    </div>
  )
}

const NotificationForm = MotionHoc(NotificationFormComponent)
export default NotificationForm
