import React from 'react'
import { Grid, CardContent, CardActions } from '@material-ui/core'
import { useSelector } from 'react-redux'
import MotionHoc from '../MotionHoc'
import { authSelector } from '../../stores/selectors/authSelectors'

function SecuriteFormComponent({ user, openEmailModal, openPasswordModal }) {
  const auth = useSelector(authSelector)
  return (
    <div className="card">
      <Grid container justifyContent="center" spacing={2}>
        <form>
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={6}>
                <label htmlFor="inputEmail">Adresse e-mail</label>
                <div className="input disabled">
                  <input
                    id="inputEmail"
                    type="email"
                    name="email"
                    placeholder="Adresse e-mail"
                    className="form-control"
                    disabled
                    defaultValue={
                      auth.isAdmin ? user.email : user?.client?.email
                    }
                  />
                </div>
                <button type="button" className="link" onClick={openEmailModal}>
                  Modifier l'adresse e-mail.
                </button>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <label htmlFor="inputPassword">Mot de passe</label>
                <div className="input disabled">
                  <input
                    id="inputPassword"
                    type="password"
                    name="password"
                    placeholder="Mot de passe"
                    className="form-control"
                    disabled
                  />
                </div>
                <button
                  type="button"
                  className="link"
                  onClick={openPasswordModal}
                >
                  Réinitialiser le mot de passe
                </button>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <button type="button" className="btn">
              <em>Supprimer mon compte utilisateur</em>
            </button>
          </CardActions>
        </form>
      </Grid>
    </div>
  )
}

const SecuriteForm = MotionHoc(SecuriteFormComponent)
export default SecuriteForm
