import React, { useState } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useQueries } from 'react-query'
import { ToggleButton, Tooltip } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toast } from 'react-toastify'
import CustomizedProgress from '../../../customElements/CircularProgress'
import { getMany } from '../../../../services/Api/request'
import {
  StyledCardContent,
  StyledToggleButtonGroup,
} from '../02-expedition/ExpeditionInstructionsStep'

export default function LivraisonInstructionsStep({
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
  step,
}) {
  const [accesContrainteSelect, setAccesContrainteSelect] = useState(
    funnelState.destinationContrainteAcces || null
  )
  const [chargementContrainteSelect, setChargementContrainteSelect] = useState(
    funnelState.destinationContrainteChargement || null
  )
  const [
    { data: accesContraintes, isLoading: isLoadingAcces },
    { data: chargementContraintes, isLoading: isLoadingChargement },
  ] = useQueries([
    {
      queryKey: 'acces_contraintes',
      queryFn: async () => {
        const data = await getMany('acces_contraintes')
        return data
      },
    },
    {
      queryKey: 'chargement_contraintes',
      queryFn: async () => {
        const data = await getMany('chargement_contraintes')
        return data
      },
    },
  ])

  const handleChangeChargementType = (event, value) => {
    setChargementContrainteSelect(value)
  }
  const handleChangeAccesType = (event, value) => {
    setAccesContrainteSelect(value)
  }

  const nextStep = () => {
    if (accesContrainteSelect && chargementContrainteSelect) {
      setFunnelState({
        ...funnelState,
        destinationContrainteAcces: accesContrainteSelect,
        destinationContrainteChargement: chargementContrainteSelect,
      })
      setFunnelSteps(step, item)
      setActiveStep(item.nextStep)
    } else {
      toast.error('Veuillez sélectionner des instructions de livraison')
    }
  }
  const previousStep = () => {
    setFunnelState({
      ...funnelState,
      destinationContrainteAcces: accesContrainteSelect,
      destinationContrainteChargement: chargementContrainteSelect,
    })
    setFunnelSteps(step, item)
    setActiveStep(item.previousStep)
  }

  return (
    <div className="card card-funnel">
      <StyledCardContent>
        <Box sx={{ flexGrow: 1, margin: 'auto' }} width="100%">
          <Box sx={{ pb: 6 }}>
            <Grid container direction="column">
              <Box sx={{ pb: 2 }}>
                <p>Contraintes d'accès :</p>
              </Box>
              {isLoadingAcces ? (
                <CustomizedProgress />
              ) : (
                <StyledToggleButtonGroup
                  className="toggle-button-group"
                  value={accesContrainteSelect}
                  exclusive
                  onChange={handleChangeAccesType}
                >
                  {accesContraintes.map((acces) => (
                    <ToggleButton
                      className={`toggle-btn ${
                        accesContrainteSelect?.id === acces.id ? ' select ' : ''
                      }`}
                      key={acces.id}
                      active={acces}
                      value={acces}
                      style={{ position: 'relative' }}
                    >
                      <img
                        src={`/icons/funnel/cotation/${acces.icon}`}
                        alt="svg"
                        className="svgIcon"
                      />
                      <Box
                        className="title-toggle-btn"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          top: 'calc(100% + 10px)',
                        }}
                      >
                        {acces.nom}
                      </Box>
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              )}
            </Grid>
          </Box>
          <Box sx={{ py: 1 }}>
            <Grid container direction="column">
              <Box sx={{ pb: 2 }}>
                <p>Contraintes de chargement :</p>
              </Box>
              {isLoadingChargement ? (
                <CustomizedProgress />
              ) : (
                <StyledToggleButtonGroup
                  className="toggle-button-group"
                  value={chargementContrainteSelect}
                  exclusive
                  onChange={handleChangeChargementType}
                >
                  {chargementContraintes.map((chargement) => (
                    <ToggleButton
                      className={`toggle-btn ${
                        chargementContrainteSelect?.id === chargement.id
                          ? ' select '
                          : ''
                      }`}
                      key={chargement.id}
                      active={chargement}
                      value={chargement}
                      style={{ position: 'relative' }}
                    >
                      <img
                        src={`/icons/funnel/cotation/${chargement.icon}`}
                        alt="svg"
                        className="svgIcon"
                      />
                      <Box
                        className="title-toggle-btn"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          top: 'calc(100% + 10px)',
                        }}
                      >
                        {chargement.nom}
                      </Box>
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              )}
            </Grid>
          </Box>
        </Box>
      </StyledCardContent>

      <Tooltip title="Précédent" placement="right">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            left: '2rem',
          }}
          onClick={previousStep}
        >
          <NavigateBeforeIcon />
        </button>
      </Tooltip>
      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
