/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, CardContent, Grid } from '@material-ui/core'
import { useAuth } from '../services/security'
import ModalResetPassword from '../components/modals/ModalResetPassword'

function Login() {
  const { register, handleSubmit } = useForm()
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()

  const from = location.state?.from?.pathname || '/'

  const onSubmit = (data) => {
    auth.signin(data, () => {
      navigate(from, { replace: true })
    })
  }

  const [openModal, setOpenModal] = useState(false)
  const [view, setView] = useState(null)
  const openModalPass = (index) => {
    setOpenModal(true)
    setView(index)
  }
  const closeModalPass = () => {
    setOpenModal(false)
    setView(false)
  }

  return (
    <>
      <div className="bkg-section">
        <div className="bkg-effect" />
      </div>
      <div className="section-login">
        <div className="card">
          <CardContent>
            <div className="brand">
              <div className="brand-svg" />
              <div className="brand-logo">
                <div className="box-logo" />
              </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card-form">
                <Box sx={{ py: 1 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <label htmlFor="inputEmail">Adresse e-mail</label>
                      <div className="input">
                        <input
                          type="email"
                          name="username"
                          placeholder="Email"
                          id="inputEmail"
                          className="form-control"
                          {...register('username', { required: true })}
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <label htmlFor="inputPassword">Mot de passe</label>
                      <div className="input">
                        <input
                          type="password"
                          name="password"
                          placeholder="Mot de passe"
                          id="inputPassword"
                          className="form-control"
                          {...register('password', { required: true })}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <button type="button" className="link" onClick={openModalPass}>
                  J'ai oublié mon mot de passe
                </button>
              </div>
              <button className="btn" type="submit">
                <em>Connexion</em>
              </button>
            </form>
          </CardContent>

          <ModalResetPassword
            open={openModal}
            onClose={closeModalPass}
            view={view}
          />
        </div>
      </div>
    </>
  )
}

export default Login
