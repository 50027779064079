import {
  TypeFretStep,
  ExpediteurStep,
  ExpeditionDateStep,
  ExpeditionInstructionsStep,
  DestinataireStep,
  LivraisonInstructionsStep,
  AssuranceStep,
  MarchandisesCotationStep,
  TaxeStep,
  RecapitulatifCotationStep,
} from '../../components/funnels'

export const initialStateCotation = [
  {
    id: 0,
    icon: 'fret',
    title: 'Type de fret',
    titleSidebar: 'Type de fret',
    completed: false,
    activeStep: { step: 0, children: 0 },
    childrens: [
      {
        id: 0,
        title: '',
        titleSidebar: 'Type de fret',
        component: TypeFretStep,
        values: {},
        completed: false,
        previousStep: null,
        activeStep: { step: 0, children: 0 },
        nextStep: { step: 1, children: 0 },
      },
    ],
  },
  {
    id: 1,
    icon: 'expedition',
    title: 'Expédition',
    titleSidebar: 'Expédition',
    completed: false,
    activeStep: { step: 1, children: 0 },
    childrens: [
      {
        id: 0,
        title: ' | Expéditeur',
        titleSidebar: 'Expéditeur',
        component: ExpediteurStep,
        values: {},
        completed: false,
        previousStep: { step: 0, children: 0 },
        activeStep: { step: 1, children: 0 },
        nextStep: { step: 1, children: 1 },
      },
      {
        id: 1,
        title: ' | Date de prise en charge',
        titleSidebar: 'Date de prise en charge',
        component: ExpeditionDateStep,
        values: {},
        completed: false,
        previousStep: { step: 1, children: 0 },
        activeStep: { step: 1, children: 1 },
        nextStep: { step: 1, children: 2 },
      },
      {
        id: 2,
        title: ' | Instructions',
        titleSidebar: 'Instructions',
        component: ExpeditionInstructionsStep,
        values: {},
        completed: false,
        previousStep: { step: 1, children: 1 },
        activeStep: { step: 1, children: 2 },
        nextStep: { step: 2, children: 0 },
      },
    ],
  },
  {
    id: 2,
    icon: 'livraison',
    title: 'Livraison',
    titleSidebar: 'Livraison',
    completed: false,
    activeStep: { step: 2, children: 0 },
    childrens: [
      {
        id: 0,
        title: ' | Destinataire',
        titleSidebar: 'Destinataire',
        component: DestinataireStep,
        values: {},
        completed: false,
        previousStep: { step: 1, children: 2 },
        activeStep: { step: 2, children: 0 },
        nextStep: { step: 2, children: 1 },
      },
      {
        id: 1,
        title: ' | Instructions',
        titleSidebar: 'Instructions',
        component: LivraisonInstructionsStep,
        values: {},
        completed: false,
        previousStep: { step: 2, children: 0 },
        activeStep: { step: 2, children: 1 },
        nextStep: { step: 3, children: 0 },
      },
    ],
  },
  {
    id: 3,
    icon: 'marchandise',
    title: '',
    titleSidebar: 'Marchandises',
    completed: false,
    activeStep: { step: 3, children: 0 },
    childrens: [
      {
        id: 0,
        title: '',
        titleSidebar: 'Marchandises',
        component: MarchandisesCotationStep,
        values: {},
        completed: false,
        previousStep: { step: 2, children: 1 },
        activeStep: { step: 3, children: 0 },
        nextStep: { step: 3, children: 1 },
      },
      {
        id: 1,
        title: ' | Assurance',
        titleSidebar: 'Assurance',
        component: AssuranceStep,
        values: {},
        completed: false,
        previousStep: { step: 3, children: 0 },
        activeStep: { step: 3, children: 1 },
        nextStep: { step: 4, children: 0 },
      },
    ],
  },
  {
    id: 4,
    icon: 'taxe',
    title: 'Droits et taxes',
    titleSidebar: 'Droits et taxes',
    completed: false,
    activeStep: { step: 4, children: 0 },
    childrens: [
      {
        id: 0,
        title: '',
        titleSidebar: 'Droits et taxes',
        component: TaxeStep,
        values: {},
        completed: false,
        previousStep: { step: 3, children: 1 },
        activeStep: { step: 4, children: 0 },
        nextStep: { step: 5, children: 0 },
      },
    ],
  },
  {
    id: 5,
    icon: 'recap',
    title: 'Récapitulatif',
    titleSidebar: 'Récapitulatif',
    completed: false,
    activeStep: { step: 5, children: 0 },
    childrens: [
      {
        id: 0,
        title: ' de votre demande',
        titleSidebar: 'Récapitulatif',
        component: RecapitulatifCotationStep,
        values: {},
        completed: false,
        previousStep: { step: 4, children: 0 },
        activeStep: { step: 5, children: 0 },
        nextStep: null,
      },
    ],
  },
]
