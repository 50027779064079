/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import ModalChoiceCollab from '../components/modals/ModalChoiceCollab'
import ModalCreateContact from '../components/modals/ModalCreateContact'
import { authSelector, userSelector } from '../stores/selectors/authSelectors'

export default function Demandes() {
  const auth = useSelector(authSelector)
  const user = useSelector(userSelector)
  const { collaborateurId } = useParams()
  const location = useLocation()
  const [collaborateur, setCollaborateur] = useState(
    collaborateurId || location?.state?.collaborateurId || null
  )
  const [open, setOpen] = useState(
    !(collaborateurId || location?.state?.collaborateurId)
  )
  const [openCreateContact, setOpenCreateContact] = useState(false)

  const modalProps = {
    open,
    setOpen,
    setCollaborateur,
    setOpenCreateContact,
  }
  const changeBackground = (e) => {
    e.target.style.background = '$black'
  }

  useEffect(() => {
    if (auth.isAdmin === false && user) {
      setCollaborateur(user.id)
      setOpen(false)
    }
  }, [user])

  return (
    <>
      {!collaborateurId && auth.isAdmin && (
        <>
          <ModalChoiceCollab {...modalProps} />
          <ModalCreateContact
            open={openCreateContact}
            handleClose={() => setOpenCreateContact(false)}
            callback={(data) => {
              setCollaborateur(data.id)
            }}
          />
        </>
      )}
      <div className="choices-container">
        {!collaborateur && auth.isAdmin && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              background: '#808080ad',
              zIndex: 9,
            }}
          >
            {!open && (
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="btn"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10,
                }}
              >
                <em>Chercher un client</em>
              </button>
            )}
          </div>
        )}
        <Link
          to="/messagerie"
          state={{ collaborateur }}
          className="funnel-card btn-card"
          id="messagerie"
          onMouseOut={changeBackground}
        >
          <h3>Messagerie</h3>
          <img src="/icons/illusMessagerie.png" alt="messagerie" />
          <p>Envoyer vos marchandises</p>
        </Link>
        <Link
          to="/cotation"
          state={{ collaborateur }}
          className="funnel-card btn-card"
          id="cotation"
        >
          <h3>Cotation</h3>
          <img src="/icons/illusCotation.png" alt="cotation" />
          <p>Faites une demande de cotation</p>
        </Link>
      </div>
    </>
  )
}
