/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useQuery } from 'react-query'
import { ToggleButton, Tooltip } from '@mui/material'
import { toast } from 'react-toastify'
import CustomizedProgress from '../../../customElements/CircularProgress'
import { getMany } from '../../../../services/Api/request'
import {
  StyledCardContent,
  StyledToggleButtonGroup,
} from '../02-expedition/ExpeditionInstructionsStep'

// eslint-disable-next-line no-unused-vars
export default function TypeFretStep({
  step,
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
}) {
  const [typeFret, setTypeFret] = useState(funnelState?.typeFret || null)
  const { data: types, isLoading } = useQuery('fret_types', async () => {
    const data = await getMany('fret_types')
    return data
  })

  const handleChangeType = (_event, value) => {
    setTypeFret(value)
  }

  const nextStep = () => {
    if (typeFret) {
      setFunnelState({ ...funnelState, typeFret })
      setFunnelSteps(step, item)
      setActiveStep(item.nextStep)
    } else {
      toast.error('Veuillez sélectionner un type de fret')
    }
  }

  return (
    <div className="card card-funnel">
      <StyledCardContent>
        <Box sx={{ flexGrow: 1, margin: 'auto' }} width="100%">
          <Grid container direction="column">
            <Box sx={{ pb: 2 }}>
              <p>Mode de fret souhaité :</p>
            </Box>
            {isLoading ? (
              <CustomizedProgress />
            ) : (
              <StyledToggleButtonGroup
                className="toggle-button-group"
                value={typeFret}
                exclusive
                onChange={handleChangeType}
                sx={{
                  alignItems: 'center',
                  justifycontent: 'space-between',
                  gap: 3,
                }}
              >
                {types.map((type) => (
                  <ToggleButton
                    className={`toggle-btn ${
                      typeFret?.id === type.id ? ' select ' : ''
                    }`}
                    key={type.id}
                    active={type}
                    value={type}
                    style={{ position: 'relative' }}
                  >
                    <img
                      src={`/icons/funnel/cotation/${type.icon}`}
                      alt="svg"
                      className="svgIcon"
                    />
                    <Box
                      className="title-toggle-btn"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        bottom: '-38px',
                      }}
                    >
                      {type.nom}
                    </Box>
                  </ToggleButton>
                ))}
              </StyledToggleButtonGroup>
            )}
          </Grid>
        </Box>
      </StyledCardContent>

      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
