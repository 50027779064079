import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <>
      <div className="bkg-section">
        <div className="bkg-effect" />
      </div>
      <div className="section-404">
        <h2>Page</h2>
        <h1>404</h1>
        <button type="button" className="btn">
          <Link to="/">
            <em>Retour à l'accueil</em>
          </Link>
        </button>
      </div>
    </>
  )
}
