import {
  faAddressCard,
  faAt,
  faHouseUser,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import React from 'react'

function DestinataireBloc({ data }) {
  return (
    <>
      <h5>Destinataire</h5>
      <div className="card">
        <h5 className="fullname">
          <FontAwesomeIcon
            icon={faAddressCard}
            style={{ marginRight: '1rem' }}
          />
          {`${data?.destinataire.civilite} ${data?.destinataire.prenom} ${data?.destinataire.nom}`}
        </h5>
        <p>
          <FontAwesomeIcon icon={faHouseUser} style={{ marginRight: '1rem' }} />
          {`${data?.destinataire.adresse},`} <br />
          {`${data?.destinataire.codePostal} ${data?.destinataire.ville}`}
        </p>
        <Box
          style={{
            marginTop: '0.8rem',
            marginBottom: '0.8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>
            <FontAwesomeIcon icon={faPhone} style={{ marginRight: '1rem' }} />
            {data?.destinataire.telephone}
          </p>
          <p>
            <FontAwesomeIcon icon={faAt} style={{ marginRight: '1rem' }} />
            {data?.destinataire.email}
          </p>
        </Box>
      </div>
    </>
  )
}

export default DestinataireBloc
