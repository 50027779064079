/* eslint-disable no-constant-condition */
import React, { useState } from 'react'
import { Box, CardContent, Grid, InputAdornment } from '@material-ui/core'
import { TextField, Tooltip } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function AssuranceStep({
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
  step,
}) {
  const [marchandisesAssurance, setMarchandisesAssurance] = useState(
    funnelState.assurance || false
  )
  const [marchandisesValue, setMarchandisesValue] = useState(
    funnelState.marchandisesValue || null
  )

  const nextStep = () => {
    if (marchandisesAssurance && !marchandisesValue) {
      toast.error('Veuillez ajouter au moins une marchandise')
    } else {
      setFunnelState({
        ...funnelState,
        assurance: marchandisesAssurance,
        marchandisesValue,
      })
      setFunnelSteps(step, item)
      setActiveStep(item.nextStep)
    }
  }
  const previousStep = () => {
    setFunnelState({
      ...funnelState,
      assurance: marchandisesAssurance,
      marchandisesValue,
    })
    setFunnelSteps(step, item)
    setActiveStep(item.previousStep)
  }

  return (
    <div className="card card-funnel">
      <CardContent>
        <Box sx={{ flexGrow: 1, margin: 'auto' }} width="100%">
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item align="center" xs={12} sm={12} md={12}>
              <div className="info">
                <p>
                  Pensez à assurer vos marchandises pour un transport en toute
                  sécurité.
                </p>
              </div>
            </Grid>
            <Grid item align="center" xs={12} sm={12} md={4}>
              <button
                type="button"
                className={`btn ${marchandisesAssurance ? 'active' : ''}`}
                style={{ width: '100%' }}
                onClick={() => setMarchandisesAssurance(true)}
              >
                <em>J'assure la marchandise</em>
              </button>
            </Grid>
            <Grid item align="center" xs={12} sm={12} md={4}>
              <button
                type="button"
                className={`btn ${!marchandisesAssurance ? 'active' : ''}`}
                style={{ width: '100%' }}
                onClick={() => setMarchandisesAssurance(false)}
              >
                <em>Je n'assure pas la marchandise</em>
              </button>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
              {marchandisesAssurance ? (
                <>
                  <Grid item align="center" xs={12} sm={12} md={12}>
                    <TextField
                      id="InputPrice"
                      label="Valeur de votre marchandise"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      onChange={(event) =>
                        setMarchandisesValue(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item align="center" xs={12} sm={12} md={8}>
                    <p className="small">
                      En sélectionnant "J'assure ma marchandise" vous prenez
                      connaissance des conditions générales. En validant votre
                      commande avant son paiement, vous déclarez les accepter
                      dans leur intégralités.
                    </p>
                  </Grid>
                </>
              ) : (
                <Grid item align="center" xs={12} sm={12} md={12}>
                  <div className="info" style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    <p style={{ marginBottom: '1rem' }}>
                      Vous avez choisi de ne pas assurer votre marchandise !
                      <br />
                      En cas de perte ou dommage seule une indemnité
                      contractuelle transporteur pourra vous être versée.
                    </p>
                    <p className="small">
                      Si vous ne vous souscrivez pas d’assurance pour cet envoi,
                      le contrat type CMR s’applique. Si vous souhaitez une
                      assurance complémentaire Ad Valorem, merci de cliquer sur
                      j’assure la marchandise. Le montant de l’assurance s’élève
                      à 1% AD Valorem avec un minimum de 15€
                    </p>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </CardContent>

      <Tooltip title="Précédent" placement="right">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            left: '2rem',
          }}
          onClick={previousStep}
        >
          <NavigateBeforeIcon />
        </button>
      </Tooltip>
      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
