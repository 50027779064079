/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Grid, Toolbar } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faHouseUser, faPhone } from '@fortawesome/free-solid-svg-icons'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useQueries, useQuery } from 'react-query'
import CustomizedProgress from '../components/customElements/CircularProgress'
import { getMany, getOne } from '../services/Api/request'
import {
  ClassicSearchToolbar,
  ContactsTable,
  OrderSearchToolbar,
  OrdersTable,
} from '../components/tables'
import {
  columnsCarnetAdminClient,
  hiddenColumnsCarnet,
} from '../utils/columns/columnsCarnet'
import {
  columnsHistoriqueCollab,
  hiddenColumnsHistorique,
} from '../utils/columns/columnsHistorique'
import BadgeAvatars from '../components/customElements/BadgeAvatar'
import ModalCreateContactCollab from '../components/modals/ModalCreateContactCollab'
import { addType } from '../utils/function'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    minHeight: '48px',
    marginBottom: '24px',
  },
  title: {
    fontSize: '0.8rem',
    marginBottom: '0',
  },
}))

export default function DetailsContact() {
  const [open, setOpen] = useState(false)
  const classes = useToolbarStyles()
  const { id: collaborateurId } = useParams()
  const handleOpen = () => setOpen(true)
  const [viewHistorique, setViewHistorique] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/contacts'

  const switchView = (bool) => {
    setViewHistorique(bool)
  }

  function textBackBtn() {
    if (from) {
      if (from.includes('contacts/:id')) {
        return 'Contacts/id'
      }
    }
    return 'Carnet de Clientèle'
  }

  const handleClose = () => {
    setOpen(false)
  }
  const { data: collab, isLoading } = useQuery(
    ['collaborateurs', collaborateurId],
    async () => {
      const response = await getOne('collaborateurs', { id: collaborateurId })
      return response
    }
  )

  return isLoading ? (
    <CustomizedProgress />
  ) : (
    <>
      <Grid
        container
        justifyContent="space-between"
        spacing={1}
        style={{ marginBottom: '.5rem' }}
      >
        <Grid item xs={6} sm={12} md={3}>
          <button
            type="button"
            className="breadcrumps"
            style={{
              background: 'none',
              border: 'none',
            }}
            onClick={() => navigate(from, { replace: true })}
          >
            <ArrowBackIcon />
            <h5>{textBackBtn()}</h5>
          </button>
        </Grid>
      </Grid>
      <Toolbar className={classes.root} open>
        <Grid container>
          <Box className="toolbarContact">
            <div className="flex-img-fullname">
              <BadgeAvatars avatarFile={collab.client.avatarFile} />
              <p className={classes.title}>
                {`${collab.client.civilite} ${collab.client.nom} ${collab.client.prenom}`}
              </p>
            </div>
            <h5 className="small">{collab.client.number}</h5>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className={classes.title}>
                <FontAwesomeIcon
                  icon={faHouseUser}
                  style={{ marginRight: '1rem' }}
                />
                {`${collab.client.adresse}, ${collab.client.codePostal} ${collab.client.ville}`}
              </p>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className={classes.title}>
                <FontAwesomeIcon icon={faAt} style={{ marginRight: '1rem' }} />
                {collab.client.email}
              </p>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className={classes.title}>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ marginRight: '1rem' }}
                />
                {collab.client.telephone}
              </p>
            </Box>
          </Box>
          <Box className="toolbarBtn">
            <button
              type="button"
              className="btn"
              style={{ width: '100%' }}
              onClick={() => switchView(true)}
            >
              <em>HISTORIQUE DE COMMANDE DE CLIENT</em>
            </button>
            <button
              type="button"
              className="btn"
              style={{ width: '100%' }}
              onClick={() => switchView(false)}
            >
              <em>CARNET D'ADRESSE CLIENT</em>
            </button>
          </Box>
        </Grid>
      </Toolbar>

      {viewHistorique ? (
        <RenderTableHistoriqueCollab />
      ) : (
        <>
          <ContactsTable
            columns={columnsCarnetAdminClient}
            data={collab.clients}
            hiddenColumns={hiddenColumnsCarnet}
            toolbar={ClassicSearchToolbar}
            toolbarProps={{ callback: handleOpen }}
          />
          <ModalCreateContactCollab
            open={open}
            handleClose={handleClose}
            collaborateurId={collaborateurId}
          />
        </>
      )}
    </>
  )
}

function RenderTableHistoriqueCollab() {
  const { id: collabId } = useParams()

  const [
    { data: cotations, isLoading: isLoadingCotation },
    { data: messageries, isLoading: isLoadingMessagerie },
  ] = useQueries([
    {
      queryKey: 'cotations',
      queryFn: async () => {
        const data = await getMany('cotations', {
          filters: `?collaborateurId=${collabId}`,
        })
        addType(data, 'cotations')
        return data
      },
    },
    {
      queryKey: 'messageries',
      queryFn: async () => {
        const data = await getMany('messageries', {
          filters: `?collaborateurId=${collabId}`,
        })
        addType(data, 'messageries')
        return data
      },
    },
  ])

  return !isLoadingCotation && !isLoadingMessagerie ? (
    <OrdersTable
      columns={columnsHistoriqueCollab}
      data={[...cotations, ...messageries]}
      hiddenColumns={hiddenColumnsHistorique}
      toolbar={OrderSearchToolbar}
    />
  ) : (
    <CustomizedProgress />
  )
}
