/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { Grid, CardContent, Box } from '@material-ui/core'
import { Tooltip, Checkbox } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { createItem } from '../../../../services/Api/request'
import BadgeAvatars from '../../../customElements/BadgeAvatar'

export default function DestinataireStep({
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
  step,
  clients,
}) {
  const nameRef = useRef(null)
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const [userSelected, setUserSelected] = useState(null)

  const [checkedPro, setCheckedPro] = useState(
    userSelected?.type === 'professionnel' || true
  )
  const [checkedIndividual, setCheckedIndividual] = useState(
    userSelected?.type === 'particulier' || false
  )

  // SETDATA FUNNEL + PREV / NEXT STEP
  const nextStep = () => {
    if (userSelected) {
      setFunnelState({ ...funnelState, destinataire: userSelected })
      setFunnelSteps(step, item)
      setActiveStep(item.nextStep)
    } else {
      toast.error('Veuillez choisir un destinataire')
    }
  }

  const previousStep = () => {
    if (userSelected) {
      setFunnelState({ ...funnelState, destinataire: userSelected })
    }
    setFunnelSteps(step, item)
    setActiveStep(item.previousStep)
  }

  // SEARCH FORM DATA CLIENT
  const [filteredClients, setFilteredClients] = useState([])
  const [strSearch, setStrSearch] = useState('')

  // UseEffect pour la recherche du filtrage client
  useEffect(() => {
    if (strSearch.length > 2) {
      setFilteredClients(
        clients.filter(
          (client) =>
            client.fullname.toLowerCase().includes(strSearch.toLowerCase()) &&
            (funnelState.expediteur
              ? client.id !== funnelState.expediteur.id
              : true)
        )
      )
    } else {
      setUserSelected(null)
      setFilteredClients([])
    }
  }, [strSearch])

  // CHOIX PRO / PARTICULIER

  // Checkbox type pro / particulier
  const checkHandler = () => {
    setCheckedPro(!checkedPro)
    setCheckedIndividual(!checkedIndividual)
  }

  // Enregistrement du type
  function handleChoice(client) {
    if (nameRef.current) {
      nameRef.current.value = client.nom
      setStrSearch(client.nom)
    }
    setUserSelected(client)
    setCheckedPro(client.type === 'professionnel')
    setCheckedIndividual(client.type === 'particulier')
  }

  // MUTATION DATA + CREATION DESTINATAIRE
  const { mutate } = useMutation(
    async (data) => {
      data.collaborateur = funnelState.collaborateur
      data.nom = strSearch
      data.type = checkedPro ? 'professionnel' : 'particulier'
      const response = await createItem('clients', {
        body: JSON.stringify(data),
      })
      return response
    },
    {
      onSuccess: (data) => {
        setUserSelected(data)
        toast.success('Votre destinataire a bien été créé')
        queryClient.refetchQueries(['clients'])
      },
    }
  )

  useEffect(() => {
    if (funnelState.destinataire) {
      setUserSelected(funnelState.destinataire)
      setCheckedPro(funnelState.destinataire.type === 'professionnel')
      setCheckedIndividual(funnelState.destinataire.type === 'particulier')
    }
  }, [])

  return (
    <div className="card card-funnel">
      <form onSubmit={handleSubmit(mutate)}>
        <CardContent>
          {!userSelected && (
            <Grid item align="center" xs={12} sm={12} md={12}>
              <div className="info">
                <p>
                  Veuillez effectuez la recherche de votre client dans le
                  formulaire. Si vous souhaitez ajouter un nouveau client en
                  tant que destinataire de cette commande, créer le en rentrant
                  les informations nécéssaires, puis valider votre choix.
                </p>
              </div>
            </Grid>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '1.5rem',
            }}
          >
            <label htmlFor="isPro" style={{ fontSize: '1em' }}>
              <p style={{ marginTop: '4.5px' }}>Professionnel</p>
            </label>
            <Checkbox
              id="isPro"
              checked={checkedPro}
              disabled={!!userSelected}
              onChange={() => {
                checkHandler()
              }}
            />
            <Checkbox
              id="isIndividual"
              checked={checkedIndividual}
              disabled={!!userSelected}
              onChange={() => {
                checkHandler()
              }}
            />
            <label htmlFor="isIndividual" style={{ fontSize: '1em' }}>
              <p style={{ marginTop: '4.5px' }}>Particulier</p>
            </label>
          </Box>
          <Grid container justifyContent="center" spacing={2}>
            <input
              type="hidden"
              name="type"
              {...register('type')}
              value={checkedPro ? 'professionnel' : 'particulier'}
            />
            <Grid item xs={12} sm={12} md={2}>
              <label htmlFor="inputCivility">Civilité</label>
              <div className="input">
                <select
                  name="civilite"
                  id="inputCivility"
                  defaultValue={userSelected?.civilite}
                  {...register('civilite')}
                >
                  <option value="M">M.</option>
                  <option value="Mme">Mme</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={{ position: 'relative' }}>
              <label htmlFor="inputLastName">Nom</label>
              <div className="input">
                <input
                  ref={nameRef}
                  onChange={(e) => setStrSearch(e.target.value)}
                  onBlur={() => setTimeout(() => setFilteredClients([]), 300)}
                  type="text"
                  name="nom"
                  autoComplete="off"
                  defaultValue={userSelected?.nom}
                  placeholder="Nom"
                  className="form-control"
                />
              </div>

              <div
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  background: '#0C0117',
                  width: '100%',
                  borderRadius: '26px',
                }}
              >
                {filteredClients.map((client) => (
                  <button
                    type="button"
                    key={client.id}
                    style={{ padding: '0' }}
                    className="list-client-item"
                    onClick={() => handleChoice(client)}
                  >
                    <BadgeAvatars avatarFile={client.avatarFile} />
                    {client.fullname}
                  </button>
                ))}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label htmlFor="inputFirstName">Prénom</label>
              <div className="input">
                <input
                  type="text"
                  name="prenom"
                  defaultValue={userSelected?.prenom}
                  {...register('prenom')}
                  id="inputFirstName"
                  placeholder="Prénom"
                  className="form-control"
                />
              </div>
            </Grid>

            {checkedPro && (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputSociety">Société</label>
                  <div className="input">
                    <input
                      type="text"
                      name="societe"
                      defaultValue={userSelected?.societe}
                      {...register('societe')}
                      id="inputSociety"
                      placeholder="Société"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputJob">Poste</label>
                  <div className="input">
                    <input
                      // onChange={(e) => setStrSearch(e.target.value)}
                      type="text"
                      name="poste"
                      defaultValue={userSelected?.poste}
                      {...register('poste')}
                      id="inputJob"
                      placeholder="Poste"
                      className="form-control"
                    />
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={6}>
              <label htmlFor="inputAddress">Adresse de livraison</label>
              <div className="input">
                <input
                  // onChange={(e) => setStrSearch(e.target.value)}
                  type="text"
                  name="adresse"
                  defaultValue={userSelected?.adresse}
                  id="inputAddress"
                  placeholder="Adresse postale"
                  className="form-control"
                  {...register('adresse')}
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <label htmlFor="inputCP">Code postal</label>
              <div className="input">
                <input
                  type="number"
                  name="codePostal"
                  defaultValue={userSelected?.codePostal}
                  id="inputCP"
                  placeholder="33000"
                  className="form-control"
                  {...register('codePostal')}
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <label htmlFor="inputCity">Ville</label>
              <div className="input">
                <input
                  type="text"
                  name="ville"
                  {...register('ville')}
                  defaultValue={userSelected?.ville}
                  id="inputCity"
                  placeholder="Ville"
                  className="form-control"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label htmlFor="inputEmail">Adresse e-mail</label>
              <div className="input">
                <input
                  type="email"
                  name="email"
                  defaultValue={userSelected?.email}
                  {...register('email')}
                  id="Email"
                  placeholder="Adresse e-mail"
                  className="form-control"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label htmlFor="inputPhone">Téléphone</label>
              <div className="input">
                <input
                  type="phone"
                  name="telephone"
                  id="Phone"
                  defaultValue={userSelected?.telephone}
                  {...register('telephone')}
                  placeholder="Numéro de téléphone"
                  className="form-control"
                  required
                />
              </div>
            </Grid>
            <Grid item align="center" xs={12} sm={12} md={12}>
              {!userSelected && (
                <button
                  type="submit"
                  className="btn"
                  style={{ marginRight: 'auto' }}
                >
                  <em>Enregistrer le destinataire</em>
                </button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </form>

      <Tooltip title="Précédent" placement="right">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            left: '2rem',
          }}
          onClick={previousStep}
        >
          <NavigateBeforeIcon />
        </button>
      </Tooltip>
      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
