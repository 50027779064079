import React from 'react'
import { Checkbox } from '@mui/material'

export default function MessagerieSidebar({
  activeStep,
  setActiveStep,
  funnelSteps,
}) {
  const isCurrentStep = (comparatedStep) => {
    if (
      activeStep.step === comparatedStep.step &&
      activeStep.children === comparatedStep.children
    ) {
      return true
    }
    return false
  }

  return (
    funnelSteps.length && (
      <div className="sidebar-funnel">
        <div className="sidebar-content">
          {funnelSteps.map((step) => {
            if (step?.childrens?.length < 2) {
              return (
                <div
                  className={`step $ ${
                    activeStep.step === step.activeStep.step ? 'active' : ''
                  }`}
                  key={step.id}
                >
                  <div className="box-sidebar">
                    <div className="title-sidebar">
                      <img
                        src={`/icons/funnel/cotation/sidebar/${step.icon}.png`}
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn-no-style h5"
                        onClick={
                          step.completed
                            ? () => setActiveStep(step.activeStep)
                            : null
                        }
                      >
                        {step.titleSidebar} {isCurrentStep(step.activeStep)}
                      </button>
                    </div>
                    <Checkbox
                      className="h5"
                      checked={step.completed || false}
                      disabled
                    />
                  </div>
                </div>
              )
            }
            return (
              <div
                className={`step $ ${
                  activeStep.step === step.activeStep.step ? 'active' : ''
                }`}
                key={step.id}
              >
                <div className="box-sidebar">
                  <div className="title-sidebar">
                    <img
                      src={`/icons/funnel/cotation/sidebar/${step.icon}.png`}
                      alt=""
                    />
                    <button
                      type="button"
                      className="btn-no-style h5"
                      onClick={
                        step.completed
                          ? () => setActiveStep(step.activeStep)
                          : null
                      }
                    >
                      {step.titleSidebar}
                    </button>
                  </div>
                  <Checkbox
                    className="checkbox"
                    checked={step.completed || false}
                    disabled
                  />
                </div>
                {step.childrens?.map((children) => (
                  <button
                    key={children.id}
                    className="btn-sidebar"
                    type="button"
                    onClick={
                      children.completed
                        ? () => setActiveStep(step.activeStep)
                        : null
                    }
                  >
                    <em>
                      {children.titleSidebar}{' '}
                      {isCurrentStep(children.activeStep)}
                    </em>
                  </button>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    )
  )
}
