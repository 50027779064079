/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
  useRowSelect,
} from 'react-table'
import {
  Checkbox,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import MaUTable from '@material-ui/core/Table'
import { styled } from '@mui/material/styles'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { Paper } from '@material-ui/core'
import moment from 'moment'
import { updateItem } from '../../services/Api/request'
import { authSelector } from '../../stores/selectors/authSelectors'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <Checkbox ref={resolvedRef} {...rest} />
  }
)

export default function OrdersTable({
  columns,
  data = [],
  hiddenColumns = [],
  toolbar: Toolbar,
  toolbarProps,
  isSelectable = false,
  handleStatutSelect,
}) {
  const auth = useSelector(authSelector)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    page,
    setFilter,
    setGlobalFilter,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        hiddenColumns: auth.isAdmin
          ? hiddenColumns
          : ['selection', ...hiddenColumns],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isSelectable) {
        hooks.allColumns.push((cols) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...cols,
        ])
      }
    }
  )

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value))
  }

  const updateStatus = () => {
    selectedFlatRows.forEach(async (row) => {
      const { id, type } = row.original
      const req = await updateItem(`${type}`, {
        id,
        filters: '/status?name=delivery',
        body: JSON.stringify({}),
      })
      if (req.ok) {
        queryClient.refetchQueries([type])
      }
    })
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#0C0117',
      borderTop: '1px solid #00000029',
      borderBottom: '1px solid #00000029',
      fontSize: '0.8rem',
      padding: '0.4rem 10px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.8rem',
      padding: '10px',
    },
  }))

  return (
    <>
      {Toolbar && (
        <Toolbar
          {...toolbarProps}
          setFilter={setFilter}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          numSelected={Object.keys(selectedRowIds).length}
          updateStatus={updateStatus}
          handleStatutSelect={handleStatutSelect}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
      )}
      {page.length ? (
        <Paper className="container table-container">
          <MaUTable className="table-content" {...getTableProps()}>
            <TableHead className="table-head">
              {headerGroups.map((headerGroup, index) => (
                <TableRow
                  key={index}
                  className="table-row"
                  {...headerGroup.getHeaderGroupProps()}
                  sx={{ width: '100%' }}
                >
                  {headerGroup.headers.map((column, i) => (
                    <StyledTableCell
                      key={i}
                      className="table-cell"
                      {...(column.id === 'selection' &&
                        column.getHeaderProps())}
                      style={{ height: column.height }}
                    >
                      {column.render('Header')}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody className="table-body" {...getTableBodyProps()}>
              {page.map((row, n) => {
                prepareRow(row)
                if (fromDate && moment(row.original.date).isBefore(fromDate)) {
                  return null
                }
                if (toDate && moment(row.original.date).isAfter(toDate)) {
                  return null
                }

                return (
                  <TableRow
                    key={n}
                    className="table-row"
                    {...row.getRowProps()}
                    sx={{ width: '100%', height: '6rem' }}
                  >
                    <StyledTableCell
                      className="table-cell"
                      {...row.cells[0].getCellProps()}
                    >
                      {row.cells[0].render('Cell')}
                    </StyledTableCell>
                    {row.cells.map(
                      (cell, index) =>
                        index !== 0 && (
                          <StyledTableCell
                            key={index}
                            className="table-cell"
                            {...cell.getCellProps()}
                          >
                            {cell.column.clickable === false ? (
                              cell.render('Cell')
                            ) : (
                              <button
                                type="button"
                                className="cell-clickable"
                                onClick={() =>
                                  navigate(
                                    `/historique/${row.original.type}/${row.original.id}`,
                                    {
                                      state: {
                                        from: location,
                                      },
                                    }
                                  )
                                }
                              >
                                {cell.render('Cell')}
                              </button>
                            )}
                          </StyledTableCell>
                        )
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ width: '100%' }}>
                <TablePagination
                  rowsPerPageOptions={[
                    20,
                    50,
                    { label: 'Tout', value: data.length },
                  ]}
                  colSpan={12}
                  count={data.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage="Nb de lignes"
                  sx={{
                    borderBottom: 'none',
                  }}
                />
              </TableRow>
            </TableFooter>
          </MaUTable>
        </Paper>
      ) : (
        <div
          className="info"
          style={{
            textAlign: 'center',
          }}
        >
          <p>Aucune commande trouvée</p>
        </div>
      )}
    </>
  )
}
