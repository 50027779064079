/* eslint-disable no-unused-vars */
import { Avatar, Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/system'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { apiUrl, updateItem } from '../../services/Api/request'
import BadgeAvatars from './BadgeAvatar'

const CustomAddButton = styled('button')({
  background: '#f6f6f8',
  border: '1px solid #ccc',
  outline: 'none',
  width: '100%',
  height: '100%',
  borderRadius: 50,
  '&:hover': {
    background: '#EFEFEF',
  },
})
const NoStyledButton = styled('button')({
  background: 'none',
  border: 'none',
  outline: 'none',
  margin: '0',
  padding: '0',
  '&:hover': {
    background: '#f6f6f8',
    border: 'none',
    outline: 'none',
  },
})

export default function AdminSelectors({ data, current, disabled = false }) {
  const [anchor, setAnchor] = useState(null)
  const queryClient = useQueryClient()
  const { id, gestionnaire, type } = current

  const { mutate } = useMutation(
    async (adminId) => {
      const response = await updateItem(`${type}/${id}`, {
        body: JSON.stringify({ gestionnaire: adminId }),
      })
      return response
    },
    {
      onSuccess: () => {
        setAnchor(null)
        queryClient.refetchQueries()
      },
    }
  )

  const handleOpen = (e) => {
    if (!disabled) {
      setAnchor(e.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <div
        style={{
          width: '40px',
          height: '40px',
          margin: 'auto',
        }}
      >
        {gestionnaire === null ? (
          <CustomAddButton type="button" onClick={handleOpen}>
            +
          </CustomAddButton>
        ) : (
          <NoStyledButton type="button" onClick={handleOpen}>
            <BadgeAvatars avatarFile={gestionnaire.avatarFile} />
          </NoStyledButton>
        )}
      </div>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        MenuListProps={{
          style: {
            padding: '.5rem',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            gap: '10px',
          },
        }}
      >
        {data?.length > 0
          ? data.map((item) => (
              <MenuItemAdmin
                key={item.id}
                onClick={() => mutate(item.id)}
                {...item}
              />
            ))
          : 'Aucun admin disponible'}
      </Menu>
    </>
  )
}

function MenuItemAdmin({ onClick, prenom, nom, id, avatarFile }) {
  return (
    <MenuItem onClick={onClick} style={{ textAlign: 'left' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          height: '28px',
        }}
      >
        {avatarFile && (
          <Avatar
            src={`${apiUrl}/uploads/${avatarFile}`}
            style={{ height: '28px', width: '28px' }}
          />
        )}
        <p>{`${prenom} ${nom}`}</p>
      </div>
    </MenuItem>
  )
}
