/* eslint-disable no-param-reassign */
export function numberWithSpaces(x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parseFloat(parts.join('.')).toFixed(2)
}

export function addType(items, type) {
  return items.forEach((item) => {
    item.type = type
    return item
  })
}
