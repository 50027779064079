/* eslint-disable react/jsx-no-useless-fragment */
import React, { createRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import UploadIcon from '@mui/icons-material/Upload'
import { useQueryClient } from 'react-query'
import { apiUrl, createItem } from '../../services/Api/request'

function FilePdfUpload({ defaultValue }) {
  const [pdfFile, _setPdfFile] = useState(
    defaultValue ? `${apiUrl}/uploads/${defaultValue}` : null
  )
  const queryClient = useQueryClient()
  const inputFileRef = createRef(null)

  const { type: typeData, id } = useParams()

  const cleanup = () => {
    URL.revokeObjectURL(pdfFile)
    if (inputFileRef.current && inputFileRef.current.value) {
      inputFileRef.current.value = null
    }
  }

  const setPdfFile = (newPdfFile) => {
    if (pdfFile) {
      cleanup()
    }
    _setPdfFile(newPdfFile)
  }

  const handleOnChange = async (event) => {
    const newPdfFile = event.target?.files?.[0]
    if (newPdfFile) {
      setPdfFile(URL.createObjectURL(newPdfFile))
      const fd = new FormData()
      fd.append('preuveLivraison', newPdfFile, newPdfFile.name)
      await createItem(`${typeData}/${id}/upload`, {
        body: fd,
      })
      queryClient.refetchQueries([typeData])
    }
  }

  return (
    <>
      {!pdfFile ? (
        <label htmlFor="preuveLivraison" className="btn-icon">
          <UploadIcon />
          Upload fichier
          <input
            ref={inputFileRef}
            accept="pdf"
            id="preuveLivraison"
            type="file"
            onChange={handleOnChange}
            name="preuveLivraison"
          />
        </label>
      ) : (
        <label
          htmlFor="preuveLivraison"
          className="btn-icon"
          style={{
            backgroundColor: 'black',
            color: 'white',
            boxShadow: 'none',
          }}
        >
          <UploadIcon />
          Fichier téléchargé
          <input
            disabled
            accept="pdf"
            id="preuveLivraison"
            type="file"
            name="preuveLivraison"
          />
        </label>
      )}
    </>
  )
}

export default FilePdfUpload
