/* eslint-disable react/react-in-jsx-scope */
export function verifyMarchandiseContent(marchandisesItems) {
  for (let i = 0; i < marchandisesItems.length; i += 1) {
    if (marchandisesItems[i].quantite && !(marchandisesItems[i].quantite > 0)) {
      return false
    }
    if (
      marchandisesItems[i].longueur &&
      !(marchandisesItems[i].longueur !== '')
    ) {
      return false
    }
    if (
      marchandisesItems[i].largeur &&
      !(marchandisesItems[i].largeur !== '')
    ) {
      return false
    }
    if (
      marchandisesItems[i].hauteur &&
      !(marchandisesItems[i].hauteur !== '')
    ) {
      return false
    }
    if (!(marchandisesItems[i].poids > 0)) {
      return false
    }
  }
  return true
}

export function formatMarchandise(marchandise) {
  if (marchandise.type.details?.includes('métrage')) {
    return `${marchandise.longueur} mL`
  }
  const sizes = []
  if (marchandise.longueur) {
    sizes.push(`${marchandise.longueur}cm`)
  }
  if (marchandise.largeur) {
    sizes.push(`${marchandise.largeur}cm`)
  }
  return sizes.join(' x ')
}

export function formatMarchandiseCell(marchandise) {
  if (marchandise.type.details?.includes('métrage')) {
    return `${marchandise.longueur} mL`
  }
  let str = ''
  const sizes = []
  if (marchandise.quantite) {
    str += marchandise.quantite
  }
  if (marchandise.longueur) {
    sizes.push(`${marchandise.longueur}cm`)
  }
  if (marchandise.largeur) {
    sizes.push(`${marchandise.largeur}cm`)
  }
  if (marchandise.hauteur) {
    sizes.push(`${marchandise.hauteur}cm`)
  }
  return (
    <>
      {`${str} ${marchandise.type.nom}`} <br /> {`${sizes.join(' x ')}`}
    </>
  )
}
