/* eslint-disable react/no-array-index-key */
import React from 'react'
import clsx from 'clsx'
import { Archive } from '@mui/icons-material'
import { Grid, IconButton, Toolbar, Tooltip } from '@mui/material'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Box } from '@material-ui/core'
import { getMany } from '../../services/Api/request'
import CustomizedProgress from '../customElements/CircularProgress'
import TypeFilter from '../filters/TypeFilter'
import CustomDateRangePicker from '../datePicker/CustomDateRangePicker'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '48px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: '#FF00B4',
          backgroundColor: lighten('#FF00B4', 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    fontSize: '0.8rem',
    flex: '1 1 100%',
  },
}))

export default function OrderSearchToolbar({
  globalFilter,
  setGlobalFilter,
  setFilter,
  numSelected,
  updateStatus,
  handleStatutSelect,
  searchParams,
  setFromDate = () => {},
  setToDate = () => {},
}) {
  const classes = useToolbarStyles()
  const { data: statuts, isLoading } = useQuery('statuts', async () => {
    const data = await getMany('statuts')
    return data
  })
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { id: collabId } = useParams()

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="search-bar"
        marginBottom={3}
      >
        <Grid item xs={12} sm={12} md={3} mb={{ xs: 2, sm: 2, md: 0 }}>
          <div className="input">
            <input
              type="search"
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Recherche"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} mb={{ xs: 2, sm: 2, md: 0 }}>
          <CustomDateRangePicker
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} mb={{ xs: 2, sm: 2, md: 0 }}>
          <button
            type="button"
            className="btn"
            onClick={() =>
              navigate('/demandes', {
                state: { collaborateurId: collabId || null },
              })
            }
          >
            <em>Faire une nouvelle demande</em>
          </button>
        </Grid>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            margin: '1rem 0',
          }}
        >
          <TypeFilter setFilter={setFilter} />
        </Box>
        <Grid
          container
          justifyContent="center"
          className="filterByStatuts"
          style={{
            gap: '2rem',
            paddingTop: '.8rem',
          }}
        >
          {isLoading ? (
            <CustomizedProgress />
          ) : (
            statuts.map((statut, index) => {
              if (pathname.includes('commandes')) {
                if (statut.type !== 'delivery') {
                  return (
                    <Statut
                      key={index}
                      searchParams={searchParams}
                      statut={statut}
                      handleStatutSelect={handleStatutSelect}
                    />
                  )
                }
                return null
              }

              return (
                <Statut
                  key={index}
                  statut={statut}
                  searchParams={searchParams}
                  handleStatutSelect={handleStatutSelect}
                />
              )
            })
          )}
        </Grid>
      </Grid>
      {numSelected > 0 ? (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <p className={classes.title}>
            Vous avez selectionné {numSelected} commande(s).
          </p>
          <Tooltip title="Archiver">
            <IconButton aria-label="archiver" onClick={updateStatus}>
              <Archive />
            </IconButton>
          </Tooltip>
        </Toolbar>
      ) : null}
    </>
  )
}

function Statut({ statut, handleStatutSelect, searchParams = [] }) {
  const checked = searchParams.find((el) => el.includes(statut.type))

  return (
    <div>
      <input
        type="checkbox"
        name={statut.nom}
        id={`${statut.nom} status-checkbox`}
        defaultChecked={checked}
        className={`btn-statuts box-${statut.type} checkbox-status`}
        onChange={handleStatutSelect}
        value={statut.type}
      />
      <label
        htmlFor={`${statut.nom} status-checkbox`}
        className={`btn-statuts box-${statut.type}`}
        style={{ padding: '0.6rem 1rem' }}
      >
        {statut.nom}
      </label>
    </div>
  )
}
