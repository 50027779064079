/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { Grid, CardContent, CardActions } from '@material-ui/core'
import CustomizedProgress from '../customElements/CircularProgress'

import { createItem } from '../../services/Api/request'
import { authSelector } from '../../stores/selectors/authSelectors'

import MotionHoc from '../MotionHoc'
import AvatarUpload from '../uploads/AvatarUpload'

function ContactFormComponent({ user }) {
  const { register, handleSubmit } = useForm()
  const auth = useSelector(authSelector)
  const queryClient = useQueryClient()
  const { isLoading: isUpdating, mutate } = useMutation(
    async (data) => {
      const fd = new FormData()
      for (const [key, value] of Object.entries(data)) {
        if (value !== null && value.length > 0) {
          if (data[`${key}`][0] instanceof File) {
            fd.append(`${key}`, value[0], value[0].name)
          } else {
            fd.append(`${key}`, value)
          }
        }
      }
      await createItem('me', { body: fd })
    },
    {
      onSuccess: () => queryClient.refetchQueries(['me']),
    }
  )

  return isUpdating ? (
    <CustomizedProgress />
  ) : (
    <div className="card">
      <form onSubmit={handleSubmit(mutate)}>
        <CardContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <AvatarUpload
                defaultValue={user?.avatarFile ?? ''}
                // defaultValue={user?.avatarFile}
                register={register}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <label htmlFor="inputCivility">Civilité</label>
              <div className="input">
                <select
                  type="civility"
                  name="civilite"
                  id="inputCivility"
                  defaultValue={
                    auth.isAdmin ? user?.civilite : user?.client?.civilite
                  }
                  {...register('civilite')}
                >
                  <option value="M">M.</option>
                  <option value="Mme">Mme</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <label htmlFor="inputLastName">Nom</label>
              <div className="input">
                <input
                  type="text"
                  name="nom"
                  defaultValue={auth.isAdmin ? user.nom : user?.client?.nom}
                  {...register('nom')}
                  id="inputLastname"
                  placeholder="Nom"
                  className="form-control"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label htmlFor="inputFirstName">Prénom</label>
              <div className="input">
                <input
                  type="text"
                  name="prenom"
                  defaultValue={
                    auth.isAdmin ? user.prenom : user?.client?.prenom
                  }
                  {...register('prenom')}
                  id="inputFirstname"
                  placeholder="Prénom"
                  className="form-control"
                />
              </div>
            </Grid>
            {!auth.isAdmin && (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputSociety">Société</label>
                  <div className="input">
                    <input
                      type="text"
                      name="societe"
                      defaultValue={
                        auth.isAdmin ? user.societe : user?.client?.societe
                      }
                      {...register('societe')}
                      id="inputSociety"
                      placeholder="Société"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputJob">Poste</label>
                  <div className="input">
                    <input
                      type="text"
                      name="poste"
                      defaultValue={
                        auth.isAdmin ? user.poste : user?.client?.poste
                      }
                      {...register('poste')}
                      id="inputJob"
                      placeholder="Poste"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputAddress">Adresse de livraison</label>
                  <div className="input">
                    <input
                      type="text"
                      name="adresse"
                      defaultValue={
                        auth.isAdmin ? user.adresse : user?.client?.adresse
                      }
                      {...register('adresse')}
                      id="inputAddress"
                      placeholder="Adresse postale"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <label htmlFor="inputCP">Code postal</label>
                  <div className="input">
                    <input
                      type="number"
                      name="codePostal"
                      defaultValue={
                        auth.isAdmin
                          ? user.codePostal
                          : user?.client?.codePostal
                      }
                      {...register('codePostal')}
                      id="inputCP"
                      placeholder="33000"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <label htmlFor="inputCity">Ville</label>
                  <div className="input">
                    <input
                      type="text"
                      name="ville"
                      defaultValue={
                        auth.isAdmin ? user.ville : user?.client?.ville
                      }
                      {...register('ville')}
                      id="inputCity"
                      placeholder="Ville"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputEmail">Adresse e-mail</label>
                  <div className="input">
                    <input
                      type="email"
                      name="email"
                      defaultValue={
                        auth.isAdmin ? user.email : user?.client?.email
                      }
                      {...register('email')}
                      id="Email"
                      placeholder="Adresse e-mail"
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="inputPhone">Téléphone</label>
                  <div className="input">
                    <input
                      type="phone"
                      name="telephone"
                      defaultValue={
                        auth.isAdmin ? user.telephone : user?.client?.telephone
                      }
                      {...register('telephone')}
                      id="Phone"
                      placeholder="Numéro de téléphone"
                      className="form-control"
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <button type="submit" className="btn">
            <em>Enregister les modifications</em>
          </button>
        </CardActions>
      </form>
    </div>
  )
}

const ContactForm = MotionHoc(ContactFormComponent)
export default ContactForm
