import React from 'react'
import { SvgIcon } from '@mui/material'

export default function CalendarIcon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="15.576"
      height="15.794"
      viewBox="0 0 15.576 15.794"
      {...props}
    >
      <g
        id="Groupe_3017"
        data-name="Groupe 3017"
        transform="translate(-72.212)"
      >
        <g id="ecrire" transform="translate(72.212)">
          <g id="Groupe_2865" data-name="Groupe 2865" transform="translate(0)">
            <g id="Groupe_2864" data-name="Groupe 2864">
              <path
                id="Tracé_4100"
                data-name="Tracé 4100"
                d="M19.312,2.336h-1.4V1.4a1.4,1.4,0,1,0-2.8,0v.937H13.4V1.4a1.4,1.4,0,1,0-2.8,0v.937H8.889V1.4a1.4,1.4,0,1,0-2.8,0v.937h-1.4a.476.476,0,0,0-.476.476V13.861a1.935,1.935,0,0,0,1.932,1.932H17.856a1.935,1.935,0,0,0,1.932-1.932V2.812A.476.476,0,0,0,19.312,2.336Zm-.476,3.007H5.165V3.288h.927V3.53a1.4,1.4,0,1,0,2.8,0V3.288H10.6V3.53a1.4,1.4,0,0,0,2.8,0V3.288h1.712V3.53a1.4,1.4,0,0,0,2.8,0V3.288h.926V5.343h0ZM16.064,1.4a.446.446,0,1,1,.892,0V3.53a.446.446,0,1,1-.892,0Zm-4.51,0a.446.446,0,1,1,.892,0V2.812h0V3.53a.446.446,0,0,1-.892,0Zm-4.51,0a.446.446,0,1,1,.892,0V2.811h0V3.53a.446.446,0,1,1-.892,0V1.4ZM18.836,13.861a.981.981,0,0,1-.98.98H6.144a.981.981,0,0,1-.98-.98V6.3H18.836Z"
                transform="translate(-4.212)"
                fill="#0c0117"
              />
              <path
                id="Tracé_4101"
                data-name="Tracé 4101"
                d="M77.5,298.958H88.37a.476.476,0,1,0,0-.953H77.5a.476.476,0,0,0,0,.953Z"
                transform="translate(-75.149 -290.314)"
                fill="#0c0117"
              />
              <path
                id="Tracé_4102"
                data-name="Tracé 4102"
                d="M77.5,384.533H88.37a.476.476,0,0,0,0-.953H77.5a.476.476,0,0,0,0,.953Z"
                transform="translate(-75.149 -373.681)"
                fill="#0c0117"
              />
              <path
                id="Tracé_4103"
                data-name="Tracé 4103"
                d="M77.5,470.109H88.37a.476.476,0,1,0,0-.953H77.5a.476.476,0,0,0,0,.953Z"
                transform="translate(-75.149 -457.049)"
                fill="#0c0117"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
