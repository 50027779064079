// A custom theme for this app
import { createTheme } from '@mui/material/styles'
import GorditaRegular from './assets/fonts/Gordita Regular.woff'

const customTheme = createTheme({
  typography: {
    fontFamily: 'Gordita Regular !important',
    fontSize: 11.25,
    body: {
      fontSize: '.8rem',
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      mb: '1.6rem',
    },
    h5: {
      fontSize: '1rem',
    },
    button: {
      fontSize: '.8rem',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#FF00B4',
    },
    secondary: {
      main: '#7d58c8',
    },
    warning: {
      main: '#ff9100',
    },
    info: {
      main: '#556cda',
    },
    success: {
      main: '#b6ec4e',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gordita Regular'!important;
          font-size: '0.8rem !important';
          line-height: 1;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gordita'), local('Gordita-Regular'), url(${GorditaRegular}) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        // button {
        //   border-radius: 100%;
        //   line-height: inherit;
        // }
      `,
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '.MuiFormControlLabel-label': {
            fontSize: '0.8rem',
          },
        },
      },
    },
    // MuiButtonBass:{
    //   styleOverrides: {
    //     root: {
    //       position: 'none',
    //     }
    //   }
    // }
    // MuiButtonBase-root-MuiToggleButton-root.Mui-selected
    MuiToggleButton: {
      styleOverrides: {
        root: {
          ':(.Mui-selected)': {
            color: 'white !important',
            backgroundColor: '#0b0421 !important',
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Gordita Regular',
          fontSize: '0.8rem',
          // transform: 'translate(18px, 12px) scale(1)',
        },
      },
    },
    // Input.
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '46px',
          fontFamily: 'Gordita Regular',
          fontSize: '0.8rem',
          backgroundColor: 'transparent',
          // '&.Mui-focused': {
          //   borderWidth: 1,
          // }
          // },
          '-.notchedOutline': {
            border: '1.5px solid #00000029',
          },
        },
        input: {
          padding: '0.75rem 1.25rem',
          border: 'none',
        },
      },
    },
    // Pickers Calendar
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          ':not(.Mui-selected)': {
            borderRadius: '100px',
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#1b5e20',
      },
    },

    MuiPickersBasePicker: {
      styleOverrides: {
        pickerView: {
          backgroundColor: 'black',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: '1600',
        },
      },
    },
  },
})

export default customTheme
