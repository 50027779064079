/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Modal } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Grid } from '@mui/material'
import { createItem, getOne, updateItem } from '../services/Api/request'
import CustomizedProgress from '../components/customElements/CircularProgress'
import {
  NotificationForm,
  SecuriteForm,
  ContactForm,
} from '../components/account'
import { authSelector } from '../stores/selectors/authSelectors'
import { updateUserAction } from '../stores/actions/authActions'

export default function Compte() {
  const auth = useSelector(authSelector)
  const [index, setIndex] = useState(0)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const handleOpenEmailModal = () => setOpenEmailModal(true)
  const handleOpenPasswordModal = () => setOpenPasswordModal(true)
  const closeModal = () => {
    setOpenEmailModal(false)
    setOpenPasswordModal(false)
  }
  const dispatch = useDispatch()

  const { data: user, isLoading } = useQuery('me', async () => {
    const data = await getOne('me')
    dispatch(
      updateUserAction({
        user: data,
      })
    )
    return data
  })

  function renderComponent(user) {
    switch (index) {
      case 0:
        return <ContactForm user={user} />
      case 1:
        return (
          <SecuriteForm
            openEmailModal={handleOpenEmailModal}
            openPasswordModal={handleOpenPasswordModal}
            user={user}
          />
        )
      case 2:
        return <NotificationForm user={user} />
      default:
        return null
    }
  }

  return (
    <div className="content-account">
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={0} sm={0} md={3}>
          <div className="sidebar">
            <div className="sidebar-content">
              <div className="title-sidebar">
                <FontAwesomeIcon icon={faSuitcase} />
                <h5>Paramètres</h5>
              </div>
              <button
                onClick={() => setIndex(0)}
                className="btn btn-sidebar"
                type="button"
              >
                <em>Général</em>
              </button>
              <button
                onClick={() => setIndex(1)}
                className="btn btn-sidebar"
                type="button"
              >
                <em>Sécurité</em>
              </button>
              {!auth.isAdmin && (
                <button
                  onClick={() => setIndex(2)}
                  className="btn btn-sidebar"
                  type="button"
                >
                  <em>Notifications</em>
                </button>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Modal open={openEmailModal} onClose={closeModal}>
            <div className="card-modal">
              <div className="card">
                <h5>Changer d'adresse email</h5>
                <FormEmail closeModal={closeModal} />
              </div>
            </div>
          </Modal>
          <Modal open={openPasswordModal} onClose={closeModal}>
            <div className="card-modal">
              <div className="card">
                <h5>Changer le mot de passe</h5>
                <FormPassword closeModal={closeModal} />
              </div>
            </div>
          </Modal>
          {isLoading ? <CustomizedProgress /> : renderComponent(user)}
        </Grid>
      </Grid>
    </div>
  )
}

function FormEmail() {
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const { isLoading: isUpdating, mutate } = useMutation(
    async (data) => {
      await updateItem('me', { body: JSON.stringify(data) })
    },
    {
      onSuccess: async ({ closeModal }) => {
        await queryClient.refetchQueries(['me'])
        toast.success('Adresse e-mail modifiée avec succès.')
        closeModal()
      },
    }
  )

  return (
    <form onSubmit={handleSubmit(mutate)}>
      <div className="input">
        <input
          type="email"
          name="email"
          placeholder="E-mail"
          className="form-control"
          {...register('email')}
        />
      </div>

      <button type="submit" className="btn">
        <em>Modifier votre adresse e-mail</em>
      </button>
    </form>
  )
}

function FormPassword({ closeModal }) {
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    async (data) => {
      if (data.password !== data.password_verify) {
        toast.error('Les mots de passe ne sont pas identiques.')
      } else {
        await createItem('reset_password', {
          body: JSON.stringify({
            plainPassword: data.password,
          }),
        })
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['me'])
        toast.success('Mot de passe modifié avec succès.')
        closeModal()
      },
    }
  )

  return (
    <form onSubmit={handleSubmit(mutate)}>
      <div className="input">
        <input
          type="password"
          name="password"
          placeholder="Mot de passe"
          className="form-control"
          {...register('password')}
        />
      </div>
      <div className="input">
        <input
          type="password"
          name="password_verify"
          placeholder="Confirmer le mot de passe"
          className="form-control"
          {...register('password_verify')}
        />
      </div>

      <button type="submit" className="btn">
        <em>Modifier votre mot de passe</em>
      </button>
    </form>
  )
}
