import { httpClient } from './client'

export const apiUrl = `${process.env.REACT_APP_API_ENTRYPOINT}`

export const getMeWithToken = async (token) => {
  const res = await fetch(`${apiUrl}/me`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
  const user = await res.json()
  return user
}

export const getMany = async (resource, params = {}) => {
  let url = ''
  if (params.filters) {
    url = `${apiUrl}/${resource}${params.filters}`
  } else {
    url = `${apiUrl}/${resource}`
  }

  return httpClient(url, { method: 'GET' }).then((res) => res.json())
}

export const getOne = async (resource, params = {}) =>
  httpClient(`${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}`, {
    method: 'GET',
  }).then((res) => res.json())

export const createItem = async (resource, params = {}) =>
  httpClient(`${apiUrl}/${resource}`, {
    method: 'POST',
    body: params.body,
  }).then((res) => res.json())

export const updateItem = async (resource, params = {}) =>
  httpClient(
    `${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}${
      params.filters ? `${params.filters}` : ''
    }`,
    {
      method: 'PUT',
      body: params.body,
    }
  ).then((res) => res.json())

export const deleteItem = async (resource, params = {}) =>
  httpClient(`${apiUrl}/${resource}/${params.id}`, {
    method: 'DELETE',
  }).then((res) => res)
