/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import { Grid, InputAdornment } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faHouseUser,
  faPhone,
  faTruckFast,
  faAt,
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { HuubSwitch } from '../../../customElements/Switch'
import { createItem } from '../../../../services/Api/request'
import ValidationCotation from '../ValidationCotation'
import { authSelector } from '../../../../stores/selectors/authSelectors'
import RecapTaxe from './components/RecapTaxe'
import { formatMarchandise } from '../04-marchandises/utils'

export default function RecapitulatifCotationStep({
  item: funnelItem,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
  step,
}) {
  const {
    typeFret,
    expediteur,
    expeditionContrainteAcces,
    expeditionContrainteChargement,
    date,
    commentaireDate,
    destinataire,
    destinationContrainteAcces,
    destinationContrainteChargement,
    marchandisesItems,
    marchandisesValue,
    euSwitch,
    euSwitchQte,
    assurance,
    incotermSelect,
  } = funnelState
  const [activeValidation, setActiveValidation] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [fileName, setFileName] = useState(null)
  const auth = useSelector(authSelector)

  const { mutate } = useMutation(async (data) => {
    if (!data.incoterm) {
      data.incoterm = ''
    }
    const response = await createItem('cotations', {
      body: JSON.stringify(data),
    })
    if (response.ok) {
      toast.success('Demande envoyée')
      setActiveValidation(true)
    }
    setFileName(response.bordereau)
    return response
  })

  function transformDataforPost(data) {
    const formattedData = {
      typeFret: data.typeFret.id,
      expediteur: data.expediteur.id,
      date: data.date,
      commentaireDate: data.commentaireDate,
      expeditionContrainteAcces: data.expeditionContrainteAcces.id,
      expeditionContrainteChargement: data.expeditionContrainteChargement.id,
      destinataire: data.destinataire.id,
      destinationContrainteAcces: data.destinationContrainteAcces.id,
      destinationContrainteChargement: data.destinationContrainteChargement.id,
      incoterm: data.incotermSelect,
      assurance: data.assurance,
      assuranceValue: data.marchandisesValue,
      marchandisesItems: data.marchandisesItems,
    }
    if (auth.isAdmin) {
      formattedData.collaborateur = data.collaborateur
    }

    return formattedData
  }

  const onSubmit = async () => {
    setIsSubmiting(true)
    if (funnelState) {
      const data = transformDataforPost(funnelState)
      await mutate(data)
      setFunnelSteps(step, funnelItem)
      toast.success('Demande envoyée')
      setActiveValidation(true)
    } else {
      toast.error('Une erreur est survenue')
      setIsSubmiting(false)
    }
  }

  const deleteMarchandise = (id) => {
    const index = marchandisesItems.map((element) => element.id).indexOf(id)
    marchandisesItems.splice(index, 1)
    setFunnelState((prevState) => ({
      ...prevState,
      marchandisesItems,
    }))
  }

  const goToStep = (targetStep) => {
    setFunnelSteps(step, funnelItem)
    setActiveStep(targetStep)
  }

  return activeValidation && fileName !== null ? (
    <div className="card card-funnel-validation">
      <ValidationCotation name={fileName} />
    </div>
  ) : (
    <div className="recap-step">
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        style={{ rowGap: '1.5rem', paddingBottom: '1.5rem' }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <h5>Type de fret</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div
            className="card"
            style={{
              padding: '1rem 2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={`/icons/funnel/cotation/${typeFret.icon}`}
              alt="svg"
              style={{ width: '42px', margin: 'auto' }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <h5>Expediteur</h5>
          <div className="card">
            <h5 className="fullname">
              <FontAwesomeIcon
                icon={faAddressCard}
                style={{ marginRight: '1rem' }}
              />
              {`${expediteur.civilite} ${expediteur.prenom} ${expediteur.nom}`}
            </h5>
            <p>
              <FontAwesomeIcon
                icon={faHouseUser}
                style={{ marginRight: '1rem' }}
              />
              {`${expediteur.adresse},`} <br />
              {`${expediteur.codePostal} ${expediteur.ville}`}
            </p>
            <Box
              style={{
                marginTop: '0.8rem',
                marginBottom: '0.8rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ marginRight: '1rem' }}
                />
                {expediteur.telephone}
              </p>
              <p>
                <FontAwesomeIcon icon={faAt} style={{ marginRight: '1rem' }} />
                {expediteur.email}
              </p>
            </Box>
            <button
              type="button"
              className="btn-icon"
              onClick={() => {
                goToStep({ step: 1, children: 0 })
              }}
            >
              <EditIcon />
              Modifier
            </button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <h5>Destinataire</h5>
          <div className="card">
            <h5 className="fullname">
              <FontAwesomeIcon
                icon={faAddressCard}
                style={{ marginRight: '1rem' }}
              />
              {`${destinataire.civilite} ${destinataire.prenom} ${destinataire.nom}`}
            </h5>
            <p>
              <FontAwesomeIcon
                icon={faHouseUser}
                style={{ marginRight: '1rem' }}
              />
              {`${destinataire.adresse},`} <br />
              {`${destinataire.codePostal} ${destinataire.ville}`}
            </p>
            <Box
              style={{
                marginTop: '0.8rem',
                marginBottom: '0.8rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ marginRight: '1rem' }}
                />
                {destinataire.telephone}
              </p>
              <p>
                <FontAwesomeIcon icon={faAt} style={{ marginRight: '1rem' }} />
                {destinataire.email}
              </p>
            </Box>
            <button
              type="button"
              className="btn-icon"
              onClick={() => {
                goToStep({ step: 2, children: 0 })
              }}
            >
              <EditIcon />
              Modifier
            </button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <h5>Instructions</h5>
          <div className="card">
            <Grid
              container
              spacing={2}
              style={{
                marginBottom: '1.5rem',
              }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <p>Enlèvement demandé pour le :</p>
                <p className="input-info">{date.format('L')}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className="input-info-grey">
                  <p>Annotations</p>
                  <p>
                    {commentaireDate || 'Aucune information supplémentaire'}
                  </p>
                </Box>
              </Grid>
            </Grid>
            <Box className="tableRecap">
              <p>
                <FontAwesomeIcon
                  icon={faTruckFast}
                  style={{ marginRight: '1rem' }}
                />
                Expédition
              </p>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`/icons/funnel/cotation/${expeditionContrainteAcces.icon}`}
                  alt="svg"
                />
                <p style={{ marginLeft: '1rem' }}>
                  {expeditionContrainteAcces.nom}
                </p>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`/icons/funnel/cotation/${expeditionContrainteChargement.icon}`}
                  alt="svg"
                />
                <p style={{ marginLeft: '1rem' }}>
                  {expeditionContrainteChargement.nom}
                </p>
              </Box>
              <button
                type="button"
                className="btn-icon"
                onClick={() => {
                  goToStep({ step: 1, children: 2 })
                }}
              >
                <EditIcon />
                Modifier
              </button>
            </Box>
            <Box className="tableRecap">
              <p>
                <FontAwesomeIcon
                  icon={faTruckFast}
                  style={{ marginRight: '1rem' }}
                />
                Destination
              </p>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`/icons/funnel/cotation/${destinationContrainteAcces.icon}`}
                  alt="svg"
                />
                <p style={{ marginLeft: '1rem' }}>
                  {destinationContrainteAcces.nom}
                </p>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`/icons/funnel/cotation/${destinationContrainteChargement.icon}`}
                  alt="svg"
                />
                <p style={{ marginLeft: '1rem' }}>
                  {destinationContrainteChargement.nom}
                </p>
              </Box>
              <button
                type="button"
                className="btn-icon"
                onClick={() => {
                  goToStep({ step: 2, children: 1 })
                }}
              >
                <EditIcon />
                Modifier
              </button>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <h5>Marchandises</h5>
          <div className="card">
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '1.5rem',
              }}
            >
              {marchandisesItems.map((item) => (
                <p
                  className="input-info input-info-black"
                  style={{ color: '#FFFFF' }}
                  key={item.id}
                >
                  {(item.type === 3 && 'Marchandise paletisée') ||
                    (item.type === 4 && 'Marchandise paletisée') ||
                    (item.type === 5 && 'marchandise non paletisée')}
                </p>
              ))}
            </Box>
            <Box className="table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <h5>N°</h5>
                    </th>
                    <th>
                      <h5>Quantité</h5>
                    </th>
                    <th>
                      <h5>Format (cm)</h5>
                    </th>
                    <th>
                      <h5>Hauteur</h5>
                    </th>
                    <th>
                      <h5>Poids (kg)</h5>
                    </th>
                    <th>{}</th>
                    <th>{}</th>
                  </tr>
                </thead>
                <tbody style={{ borderBottom: '1px solid #f6f6f8 !important' }}>
                  {marchandisesItems.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.quantite}</td>
                      <td>{formatMarchandise(item)}</td>
                      <td>{item.hauteur}</td>
                      <td>{item.poids}</td>
                      <td style={{ width: '17%' }}>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => {
                            goToStep({ step: 3, children: 0 })
                          }}
                        >
                          <EditIcon />
                          Modifier
                        </button>
                      </td>
                      {marchandisesItems.length > 1 && (
                        <td style={{ width: '17%' }}>
                          <button
                            type="button"
                            className="btn-icon"
                            onClick={() => {
                              deleteMarchandise(item.id)
                            }}
                          >
                            <DeleteIcon />
                            Supprimer
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Grid container style={{ marginBottom: '1.5rem', width: '100%' }}>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  <HuubSwitch
                    type="checkbox"
                    name="euSwitch"
                    id="euSwitch"
                    defaultChecked={euSwitch}
                    onClick={(event) => {
                      const isCheckedEuSwitch =
                        document.querySelector('#euSwitch').checked
                      if (isCheckedEuSwitch) {
                        setFunnelState({
                          ...funnelState,
                          euSwitch: isCheckedEuSwitch,
                        })
                      } else {
                        setFunnelState({
                          ...funnelState,
                          euSwitch: isCheckedEuSwitch,
                          euSwitchQte: null,
                        })
                      }
                    }}
                  />
                  {euSwitch ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <label htmlFor="euSwitch">
                        Palettes Europe à échanger
                      </label>
                      <TextField
                        id="InputQteSwitch"
                        type="number"
                        defaultValue={euSwitchQte}
                        style={{
                          marginLeft: '1rem',
                          width: '100px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        variant="standard"
                        onChange={(event) => {
                          setFunnelState({
                            ...funnelState,
                            euSwitchQte: event.target.value,
                          })
                        }}
                      />
                    </span>
                  ) : (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <label htmlFor="inputEuPal">
                        Aucune palette europe à echanger
                      </label>
                    </span>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {marchandisesItems.map((item) => (
                  <Box
                    key={item.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <label htmlFor="inputGePal">Palettes gerbables :</label>
                    <p style={{ marginLeft: '1rem' }} key={item.id}>
                      {item.gerbable ? 'oui' : 'non'}
                    </p>
                  </Box>
                ))}
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: '1rem', width: '100%' }}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <HuubSwitch
                  type="checkbox"
                  name="assurance"
                  id="assurance"
                  defaultChecked={funnelState.assurance}
                  onClick={(event) => {
                    const isCheckedAssurance =
                      document.querySelector('#assurance').checked
                    if (isCheckedAssurance) {
                      setFunnelState({
                        ...funnelState,
                        assurance: isCheckedAssurance,
                      })
                    } else {
                      setFunnelState({
                        ...funnelState,
                        assurance: isCheckedAssurance,
                        marchandisesValue: null,
                      })
                    }
                  }}
                />
                {assurance ? (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="inputEuPal">
                      Vos marchandises sont assurées pour une valeur de
                    </label>
                    <TextField
                      id="InputPrice"
                      type="text"
                      defaultValue={marchandisesValue}
                      style={{
                        marginLeft: '1rem',
                        width: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                      variant="standard"
                      onChange={(event) => {
                        setFunnelState({
                          ...funnelState,
                          marchandisesValue: event.target.value,
                        })
                      }}
                    />
                  </span>
                ) : (
                  <span>
                    <label htmlFor="inputEuPal">
                      Vous avez choisi de ne pas assurer votre envoi !
                    </label>
                    <p className="small">
                      En cas de perte ou dommage seule une indemnité
                      contractuelle transporteur pourra vous être versée.
                    </p>
                  </span>
                )}
              </Box>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RecapTaxe funnelState={funnelState} />
          {/* <h5>Droits et taxes</h5>
          <div className="card">
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p>Incoterms</p>
              <input
                className="input-info"
                type="text"
                value={incotermSelect || 'À définir'}
                readOnly
                style={{ marginTop: '0' }}
              />
            </Box>
          </div> */}
        </Grid>
        <button
          type="submit"
          className="btn"
          disabled={isSubmiting}
          onClick={onSubmit}
        >
          <em>Valider ma commande</em>
        </button>
      </Grid>
    </div>
  )
}
