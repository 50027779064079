import { Box } from '@material-ui/core'
import React from 'react'

function RecapTaxe({ funnelState }) {
  return (
    <>
      <h5>Droits et taxes</h5>
      <div className="card">
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>Incoterms</p>
          <input
            className="input-info"
            type="text"
            value={funnelState.incotermSelect || 'À définir'}
            readOnly
            style={{ marginTop: '0' }}
          />
        </Box>
      </div>
    </>
  )
}

export default RecapTaxe
