/* eslint-disable no-nested-ternary */
import { Box, InputAdornment, TextField } from '@mui/material'
import React from 'react'

function TarificationBloc({
  auth,
  data,
  prixAchat,
  prixVente,
  typeData,
  setPrixAchat,
  setPrixVente,
}) {
  return (
    <>
      <h5>Tarification</h5>
      <div
        className="card"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {auth.isAdmin ? (
          <>
            <Box
              style={{
                width: '100%',
                padding: '1rem 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p>Prix d'achat</p>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {typeData === 'cotations' ? (
                  <TextField
                    id="InputBuyPrice"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€ H.T</InputAdornment>
                      ),
                      inputProps: {
                        step: '0.01',
                        presicion: '2',
                      },
                    }}
                    variant="standard"
                    onChange={(e) => {
                      const { value } = e.target
                      value.replace(',', '.')
                      setPrixAchat(value)
                    }}
                    defaultValue={parseFloat(data?.prixAchat)}
                  />
                ) : (
                  // <p>{`${parseFloat(data?.prixAchat).toFixed(2)} € H.T`}</p>
                  <p>NC</p>
                )}
              </Box>
            </Box>
            <Box
              style={{
                width: '100%',
                padding: '1rem 0',
                borderTop: '1px solid #00000029',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p>Prix de vente</p>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {typeData === 'cotations' ? (
                  <TextField
                    id="InputSellPrice"
                    type="number"
                    min="0.00"
                    step="0.01"
                    max="1.00"
                    presicion={2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€ H.T</InputAdornment>
                      ),
                      inputProps: {
                        step: '0.01',
                        presicion: '2',
                      },
                    }}
                    variant="standard"
                    onChange={(e) => {
                      const { value } = e.target
                      value.replace(',', '.')
                      setPrixVente(value)
                    }}
                    defaultValue={parseFloat(data?.prixVente)}
                  />
                ) : (
                  // <p>{`${parseFloat(data?.prixVente).toFixed(2)} € H.T`}</p>
                  <p>NC</p>
                )}
              </Box>
            </Box>
            <Box
              style={{
                width: '100%',
                padding: '1rem 0',
                borderTop: '1px solid #00000029',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p>Marge NET</p>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {typeData === 'cotations' ? (
                  <h5 style={{ marginBottom: 0, color: '#00BDFF' }}>
                    {`${parseFloat(prixVente - prixAchat).toFixed(2)} € H.T`}
                  </h5>
                ) : (
                  <h5 style={{ marginBottom: 0, color: '#00BDFF' }}>
                    {/* {`${parseFloat(data?.marge).toFixed(2)} € H.T `} */}
                    NC
                  </h5>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box
            style={{
              width: '100%',
              padding: '1rem 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p>TOTAL</p>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p>
                {typeData === 'cotations'
                  ? data.prixVente
                    ? `${parseFloat(data.prixVente).toFixed(2)} € H.T`
                    : 'En cours'
                  : 'NC'}
              </p>
            </Box>
          </Box>
        )}
      </div>
    </>
  )
}

export default TarificationBloc
