import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSliders,
  faFolder,
  faUser,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AccountPopover } from './account'
import { CustomLink } from './customElements/CustomLink'
import { authSelector } from '../stores/selectors/authSelectors'

export default function Navigation(props) {
  const [navBurger, setNavBurger] = useState(false)
  const [btnIsActive, setBtnIsActive] = useState(false)
  const auth = useSelector(authSelector)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      window.location.pathname === '/demandes' ||
      window.location.pathname === '/cotation' ||
      window.location.pathname === '/messagerie'
    ) {
      setBtnIsActive(true)
    } else {
      setBtnIsActive(false)
    }
  }, [props])

  return (
    <header id="navigation">
      <nav className="navbar">
        <div className="container">
          <div className="navbar-admin">
            <input
              id="nav-toggle"
              type="checkbox"
              className={`nav-burger ${navBurger ? 'active' : ''}`}
              onChange={() => setNavBurger(!navBurger)}
              checked={navBurger}
            />
            <label htmlFor="nav-toggle" className="icon-burger">
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </label>
            <NavLink
              to="/"
              className="nav-brand"
              onClick={() => setNavBurger(false)}
            >
              <div className="brand">
                <div className="brand-svg" />
                <div className="brand-logo">
                  <div className="box-logo" />
                </div>
              </div>
            </NavLink>
            <ul className={`nav-links ${navBurger ? 'active' : 'hide'}`}>
              {auth.isAdmin ? (
                <>
                  <li>
                    <CustomLink
                      className="nav-menu-item"
                      to="/"
                      onClick={() => setNavBurger(false)}
                    >
                      <em>
                        <FontAwesomeIcon icon={faChartLine} />
                        Dashboard
                      </em>
                    </CustomLink>
                  </li>
                  <li>
                    <CustomLink
                      className="nav-menu-item"
                      to="/commandes"
                      onClick={() => setNavBurger(false)}
                    >
                      <em>
                        <FontAwesomeIcon icon={faSliders} />
                        Nouvelles commandes
                      </em>
                    </CustomLink>
                  </li>
                </>
              ) : (
                <li>
                  <CustomLink
                    className="nav-menu-item"
                    to="/demandes"
                    onClick={() => setNavBurger(false)}
                  >
                    <em>
                      <FontAwesomeIcon icon={faSliders} />
                      Demande de prise en charge
                    </em>
                  </CustomLink>
                </li>
              )}
              <li>
                <CustomLink
                  className="nav-menu-item"
                  to="/historique"
                  onClick={() => setNavBurger(false)}
                >
                  <em>
                    <FontAwesomeIcon icon={faFolder} />
                    Historique des commandes
                  </em>
                </CustomLink>
              </li>
              <li>
                <CustomLink
                  className="nav-menu-item"
                  to="/contacts"
                  onClick={() => setNavBurger(false)}
                >
                  <em>
                    <FontAwesomeIcon icon={faUser} />
                    Carnet de {auth.isAdmin ? 'clientèle' : 'contact'}
                  </em>
                </CustomLink>
              </li>
              {auth.isAdmin ? (
                <li>
                  <button
                    type="button"
                    className={`btn ${btnIsActive ? 'active' : ''}`}
                    onClick={() => {
                      setNavBurger(false)
                      navigate('/demandes')
                    }}
                  >
                    <em>Faire une nouvelle demande</em>
                  </button>
                </li>
              ) : null}
            </ul>
            <AccountPopover />
          </div>
        </div>
      </nav>
    </header>
  )
}
