import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { authSelector } from '../../../stores/selectors/authSelectors'

export default function ValidationCotation() {
  const auth = useSelector(authSelector)
  return (
    <Box sx={{ flexGrow: 1 }} width="100%">
      <Grid
        container
        direction="column"
        justifyContent="stretch"
        alignItems="center"
        spacing={2}
      >
        <Grid
          item
          align="center"
          xs={12}
          sm={12}
          md={6}
          style={{ width: '100%' }}
        >
          <div className="info-validation">
            <p>Votre commande a bien été prise en compte</p>
            <br />
            <p className="small">
              Pensez à télécharger le bon de prise en charge à coller sur votre
              marchandise. <br />
              Un duplicata vous sera transmis par e-mail.
            </p>
          </div>
        </Grid>

        <Grid item align="center" xs={12} sm={12} md={6}>
          {auth.isAdmin ? (
            <button type="button" className="btn">
              <Link to="/commandes">
                <em>Retour aux nouvelles commandes</em>
              </Link>
            </button>
          ) : (
            <button type="button" className="btn">
              <Link to="/historique">
                <em>Retour à l'historique des commandes</em>
              </Link>
            </button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
