import {
  ExpediteurStep,
  ExpeditionDateStep,
  ExpeditionInstructionsStep,
  DestinataireStep,
  LivraisonInstructionsStep,
  MarchandisesMessagerieStep,
  AssuranceStep,
  RecapitulatifMessagerieStep,
} from '../../components/funnels'

export const initialStateMessagerie = [
  {
    id: 0,
    icon: 'expedition',
    title: 'Expédition',
    titleSidebar: 'Expédition',
    completed: false,
    activeStep: { step: 0, children: 0 },
    childrens: [
      {
        id: 0,
        title: ' | Expéditeur',
        titleSidebar: 'Expéditeur',
        component: ExpediteurStep,
        values: {},
        completed: false,
        previousStep: null,
        activeStep: { step: 0, children: 0 },
        nextStep: { step: 0, children: 1 },
      },
      {
        id: 1,
        title: ' | Date de prise en charge',
        titleSidebar: 'Date de prise en charge',
        component: ExpeditionDateStep,
        values: {},
        completed: false,
        previousStep: { step: 0, children: 0 },
        activeStep: { step: 0, children: 1 },
        nextStep: { step: 0, children: 2 },
      },
      {
        id: 2,
        title: ' | Instructions',
        titleSidebar: 'Instructions',
        component: ExpeditionInstructionsStep,
        values: {},
        completed: false,
        previousStep: { step: 0, children: 1 },
        activeStep: { step: 0, children: 2 },
        nextStep: { step: 1, children: 0 },
      },
    ],
  },
  {
    id: 1,
    icon: 'livraison',
    title: 'Livraison',
    titleSidebar: 'Livraison',
    completed: false,
    activeStep: { step: 1, children: 0 },
    childrens: [
      {
        id: 0,
        title: ' | Destinataire',
        titleSidebar: 'Destinataire',
        component: DestinataireStep,
        values: {},
        completed: false,
        previousStep: { step: 0, children: 2 },
        activeStep: { step: 1, children: 0 },
        nextStep: { step: 1, children: 1 },
      },
      {
        id: 1,
        title: ' | Instructions',
        titleSidebar: 'Instructions',
        component: LivraisonInstructionsStep,
        values: {},
        completed: false,
        previousStep: { step: 1, children: 0 },
        activeStep: { step: 1, children: 1 },
        nextStep: { step: 2, children: 0 },
      },
    ],
  },
  {
    id: 2,
    icon: 'marchandise',
    title: '',
    titleSidebar: 'Marchandises',
    completed: false,
    activeStep: { step: 2, children: 0 },
    childrens: [
      {
        id: 0,
        title: 'Marchandises',
        titleSidebar: 'Marchandises',
        component: MarchandisesMessagerieStep,
        values: {},
        completed: false,
        previousStep: { step: 1, children: 1 },
        activeStep: { step: 2, children: 0 },
        nextStep: { step: 2, children: 1 },
      },
      {
        id: 1,
        title: ' | Assurance',
        titleSidebar: 'Assurance',
        component: AssuranceStep,
        values: {},
        completed: false,
        previousStep: { step: 2, children: 0 },
        activeStep: { step: 2, children: 1 },
        nextStep: { step: 3, children: 0 },
      },
    ],
  },
  {
    id: 3,
    icon: 'recap',
    title: 'Récapitulatif',
    titleSidebar: 'Récapitulatif',
    completed: false,
    activeStep: { step: 3, children: 0 },
    childrens: [
      {
        id: 0,
        title: ' de votre demande',
        titleSidebar: 'Récapitulatif',
        component: RecapitulatifMessagerieStep,
        values: {},
        completed: false,
        previousStep: { step: 2, children: 1 },
        activeStep: { step: 3, children: 0 },
        nextStep: null,
      },
    ],
  },
]
