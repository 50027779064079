import React from 'react'
import { useQuery } from 'react-query'
import CustomizedProgress from '../components/customElements/CircularProgress'
import MotionHoc from '../components/MotionHoc'
import { getMany } from '../services/Api/request'
import { numberWithSpaces } from '../utils/function'

function DashboardComponent() {
  const { data: stats, isLoading } = useQuery('stats', async () => {
    const data = await getMany('stats')
    return data
  })

  return isLoading ? (
    <CustomizedProgress />
  ) : (
    <div className="stats-dashboard">
      <div>
        <p style={{ marginBottom: '1.5rem' }}>Nombres d'envois</p>
        <div className="card">
          <h5 style={{ marginBottom: 0 }}>
            {numberWithSpaces(stats.nombreEnvois)}
          </h5>
        </div>
      </div>
      <div>
        <p style={{ marginBottom: '1.5rem' }}>Coût global</p>
        <div className="card">
          <h5 style={{ marginBottom: 0 }}>{numberWithSpaces(stats.cout)} €</h5>
        </div>
      </div>
      <div>
        <p style={{ marginBottom: '1.5rem' }}>Marge totale</p>
        <div className="card">
          <h5 style={{ marginBottom: 0 }}>{numberWithSpaces(stats.marge)} €</h5>
        </div>
      </div>
    </div>
  )
}

const Dashboard = MotionHoc(DashboardComponent)

export default Dashboard
