/* eslint-disable react/destructuring-assignment */
import React from 'react'
import moment from 'moment'
import AdminSelectors from '../../components/customElements/AdminSelectors'
import { formatMarchandiseCell } from '../../components/funnels/steps/04-marchandises/utils'

export const columnsCommandes = (options) => [
  {
    Header: 'N°',
    accessor: 'id',
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <span>{value?.charAt(0).toUpperCase()}</span>,
  },
  {
    Header: 'Mode',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { typeFret },
      } = row
      return (
        <img
          src={`/icons/funnel/cotation/${typeFret?.icon || 'fret-truck.svg'}`}
          alt="svg"
          style={{ width: '26px' }}
        />
      )
    },
  },
  {
    Header: 'Reception',
    accessor: 'createdAt',
    Cell: ({ value }) => <p>{moment(value).format('LL')}</p>,
  },
  {
    Header: 'De',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          expediteur: { nom, prenom, adresse, ville, codePostal },
        },
      } = row
      return (
        <div className="flex-fullinfosExp">
          <h5>{`${nom} ${prenom}`}</h5>
          <p>
            {`${adresse},`} <br /> {`${codePostal} ${ville}`}
          </p>
        </div>
      )
    },
  },
  {
    Header: 'À',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          destinataire: { nom, prenom, adresse, ville, codePostal },
        },
      } = row
      return (
        <div className="flex-fullinfosDest">
          <h5>{`${nom} ${prenom}`}</h5>
          <p>
            {`${adresse},`} <br /> {`${codePostal} ${ville}`}
          </p>
        </div>
      )
    },
  },
  {
    Header: 'Contenu',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { marchandises },
      } = row
      return (
        <div className="flex-fullinfos">
          {marchandises.map((item) => (
            <div key={item.id} className="flex-fullMarchandises">
              <p>{formatMarchandiseCell(item)}</p>
            </div>
          ))}
        </div>
      )
    },
  },
  {
    Header: 'Date',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: { date },
      } = row
      return <p>{moment(date).format('LL')}</p>
    },
  },
  // {
  //   Header: 'Distance',
  //   accessor: 'distance',
  //   minWidth: 80,
  // },
  {
    Header: 'Marge',
    accessor: 'marge',
    Cell: ({ row }) => {
      const {
        original: { marge, type },
      } = row
      if (type === 'messageries') {
        return <h5>NC</h5>
      }
      return <h5>{`+ ${parseFloat(marge).toFixed(2)} €`}</h5>
    },
  },
  {
    Header: 'Gérer par',
    accessor: 'gestionnaire',
    clickable: false,
    Cell: ({ row: { original } }) => (
      <AdminSelectors data={options?.admins} current={original} />
    ),
  },
  {
    Header: 'Statut',
    accessor: '',
    Cell: ({ row }) => {
      const {
        original: {
          statut: { nom, couleur, type },
        },
      } = row
      return (
        <div className={`btn-statuts box-${type}`}>
          <p
            style={{
              color: couleur,
            }}
          >
            {`${nom}`}
          </p>
        </div>
      )
    },
    // minWidth: 60,
  },
  {
    Header: '',
    accessor: 'statut.nom',
  },
  {
    Header: '',
    accessor: 'auteur.prenom',
  },
  {
    Header: '',
    accessor: 'auteur.nom',
  },
  {
    Header: '',
    accessor: 'expediteur.prenom',
  },
  {
    Header: '',
    accessor: 'expediteur.nom',
  },
  {
    Header: '',
    accessor: 'expediteur.societe',
  },
  {
    Header: '',
    accessor: 'destinataire.prenom',
  },
  {
    Header: '',
    accessor: 'destinataire.nom',
  },
  {
    Header: '',
    accessor: 'destinataire.societe',
  },
]
export const hiddenColumnsCommande = [
  'typeFret.icon',
  'statut.nom',
  'auteur.prenom',
  'auteur.nom',
  'expediteur.prenom',
  'expediteur.nom',
  'expediteur.societe',
  'destinataire.prenom',
  'destinataire.nom',
  'destinataire.societe',
]
