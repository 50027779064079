/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from 'react-table'
import {
  Checkbox,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  // TableSortLabel,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import MaUTable from '@material-ui/core/Table'
import { styled } from '@mui/material/styles'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { useFilters } from 'react-table/dist/react-table.development'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { Paper } from '@material-ui/core'
import ModalViewClient from '../modals/ModalViewClient'
import { deleteItem } from '../../services/Api/request'
import { authSelector } from '../../stores/selectors/authSelectors'
import ModalViewCollab from '../modals/ModalViewCollab'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <Checkbox ref={resolvedRef} {...rest} />
  }
)

export default function ContactsTable({
  columns,
  data,
  hiddenColumns = [],
  toolbar: Toolbar,
  toolbarProps,
  clickable = false,
}) {
  const [openModal, setOpenModal] = useState(false)
  const [view, setView] = useState(null)
  const auth = useSelector(authSelector)
  const navigate = useNavigate()
  const { id: collaborateurId } = useParams()
  const queryClient = useQueryClient()

  const openModalView = (index) => {
    setOpenModal(true)
    setView(index)
  }
  const closeModalView = () => {
    setOpenModal(false)
    setView(null)
  }

  const {
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    page,
    setFilter,
    setGlobalFilter,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        hiddenColumns,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((cols) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...cols,
      ])
    }
  )

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value))
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#0C0117',
      borderTop: '1px solid #00000029',
      borderBottom: '1px solid #00000029',
      fontSize: '0.8rem',
      padding: '0.4rem 10px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.8rem',
      padding: '10px',
    },
  }))

  const deleteDataHandler = () => {
    const type = auth.isAdmin ? 'collaborateurs' : 'clients'
    selectedFlatRows.forEach(async (row) => {
      const { id } = row.original
      const req = await deleteItem(type, {
        id,
      })
      if (req.ok) {
        queryClient.refetchQueries([type])
      }
    })
  }

  return (
    <>
      <Toolbar
        {...toolbarProps}
        setFilter={setFilter}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        numSelected={Object.keys(selectedRowIds).length}
        deleteDataHandler={deleteDataHandler}
      />
      <Paper className="container table-container">
        <MaUTable className="table-content" {...getTableProps()}>
          <TableHead className="table-head">
            {headerGroups.map((headerGroup, index) => (
              <TableRow
                key={index}
                className="table-row"
                {...headerGroup.getHeaderGroupProps()}
                sx={{ width: '100%' }}
              >
                {headerGroup.headers.map((column, t) => (
                  <StyledTableCell
                    key={t}
                    className="table-cell"
                    {...(column.id === 'selection' && column.getHeaderProps())}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.render('Header')}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody className="table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow
                  key={i}
                  className="table-row"
                  {...row.getRowProps()}
                  sx={{ width: '100%', height: '4rem' }}
                >
                  <StyledTableCell
                    className="table-cell"
                    {...row.cells[0].getCellProps()}
                  >
                    {row.cells[0].render('Cell')}
                  </StyledTableCell>
                  {row.cells.map(
                    (cell, n) =>
                      n !== 0 && (
                        <StyledTableCell
                          key={n}
                          className="table-cell"
                          {...cell.getCellProps()}
                        >
                          {cell.getCellProps().key.includes('view') ? (
                            <button
                              type="button"
                              className="btn-icon"
                              onClick={() => openModalView(row.original.id)}
                            >
                              Aperçu
                            </button>
                          ) : auth.isAdmin && clickable === true ? (
                            <button
                              type="button"
                              className="cell-clickable"
                              onClick={() =>
                                navigate(`/contacts/${row.original.id}`)
                              }
                            >
                              {cell.render('Cell')}
                            </button>
                          ) : (
                            cell.render('Cell')
                          )}
                        </StyledTableCell>
                      )
                  )}
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ width: '100%' }}>
              <TablePagination
                rowsPerPageOptions={[
                  20,
                  50,
                  { label: 'Tout', value: data.length },
                ]}
                colSpan={12}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage="Nb de lignes"
                sx={{
                  borderBottom: 'none',
                }}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </Paper>
      {openModal && view ? (
        auth.isAdmin && clickable === true ? (
          <ModalViewCollab
            open={openModal}
            onClose={closeModalView}
            view={view}
          />
        ) : (
          <ModalViewClient
            open={openModal}
            onClose={closeModalView}
            view={view}
            collaborateurId={collaborateurId}
          />
        )
      ) : null}
    </>
  )
}
