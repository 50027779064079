import React from 'react'
import { Grid, IconButton, Toolbar, Tooltip } from '@mui/material'
import { Delete } from '@mui/icons-material'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '48px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: '#FF00B4',
          backgroundColor: lighten('#FF00B4', 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    fontSize: '0.8rem',
    flex: '1 1 100%',
  },
}))

export default function ClassicSearchToolbar({
  globalFilter,
  setGlobalFilter,
  callback,
  numSelected,
  deleteDataHandler,
}) {
  const classes = useToolbarStyles()
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="search-bar"
        marginBottom={3}
      >
        <Grid item xs={12} sm={12} md={5} mb={{ xs: 2, sm: 2, md: 0 }}>
          <div className="input">
            <input
              type="search"
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Recherche"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={2} mb={{ xs: 2, sm: 2, md: 0 }}>
          <button type="button" className="btn" onClick={() => callback()}>
            <em>Créer un contact</em>
          </button>
        </Grid>
      </Grid>
      {numSelected > 0 ? (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <p className={classes.title}>
            Vous avez selectionné {numSelected} client(s). Vous ne pouvez pas
            supprimer un client en attente d'envoi.
          </p>
          <Tooltip title="Supprimer">
            <IconButton aria-label="supprimer" onClick={deleteDataHandler}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Toolbar>
      ) : null}
    </>
  )
}
