/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Box, Modal } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Grid } from '@mui/material'
import { createItem, getOne, updateItem } from '../services/Api/request'
import CustomizedProgress from '../components/customElements/CircularProgress'
import {
  NotificationForm,
  SecuriteForm,
  ContactForm,
} from '../components/account'
import { authSelector } from '../stores/selectors/authSelectors'
import { updateUserAction } from '../stores/actions/authActions'

export default function CompteMobil() {
  const auth = useSelector(authSelector)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const handleOpenEmailModal = () => setOpenEmailModal(true)
  const handleOpenPasswordModal = () => setOpenPasswordModal(true)
  const closeModal = () => {
    setOpenEmailModal(false)
    setOpenPasswordModal(false)
  }

  const { data: user, isLoading } = useQuery('me', async () => {
    const data = await getOne('me')
    updateUserAction({
      user: data,
    })
    return data
  })

  return (
    <div className="content-account">
      <Grid
        container
        justifyContent="space-between"
        spacing={1}
        flexDirection="row"
        display="flex"
      >
        {isLoading ? (
          <CustomizedProgress />
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <h5 style={{ marginTop: '1.5rem' }}>Général</h5>
              <ContactForm user={user} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <h5 style={{ marginTop: '1.5rem' }}>Sécurité</h5>
              <SecuriteForm
                openEmailModal={handleOpenEmailModal}
                openPasswordModal={handleOpenPasswordModal}
                user={user}
              />
            </Grid>
            {!auth.isAdmin && (
              <Grid item xs={12} sm={12} md={12}>
                <h5 style={{ marginTop: '1.5rem' }}>Notification</h5>
                <NotificationForm user={user} />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={9}>
              <Modal open={openEmailModal} onClose={closeModal}>
                <div className="card-modal">
                  <div className="card">
                    <h5>Changer d'adresse email</h5>
                    <FormEmail closeModal={closeModal} />
                  </div>
                </div>
              </Modal>
              <Modal open={openPasswordModal} onClose={closeModal}>
                <div className="card-modal">
                  <div className="card">
                    <h5>Changer le mot de passe</h5>
                    <FormPassword closeModal={closeModal} />
                  </div>
                </div>
              </Modal>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

function FormEmail() {
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const { isLoading: isUpdating, mutate } = useMutation(
    async (data) => {
      await updateItem('me', { body: JSON.stringify(data) })
    },
    {
      onSuccess: async ({ closeModal }) => {
        await queryClient.refetchQueries(['me'])
        toast.success('Adresse e-mail modifiée avec succès.')
        closeModal()
      },
    }
  )

  return (
    <form onSubmit={handleSubmit(mutate)}>
      <div className="input">
        <input
          type="email"
          name="email"
          placeholder="E-mail"
          className="form-control"
          {...register('email')}
        />
      </div>

      <button type="submit" className="btn">
        <em>Modifier votre adresse e-mail</em>
      </button>
    </form>
  )
}

function FormPassword({ closeModal }) {
  const { register, handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    async (data) => {
      if (data.password !== data.password_verify) {
        toast.error('Les mots de passe ne sont pas identiques.')
      } else {
        await createItem('reset_password', {
          body: JSON.stringify({
            plainPassword: data.password,
          }),
        })
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['me'])
        toast.success('Mot de passe modifié avec succès.')
        closeModal()
      },
    }
  )

  return (
    <form onSubmit={handleSubmit(mutate)}>
      <div className="input">
        <input
          type="password"
          name="password"
          placeholder="Mot de passe"
          className="form-control"
          {...register('password')}
        />
      </div>
      <div className="input">
        <input
          type="password"
          name="password_verify"
          placeholder="Confirmer le mot de passe"
          className="form-control"
          {...register('password_verify')}
        />
      </div>

      <button type="submit" className="btn">
        <em>Modifier votre mot de passe</em>
      </button>
    </form>
  )
}
