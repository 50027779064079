/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore } from 'redux'
import { authReducer } from './reducers/authReducer'

export default createStore(
  combineReducers({
    auth: authReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
