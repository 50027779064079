/* eslint-disable no-nested-ternary */
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Input } from '@mui/material'
import React from 'react'
import { apiUrl } from '../../services/Api/request'
import FilePdfUpload from '../uploads/FilePdfUpload'

function TrackingBloc({ auth, data, typeData, register }) {
  return (
    <>
      <h5>Tracking</h5>
      <div className="card">
        <Box
          style={{
            width: '100%',
            padding: '1rem 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>Transporteur</p>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {auth.isAdmin && typeData ? (
              <Input
                id="inputNumberRecepisse"
                type="text"
                defaultValue={data?.transporteur}
                {...register('transporteur')}
              />
            ) : (
              <p>{data?.transporteur || 'En cours'}</p>
            )}
          </Box>
        </Box>
        <Box
          style={{
            width: '100%',
            padding: '1rem 0',
            borderTop: '1px solid #00000029',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>Lien de suivi</p>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {auth.isAdmin && typeData ? (
              <Input
                id="inputNumberFollow"
                type="text"
                defaultValue={data.suivi}
                {...register('suivi')}
              />
            ) : data?.suivi ? (
              <a
                href={data.suivi}
                className="tracking-link"
                target="_blank"
                rel="noreferrer"
              >
                Suivre ma commande
              </a>
            ) : (
              <p>En cours</p>
            )}
          </Box>
        </Box>
        <Box
          style={{
            width: '100%',
            padding: '1rem 0 1rem',
            borderTop: '1px solid #00000029',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>Preuve de livraison</p>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {auth.isAdmin ? (
              <FilePdfUpload defaultValue={data?.preuveLivraison} />
            ) : data?.preuveLivraison ? (
              <a
                className="flex-btn-bordereau"
                href={`${apiUrl}/uploads/${data.preuveLivraison}`}
                download="bordereau"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFileArrowDown} />
                Preuve de livraison
              </a>
            ) : (
              <p>En attente</p>
            )}
          </Box>
        </Box>
      </div>
    </>
  )
}

export default TrackingBloc
