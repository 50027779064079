import { styled } from '@mui/system'

export const RangePickerWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  border: '2px solid #000',
  borderRadius: '46px',
  height: '46px',
  width: 'fit-content',
  boxSizing: 'border-box',
  '& .item-picker': {
    width: '50%',
  },
})
export const StyledDatePicker = styled('div')({
  height: '100%',
  '& input': {
    display: 'none',
    border: 'none',
    width: '70px',
  },
  '& button': {
    width: '-webkit-fill-available',
    height: '100%',
    background: 'none',
    border: 'none',
    borderRadius: '25px 0 0 25px',
    padding: '.5rem 2rem',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '1.25rem',
    '&.reverse': {
      borderRadius: '0 25px 25px 0',
    },
    '&:hover': {
      background: '#eee',
    },
  },
})
export const CustomPipeline = styled('div')({
  background: '#000',
  width: '2px',
  height: '100%',
})
export const ClearButton = styled('button')({
  background: 'none',
  border: '1px solid black',
  borderRadius: '25px',
  padding: '0',
  height: '20px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(100%, -50%)',
  '& svg': {
    height: '15px',
    width: '15px',
    position: 'relative',
    left: 0.5,
  },
})
