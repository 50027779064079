/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import { Box, CardContent, Grid, TextField, Tooltip } from '@material-ui/core'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { toast } from 'react-toastify'
import moment from 'moment'
import StaticDatePicker from '@mui/lab/StaticDatePicker'

export default function ExpeditionDateStep({
  step,
  item,
  setActiveStep,
  funnelState,
  setFunnelState,
  setFunnelSteps,
}) {
  const [dateSelect, setDateSelect] = useState(funnelState.date || null)
  const [commentValue, setCommentValue] = useState(
    funnelState.commentaireDate || ''
  )

  const handleChangeComment = (event) => setCommentValue(event.target.value)

  const nextStep = () => {
    if (dateSelect) {
      setFunnelState({
        ...funnelState,
        date: dateSelect,
        commentaireDate: commentValue,
      })
      setFunnelSteps(step, item)
      setActiveStep(item.nextStep)
    } else {
      toast.error('Veuillez sélectionner une date')
    }
  }
  const previousStep = () => {
    setFunnelState({
      ...funnelState,
      date: dateSelect,
      commentaireDate: commentValue,
    })
    setFunnelSteps(step, item)
    setActiveStep(item.previousStep)
  }

  return (
    <div className="card card-funnel">
      <CardContent style={{ padding: 0 }}>
        <Grid container alignItems="center" justifyContent="center">
          <form>
            <Box style={{ width: '100%' }}>
              <StaticDatePicker
                views={['day']}
                disablePast
                openTo="day"
                value={moment(dateSelect)}
                onChange={(newValue) => {
                  setDateSelect(newValue)
                }}
                toolbarTitle="Selectionnez date d'enlevement"
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box>
              <label htmlFor="inputInfo">
                Ajoutez toute information utile sur l'heure de prise en charge,
                horaires d'ouverture, etc..
              </label>
              <div className="input">
                <input
                  type="textearea"
                  id="inputInfo"
                  placeholder="votre message"
                  className="form-control"
                  value={commentValue}
                  onChange={handleChangeComment}
                />
              </div>
            </Box>
          </form>
        </Grid>
      </CardContent>

      <Tooltip title="Précédent" placement="right">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            left: '2rem',
          }}
          onClick={previousStep}
        >
          <NavigateBeforeIcon />
        </button>
      </Tooltip>
      <Tooltip title="Suivant" placement="left">
        <button
          type="button"
          className="btn-funnel"
          style={{
            bottom: '2rem',
            right: '2rem',
          }}
          onClick={nextStep}
        >
          <NavigateNextIcon />
        </button>
      </Tooltip>
    </div>
  )
}
