/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react'
import { useQueries } from 'react-query'
import { Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import { getMany } from '../services/Api/request'
import { OrdersTable, OrderSearchToolbar } from '../components/tables'
import {
  columnsCommandes,
  hiddenColumnsCommande,
} from '../utils/columns/columnsCommande'
import CustomizedProgress from '../components/customElements/CircularProgress'
import MotionHoc from '../components/MotionHoc'
import { authSelector } from '../stores/selectors/authSelectors'
import { addType } from '../utils/function'

function CommandesComponent() {
  const auth = useSelector(authSelector)
  const [options, setOptions] = useState({})
  const baseSearchParams = useMemo(
    () => ['pending', 'progress', 'waiting', 'confirm'],
    []
  )
  const [searchParams, setSearchParams] = useState(baseSearchParams)

  useEffect(() => {
    if (searchParams.length < 0) {
      setSearchParams(baseSearchParams)
    }
  }, [searchParams])

  const [
    {
      data: cotations,
      isLoading: isLoadingCotation,
      refetch: refetchCotations,
    },
    {
      data: messageries,
      isLoading: isLoadingMessagerie,
      refetch: refetchMessageries,
    },
    { data: admins },
  ] = useQueries([
    {
      queryKey: ['cotations', { type: 'notDelivery' }],
      queryFn: async () => {
        let filters = ''
        if (auth.isAdmin) {
          filters = `?statuses=${
            searchParams.length > 0
              ? searchParams.join(',')
              : baseSearchParams.join(',')
          }`
        }
        const data = await getMany(`cotations${filters}`)
        addType(data, 'cotations')
        return data
      },
      enabled: false,
    },
    {
      queryKey: ['messageries', { type: 'notDelivery' }],
      queryFn: async () => {
        let filters = ''
        if (auth.isAdmin) {
          filters = `?statuses=${searchParams.join(',')}`
        }
        const data = await getMany(`messageries${filters}`)
        addType(data, 'messageries')
        return data
      },
      enabled: false,
    },
    {
      queryKey: 'admins',
      queryFn: async () => {
        const data = await getMany('admins')
        return data
      },
    },
  ])

  const columns = useMemo(() => columnsCommandes(options), [options])

  const handleStatutSelect = (e) => {
    const { value, checked } = e.target

    const match = searchParams.find((el) => el.includes(value))
    if (checked && match === undefined) {
      setSearchParams([...searchParams, value])
    } else if (!checked && match !== undefined) {
      setSearchParams(searchParams.filter((el) => !el.includes(value)))
    }
  }

  function sorting(array) {
    return array.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      }
      if (a.createdAt > b.createdAt) {
        return -1
      }
      return 0
    })
  }

  useEffect(() => {
    if (auth.isAdmin !== null) {
      refetchCotations()
      refetchMessageries()
    }
  }, [searchParams, auth])
  useEffect(() => {
    setOptions({
      ...options,
      admins,
    })
  }, [admins])

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inherit !important',
        paddingTop: '2rem',
      }}
    >
      {!isLoadingCotation && !isLoadingMessagerie ? (
        <OrdersTable
          columns={columns}
          data={
            cotations === undefined || messageries === undefined
              ? []
              : sorting([...cotations, ...messageries])
          }
          hiddenColumns={hiddenColumnsCommande}
          toolbar={OrderSearchToolbar}
          toolbarProps={{
            searchParams,
          }}
          isSelectable
          handleStatutSelect={handleStatutSelect}
        />
      ) : (
        <CustomizedProgress />
      )}
    </Paper>
  )
}

const Commandes = MotionHoc(CommandesComponent)

export default Commandes
