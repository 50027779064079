/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Box, CardContent, Grid } from '@material-ui/core'
import { apiUrl } from '../services/Api/request'

function resetPassword() {
  const { resetToken } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [passwordValue, setPasswordValue] = useState(null)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSubmit = (data) => {
    // eslint-disable-next-line no-param-reassign
    delete data.passwordConfirm
    const url = pathname.includes('onboarding')
      ? '/create_password'
      : '/reset_password'
    fetch(`${apiUrl}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Mot de passe modifié avec succès')
          navigate('/login')
        }
      })
      .catch((error) => {
        toast.error('Un problème est survenu')
      })
  }

  return (
    <>
      <div className="bkg-section">
        <div className="bkg-effect" />
      </div>
      <div className="section-login">
        <div className="card">
          <CardContent>
            <div className="brand">
              <div className="brand-svg" />
              <div className="brand-logo">
                <div className="box-logo" />
              </div>
            </div>
            <div className="card-info">
              {errors.plainPassword && (
                <p className="small error"> {errors.plainPassword.message} </p>
              )}
              {errors.passwordConfirm && (
                <p className="small success">
                  {' '}
                  {errors.passwordConfirm.message}{' '}
                </p>
              )}
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card-form">
                <Box sx={{ py: 1 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <label htmlFor="inputPassword">Mot de passe</label>
                      <div className="input">
                        <input
                          name="plainPassword"
                          type="password"
                          placeholder="Mot de passe"
                          id="inputPassword"
                          className="form-control"
                          {...register('plainPassword', {
                            required: 'Mot de passe obligatoire',
                            onChange: ({ target: { value } }) =>
                              setPasswordValue(value),
                          })}
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <label htmlFor="inputResetPassword">
                        Reinitialiser le mot de passe
                      </label>
                      <div className="input">
                        <input
                          type="password"
                          name="passwordConfirm"
                          placeholder="Mot de passe"
                          id="inputResetpassword"
                          className="form-control"
                          {...register('passwordConfirm', {
                            required: true,
                            validate: (value) =>
                              value === passwordValue ||
                              `Les mots de passe ne correspondent pas.`,
                          })}
                        />
                      </div>
                      <input
                        type="hidden"
                        name="resetToken"
                        value={resetToken}
                        {...register('resetToken')}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <button className="btn" type="submit">
                <em>Enregistrer</em>
              </button>
            </form>
          </CardContent>
        </div>
      </div>
    </>
  )
}

export default resetPassword
