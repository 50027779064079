/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react'
import { useQueries } from 'react-query'
import { Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import { getMany } from '../services/Api/request'
import {
  OrdersTable,
  OrderSearchToolbar,
  HistoricSearchToolbar,
} from '../components/tables'
import {
  columnsHistoriqueAdmin,
  columnsHistoriqueCollab,
  hiddenColumnsHistorique,
} from '../utils/columns/columnsHistorique'
import CustomizedProgress from '../components/customElements/CircularProgress'
import { authSelector } from '../stores/selectors/authSelectors'
import MotionHoc from '../components/MotionHoc'
import { addType } from '../utils/function'

function HistoriqueComponent() {
  const auth = useSelector(authSelector)
  const columns = auth.isAdmin
    ? columnsHistoriqueAdmin
    : columnsHistoriqueCollab

  const [totalMarge, setTotalMarge] = useState(0)

  const baseSearchParams = useMemo(() => {
    if (auth.isAdmin) {
      return ['delivery']
    }
    return ['pending', 'progress', 'waiting', 'confirm', 'delivery']
  }, [])
  const [searchParams, setSearchParams] = useState(baseSearchParams)

  const handleStatutSelect = (e) => {
    const { value, checked } = e.target

    const match = searchParams.find((el) => el.includes(value))
    if (checked && match === undefined) {
      setSearchParams([...searchParams, value])
    } else if (!checked && match !== undefined) {
      setSearchParams(searchParams.filter((el) => !el.includes(value)))
    }
  }

  const [
    {
      data: cotations,
      isLoading: isLoadingCotation,
      refetch: refetchCotations,
    },
    {
      data: messageries,
      isLoading: isLoadingMessagerie,
      refetch: refetchMessageries,
    },
  ] = useQueries([
    {
      queryKey: ['cotations', { type: 'delivery' }],
      queryFn: async () => {
        if (auth.isAdmin !== null) {
          const filters = `?statuses=${searchParams.join(',')}`
          const data = await getMany(`cotations${filters}`)
          addType(data, 'cotations')
          return data
        }
        return []
      },
    },
    {
      queryKey: ['messageries', { type: 'delivery' }],
      queryFn: async () => {
        if (auth.isAdmin !== null) {
          const filters = `?statuses=${searchParams.join(',')}`
          const data = await getMany(`messageries${filters}`)
          addType(data, 'messageries')
          return data
        }
        return []
      },
    },
  ])

  useEffect(() => {
    if (!auth.isAdmin && searchParams.length < 0) {
      setSearchParams(baseSearchParams)
    }
    refetchCotations()
    refetchMessageries()
  }, [searchParams, auth.isAdmin])
  useEffect(() => {
    let total = 0
    let merge = []
    if (cotations?.length > 0) {
      merge = [...merge, ...cotations]
    }
    if (messageries?.length > 0) {
      merge = [...merge, ...messageries]
    }
    merge.forEach((item) => {
      total += parseFloat(item.marge)
    })
    setTotalMarge(total)
  }, [messageries, cotations])

  function sorting(array) {
    return array.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      }
      if (a.createdAt > b.createdAt) {
        return -1
      }
      return 0
    })
  }

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inherit !important',
        paddingTop: '2rem',
      }}
    >
      {!isLoadingCotation && !isLoadingMessagerie ? (
        <OrdersTable
          columns={columns}
          data={sorting([...cotations, ...messageries])}
          hiddenColumns={hiddenColumnsHistorique}
          toolbar={auth.isAdmin ? HistoricSearchToolbar : OrderSearchToolbar}
          toolbarProps={{
            searchParams,
            totalMarge,
          }}
          handleStatutSelect={handleStatutSelect}
        />
      ) : (
        <CustomizedProgress />
      )}
    </Paper>
  )
}

const Historique = MotionHoc(HistoriqueComponent)
export default Historique
