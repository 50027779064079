/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getMany } from '../../services/Api/request'

export default function MessagerieFunnel(props) {
  const { activeStep, funnelSteps, funnelState } = props

  const { data: clients } = useQuery('clients', async () => {
    let filters = ''
    if (funnelState.collaborateur) {
      filters = `?collaborateur=${funnelState.collaborateur}`
    }
    const response = await getMany('clients', {
      filters,
    })
    if (response.length > 0) {
      response.forEach((client) => {
        const { prenom, nom } = client
        client.fullname = `${nom} ${prenom}`
      })
    }
    return response
  })

  const isCurrentStep = (comparatedStep) => {
    if (
      activeStep.step === comparatedStep.step &&
      activeStep.children === comparatedStep.children
    ) {
      return true
    }
    return false
  }

  return (
    funnelSteps.length && (
      <>
        {funnelSteps.map((step) => {
          if (step.childrens) {
            return step.childrens.map((children) => {
              if (isCurrentStep(children.activeStep)) {
                return (
                  <StepRenderer
                    key={children.id}
                    item={children}
                    step={step}
                    clients={clients}
                    {...props}
                  />
                )
              }
              return null
            })
          }
          return null
        })}
      </>
    )
  )
}

function StepRenderer({ item, ...rest }) {
  const { component: Component } = item
  const navigate = useNavigate()

  useEffect(() => {
    const { funnelState } = rest
    if (!funnelState.collaborateur) {
      navigate('/demandes')
      toast.error('Veuillez choisir un collaborateur')
    }
  }, [item])

  return <Component {...rest} item={item} />
}
