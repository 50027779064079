/* eslint-disable no-unused-expressions */
import Contacts from './pages/Contacts'
import Commandes from './pages/Commandes'
import Demandes from './pages/Demandes'
import Historique from './pages/Historique'
import Home from './pages/Home'
import Login from './pages/Login'
import Cotation from './pages/Cotation'
import Messagerie from './pages/Messagerie'
import resetPassword from './pages/resetPassword'
import HistoriqueOrders from './pages/HistoriqueOrders'
import DetailsContact from './pages/DetailsContact'

import { adminRole, collaborateurRole } from './services/security'
import Validation from './components/funnels/steps/ValidationCotation'
import CompteProvider from './components/account/CompteProvider'

export const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login,
    requireAuth: false,
  },
  {
    name: 'onboarding',
    path: '/onboarding/:resetToken',
    component: resetPassword,
    requireAuth: false,
  },
  {
    name: 'resetPassword',
    path: '/reset/:resetToken',
    component: resetPassword,
    requireAuth: false,
  },
  {
    name: 'dashboard',
    path: '/',
    component: Home,
    container: true,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    name: 'supports',
    path: '/commandes',
    title: 'Nouvelles commandes',
    container: true,
    component: Commandes,
    requireAuth: true,
    roles: [adminRole],
  },
  // {
  //   path: '/commandes/:type/:id',
  //   container: true,
  //   component: HistoriqueOrders,
  //   requireAuth: true,
  //   roles: [adminRole, collaborateurRole],
  // },
  {
    name: 'funnel',
    path: '/demandes',
    component: Demandes,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    name: 'history',
    path: '/historique',
    title: 'Historique des commandes',
    container: true,
    component: Historique,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    // name: 'history/type/id',
    path: '/historique/:type/:id',
    container: true,
    component: HistoriqueOrders,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    name: 'contact',
    path: '/contacts',
    title: 'Carnet de Clientèle',
    container: true,
    component: Contacts,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    path: '/contacts/:id',
    container: true,
    component: DetailsContact,
    requireAuth: true,
    roles: [adminRole],
  },
  {
    name: 'account',
    path: '/compte',
    title: 'Mon profil',
    container: true,
    component: CompteProvider,
    // component: Compte,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    name: 'cotation',
    path: '/cotation',
    container: true,
    component: Cotation,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    name: 'messagerie',
    path: '/messagerie',
    container: true,
    component: Messagerie,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
  {
    name: 'validate',
    path: '/cotation/validate',
    container: true,
    component: Validation,
    requireAuth: true,
    roles: [adminRole, collaborateurRole],
  },
]
